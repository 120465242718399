import React from "react";
import { LineWave } from "react-loader-spinner";
import { FaUsers } from "react-icons/fa";
import { IoLogoGameControllerB } from "react-icons/io";
import { MdFolderCopy } from "react-icons/md";

const CardWrapper = ({ children }) => {
  return (
    <div className="w-full md:w-1/2 lg:w-1/2 xl:w-1/3 px-3 mb-7">
      {children}
    </div>
  );
};

const CardContent = ({ icon: Icon, title, value }) => {
  return (
    <div className="bg-[#211849] text-white p-8 rounded">
      <div className="flex justify-between items-center">
        <div className="w-[60px] h-[60px] flex justify-center items-center rounded-full bg-white-500 bg-custom-color bg-opacity-100 min-w-[60px]">
          <Icon className="text-white text-[30px]" />
        </div>
        <p className="text-[19px]">{title}</p>
      </div>
      <p className="text-right text-[45px] font-bold">
        {value}
      </p>
    </div>
  );
};

const DashboardCard = ({ dashboard, isLoading }) => {
  return (
    <div className="flex flex-wrap -mx-2 pr-3 pl-3">
      <CardWrapper>
        <CardContent
          icon={FaUsers}
          title="Total Registered Users"
          value={dashboard?.data?.totalActiveUsers}
        />
      </CardWrapper>
      <CardWrapper>
        <CardContent
          icon={FaUsers}
          title="Total User Count (Last 30 Days)"
          value={dashboard?.data?.totalUserCountLast30}
        />
      </CardWrapper>
      <CardWrapper>
        <CardContent
          icon={FaUsers}
          title="Total Registered Users In Games (Not Started/In-Progress)"
          value={dashboard?.data?.totalRegisterdUsersInGames}
        />
      </CardWrapper>
      <CardWrapper>
        <CardContent
          icon={IoLogoGameControllerB}
          title="Total Scheduled Games"
          value={dashboard?.data?.totalScheduledGames}
        />
      </CardWrapper>
      <CardWrapper>
        <CardContent
          icon={IoLogoGameControllerB}
          title="Total Active Games (In-Progress)"
          value={dashboard?.data?.totalActiveGames}
        />
      </CardWrapper>
      <CardWrapper>
        <CardContent
          icon={IoLogoGameControllerB}
          title="Total Completed Games"
          value={dashboard?.data?.totalCompletedGames}
        />
      </CardWrapper>
      <CardWrapper>
        <CardContent
          icon={IoLogoGameControllerB}
          title="Total Games"
          value={dashboard?.data?.totalGames}
        />
      </CardWrapper>
      <CardWrapper>
        <CardContent
          icon={IoLogoGameControllerB}
          title="Total Cancelled Games (Deleted/Expired)"
          value={dashboard?.data?.totalGamesCanceled}
        />
      </CardWrapper>
      <CardWrapper>
        <CardContent
          icon={MdFolderCopy}
          title="Total Active Scenarios"
          value={dashboard?.data?.totalActiveScenarios}
        />
      </CardWrapper>

      {isLoading && (
        <div className="loader">
          <LineWave color={"#fff"} loading={isLoading} size={150} />
        </div>
      )}
    </div>
  );
};

export default DashboardCard;
