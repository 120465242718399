import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { Link, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import moment from "moment-timezone";
import { LineWave } from "react-loader-spinner";
import useCopyToClipboard from "../../utils/useCopyToClipboard";
import { gamedetails } from "../../redux/slices/gameSlice";
import { IoMdArrowRoundBack } from "react-icons/io";
import { deleteGames, getAllParticipants } from "../../redux/slices/gameSlice";
import Confirmation from "../../components/confirmation/Confirmation";
import LocationSvg from "../../components/svg/LocationSvg";
import CalendarSvg from "../../components/svg/CalendarSvg";
import UserSvg from "../../components/svg/UserSvg";
import DurationSvg from "../../components/svg/DurationSvg";
import CopyclipSvg from "../../components/svg/CopyclipSvg";
import { MdAddCircle, MdRemoveCircle } from "react-icons/md";
import GameMilestonePopup from "./GameMilestonePopup";
import GameGenCoinPopup from "./GameGenCoinPopup";
import backgroundPattern from "../../images/backgroundPattern.svg";

const GameDetail = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { id } = useParams();
  const [confirmationModel, setconfirmationModel] = useState(false);
  const [activeTab, setActiveTab] = useState("invite");
  const [popUp, setPopUp] = useState({
    milestone: false,
    genCoins: false,
  });

  const [selectedData, setSelectedData] = useState({});

  const { gameDetail, isLoading, gameParticipants } = useSelector(
    (state) => state.gameReducer
  );
  const dateTimeString =
    gameDetail?.data?.startDate + gameDetail?.data?.startTime;

  const qrCodeUrl = `${process.env.REACT_APP_QR_CODE}${gameDetail?.data?.QRCode}`;

  const { copyToClipboard } = useCopyToClipboard();

  const openTab = (tabName) => {
    setActiveTab(tabName);
  };

  const handleDelete = () => {
    setconfirmationModel(true);
  };

  const confirmDelete = () => {
    dispatch(deleteGames(gameDetail?.data?._id)).then(() => {
      setconfirmationModel(false);
      toast.success("Game Deleted Sucessfully");
      navigate("/panel/games");
    });
  };

  const cancelDelete = () => {
    setconfirmationModel(false);
  };

  useEffect(() => {
    dispatch(gamedetails(id));
  }, [id]);

  useEffect(() => {
    if (gameDetail?.data?._id) {
      dispatch(getAllParticipants(gameDetail?.data?._id));
    }
  }, [gameDetail]);

  return (
    <>
      {isLoading ? (
        <div className="loader">
          <LineWave color={"#fff"} loading={isLoading} size={150} />
        </div>
      ) : (
        <div className="pb-[60px]">
          <Link to={"/panel/games"} className=" block text-white pb-5 text-3xl">
            <IoMdArrowRoundBack />
          </Link>

          <div
            className="bg-no-repeat bg-cover py-5 px-14 rounded-xl"
            style={{ backgroundImage: `url(${backgroundPattern})` }}
          >
            <div className="text-left mb-4">
              <h1 className="text-white text-3xl font-bold">
                {gameDetail?.data?.gameTitle}
              </h1>
            </div>
            <div className="flex gap-5">
              <div className="relative w-[130px] h-[170px]">
                <img
                  src={gameDetail?.data?.scenarioId?.image}
                  className=" w-full h-full object-cover"
                  alt="game-logo"
                />
              </div>
              <div>
                <div className="w-full flex items-center gap-2">
                  <div className="border px-2.5 py-0.5 text-xs font-semibold transition-colors focus:outline-none focus:ring-2 focus:ring-ring focus:ring-offset-2 border-transparent hover:bg-secondary/80 bg-[#13BEA0] rounded-[6px] text-white">{`Levels ${gameDetail?.data?.scenarioId?.minLevel}-${gameDetail?.data?.scenarioId?.maxLevel}`}</div>
                  <div
                    className={`border px-2.5 py-0.5 text-xs font-semibold transition-colors focus:outline-none focus:ring-2 focus:ring-ring focus:ring-offset-2 border-transparent hover:bg-secondary/80 ${
                      gameDetail?.data?.modes === "online"
                        ? "bg-[#20FB92]"
                        : "bg-[#FB9620]"
                    }  rounded-[6px] text-white`}
                  >
                    {`${
                      gameDetail?.data?.modes === "online"
                        ? "Online Game"
                        : "In-Person Game"
                    }`}
                  </div>
                  <div
                    className={`${
                      gameDetail?.data?.status === "NOTSTARTED"
                        ? "bg-[#EEEEEE] text-black"
                        : gameDetail?.data?.status === "INPROGRESS"
                        ? "bg-[#13BEA0] text-white"
                        : gameDetail?.data?.status === "EXPIRED"
                        ? "bg-[#999999]"
                        : "bg-[#E53900]"
                    } border px-2.5 py-0.5 text-xs font-semibold  transition-colors focus:outline-none focus:ring-2 focus:ring-ring focus:ring-offset-2 border-transparent hover:bg-secondary/80 rounded-[6px]`}
                  >
                    {`${
                      gameDetail?.data?.status === "NOTSTARTED"
                        ? "Not Started"
                        : gameDetail?.data?.status === "INPROGRESS"
                        ? "In Progress"
                        : gameDetail?.data?.status === "EXPIRED"
                        ? "Expired"
                        : "Ended"
                    }`}
                  </div>
                </div>
                <div className="text-left py-4">
                  <span className="text-white">
                    {gameDetail?.data?.scenarioId?.name}
                  </span>
                </div>
                <div>
                  <div className="flex items-center gap-2 mb-1">
                    <LocationSvg width={16} height={16} />
                    {gameDetail?.data?.onlineLocation ? (
                      <span className="text-gray-300 text-[14px]">
                        {gameDetail?.data?.onlineLocation}
                      </span>
                    ) : (
                      <span className="text-gray-300 text-[14px]">
                        {gameDetail?.data?.specificLocation
                          ? `${gameDetail?.data?.specificLocation}, ${gameDetail?.data?.location[0]?.formattedAddress}`
                          : `${gameDetail?.data?.location[0]?.formattedAddress}`}
                      </span>
                    )}
                  </div>
                  <div className="flex items-center gap-2 mb-1">
                    <CalendarSvg />
                    <span className="text-gray-300 text-[14px]">{`${
                      gameDetail?.data?.startDate
                    }, 
    ${moment
      .tz(dateTimeString, "MM-DD-YYYY hh:mm A", gameDetail?.data?.userTimeZone)
      .format("ddd")
      .toLocaleUpperCase()}, ${gameDetail?.data?.startTime} ${moment
                      .tz(gameDetail?.data?.userTimeZone)
                      .zoneAbbr()} `}</span>
                  </div>
                  <div className="flex items-center gap-2 mb-1">
                    <UserSvg width={16} height={16} />
                    <span className="text-gray-300 text-[14px]">{`${gameDetail?.data?.scenarioId?.minNoOfPlayer}-${gameDetail?.data?.scenarioId?.maxNoOfPlayer} players`}</span>
                  </div>
                  <div className="flex items-center gap-2 mb-1">
                    <DurationSvg />
                    <span className="text-gray-300 text-[14px]">{`${gameDetail?.data?.scenarioId?.duration}h Duration`}</span>
                  </div>
                </div>
              </div>
            </div>
            {gameDetail?.data?.status === "NOTSTARTED" &&
            !gameDetail?.data?.isDeleted ? (
              <div className="flex items-center justify-end gap-4">
                <Link
                  className="border border-white text-white px-2 py-0.5 block"
                  to={`/panel/editgame/${id}`}
                >
                  Edit
                </Link>
                <button
                  className="border border-red-600 text-red-600 px-2 py-0.5"
                  onClick={handleDelete}
                >
                  Delete
                </button>
              </div>
            ) : null}
          </div>

          <div className="bg-[#211849] text-white  rounded-b-lg">
            <div className="flex border-b border-gray-700">
              <div className="max-w-lg">
                <button
                  onClick={() => openTab("invite")}
                  className={`py-4 px-8 text-center mr-4 focus:outline-none ${
                    activeTab === "invite" ? "border-b-2 border-white" : ""
                  }`}
                >
                  Invite
                </button>
                <button
                  onClick={() => openTab("participants")}
                  className={`py-4 px-8 text-center focus:outline-none ${
                    activeTab === "participants"
                      ? "border-b-2 border-white"
                      : ""
                  }`}
                >
                  Participants
                </button>
                {gameDetail?.data?.notes !== null && gameDetail?.data?.notes !== undefined && gameDetail?.data?.notes.trim() && (
                  <button
                    onClick={() => openTab("note")}
                    className={`py-4 px-8 text-center focus:outline-none ${
                      activeTab === "note" ? "border-b-2 border-white" : ""
                    }`}
                  >
                    Notes
                  </button>
                )}
              </div>
            </div>

            <div className="py-6 px-6">
              <div
                id="invite-content"
                className={`tab-content ${
                  activeTab === "invite" ? "block" : "hidden"
                }`}
              >
                <p className="text-left">
                  Users can join the game by scanning the QR or entering
                  gamecode below
                </p>
                <div className="flex items-center justify-between xl:w-[65%] lg:w-[90%]">
                  <div>
                    <div className="mt-4">
                      <span className=" text-gray-300 block text-left mb-2">
                        Share code
                      </span>
                      <div className="relative flex items-center">
                        <input
                          type="text"
                          disabled
                          readOnly
                          value={gameDetail?.data?.gameCode}
                          className="w-full border bg-[#160c40] ring-offset-[#160c40] pr-[32px] file:border-0 file:bg-transparent file:text-sm file:font-medium focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-offset-2 disabled:cursor-not-allowed disabled:opacity-50 h-[54px] p-5 placeholder:text-[#150C3F] text-base rounded-[10px] shadow-[0_5px_15px_0px_rgba(49,59,85,0.05)] border-transparent focus-visible:outline-primary-red focus-visible:ring-primary-red"
                        />
                        <button
                          className="bg-none hover:bg-white border-none absolute right-0 mr-1"
                          onClick={() =>
                            copyToClipboard(gameDetail?.data?.gameCode)
                          }
                        >
                          <CopyclipSvg />
                        </button>
                      </div>
                    </div>
                    <div className="mt-4">
                      <span className=" text-gray-300 block text-left mb-2">
                        Share link
                      </span>
                      <div className="relative flex items-center">
                        <input
                          type="text"
                          disabled
                          readOnly
                          value={`${process.env.REACT_APP_WEBSITE_URL}gamedetail/${id}`}
                          className="w-full truncate border bg-[#160c40] ring-offset-[#160c40] pr-[32px] file:border-0 file:bg-transparent file:text-sm file:font-medium focus-visible:truncate focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-offset-2 disabled:cursor-not-allowed disabled:opacity-50 h-[54px] p-5 placeholder:text-[#150C3F] text-base rounded-[10px] shadow-[0_5px_15px_0px_rgba(49,59,85,0.05)] border-transparent focus-visible:outline-primary-red focus-visible:ring-primary-red"
                        />
                        <button
                          className="bg-none hover:bg-white border-none absolute right-0 mr-1"
                          onClick={() =>
                            copyToClipboard(
                              `${process.env.REACT_APP_WEBSITE_URL}gamedetail/${id}`
                            )
                          }
                        >
                          <CopyclipSvg />
                        </button>
                      </div>
                    </div>
                    {gameDetail?.data?.gameLink && (
                      <div className="mt-4">
                        <span className=" text-gray-300 block text-left mb-2">
                          Online game link
                        </span>
                        <div className="relative flex items-center">
                          <input
                            type="text"
                            disabled
                            readOnly
                            value={gameDetail?.data?.gameLink}
                            className="w-full truncate border bg-[#160c40] ring-offset-[#160c40] pr-[32px] file:border-0 file:bg-transparent file:text-sm file:font-medium focus-visible:truncate focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-offset-2 disabled:cursor-not-allowed disabled:opacity-50 h-[54px] p-5 placeholder:text-[#150C3F] text-base rounded-[10px] shadow-[0_5px_15px_0px_rgba(49,59,85,0.05)] border-transparent focus-visible:outline-primary-red focus-visible:ring-primary-red"
                          />
                          <button
                            className="bg-none hover:bg-white border-none absolute right-0 mr-1"
                            onClick={() =>
                              copyToClipboard(`${gameDetail?.data?.gameLink}`)
                            }
                          >
                            <CopyclipSvg />
                          </button>
                        </div>
                      </div>
                    )}
                  </div>
                  <div className="hidden lg:flex flex-col justify-evenly items-center">
                    <div className="h-[60px] border border-[#3A3261] w-fit mb-3"></div>
                    <div className="text-white">OR</div>
                    <div className="h-[60px] border border-[#3A3261] mt-3"></div>
                  </div>
                  <div>
                    <span className="text-gray inline-block">Scan QR code</span>
                    <div className="qr-code p-3 mt-4">
                      <img alt="qr-code" src={qrCodeUrl} />
                    </div>
                  </div>
                </div>
              </div>
              <div
                id="participants-content"
                className={`tab-content ${
                  activeTab === "participants" ? "block" : "hidden"
                }`}
              >
                {gameParticipants?.data?.map((participants) => {
                  return (
                    <div
                      className={`w-full border-b border-gray-700 py-3`}
                      key={participants?._id}
                    >
                      <div className="flex justify-between items-center">
                        <div className="flex items-center mb-2 justify-between w-[60%]">
                          <div className="flex items-center gap-4">
                            <div
                              className={`relative w-[48px] h-[48px] ${
                                !participants?.profile_image &&
                                "bg-gray-400 rounded-full flex items-center justify-center text-center"
                              }`}
                            >
                              {participants?.game_master && (
                                <img
                                  src="/assets/images/crown.svg"
                                  className="rounded-[10px] right-0 bottom-0 absolute z-30"
                                  alt="crown-logo"
                                />
                              )}
                              <span className="relative flex shrink-0 overflow-hidden rounded-full w-[48px] h-[48px]">
                                {participants?.profile_image ? (
                                  <img
                                    className="aspect-square h-full w-full object-fill"
                                    src={participants?.profile_image}
                                    alt="avatar-img"
                                  />
                                ) : (
                                  <span className="text-center flex justify-center items-center w-full text-lg">{`${participants?.first_name[0]}${participants?.last_name[0]}`}</span>
                                )}
                              </span>
                            </div>
                            <div className="text-white text-left">
                              <h3>{`${participants?.first_name} ${participants?.last_name}`}</h3>
                              <p>{participants?.mid}</p>
                            </div>
                          </div>

                          {participants?.characterDetails?._id && (
                            <div className="w-[50%]">
                              <div className="text-gray-400 text-[14px] space-y-1 text-left ">
                                <div className="flex items-center">
                                  <span className=" pr-2">
                                    {participants?.characterDetails?.name}
                                  </span>
                                  <span className="text-grey text-[10px] rounded-[3px] border-[1px] px-1 flex items-center h-4 w-fit border-grey">
                                    Level{" "}
                                    {participants?.characterDetails?.level}
                                  </span>
                                </div>
                                <div>
                                  Arche Type -
                                  {participants?.characterDetails?.archeType}
                                </div>
                                <div>
                                  Class Type -
                                  {participants?.characterDetails?.class}
                                </div>
                              </div>
                            </div>
                          )}
                        </div>

                        {(gameDetail?.data?.status === "EXPIRED" ||
                          gameDetail?.data?.status === "COMPLETED") && (
                          <div>
                            <div className="flex items-center">
                              {participants?.game_master && (
                                <div className="pr-5">
                                  <h4> Gen Coins</h4>
                                  <div className="flex items-center justify-center gap-2 mt-2">
                                    <MdAddCircle
                                      className="text-green-600 text-2xl w-[20px] h-[20px] cursor-pointer"
                                      onClick={() => {
                                        setPopUp((prevState) => ({
                                          ...prevState,
                                          genCoins: true,
                                          mode: "Add",
                                        }));
                                        setSelectedData({
                                          participantData: participants,
                                        });
                                      }}
                                    />
                                    <MdRemoveCircle
                                      className="text-red-600 text-2xl w-[20px] h-[20px] cursor-pointer"
                                      onClick={() => {
                                        setPopUp((prevState) => ({
                                          ...prevState,
                                          genCoins: true,
                                          mode: "Remove",
                                        }));
                                        setSelectedData({
                                          participantData: participants,
                                        });
                                      }}
                                    />
                                  </div>
                                </div>
                              )}
                              {participants?.characterDetails?._id && (
                                <div className="pl-5">
                                  <h4>Milestone Points</h4>
                                  <div className="flex items-center justify-center gap-2 mt-2">
                                    <MdAddCircle
                                      className="text-green-600 text-2xl w-[20px] h-[20px] cursor-pointer"
                                      onClick={() => {
                                        setPopUp((prevState) => ({
                                          ...prevState,
                                          milestone: true,
                                          mode: "Add",
                                        }));
                                        setSelectedData({
                                          participantData: participants,
                                        });
                                      }}
                                    />
                                    <MdRemoveCircle
                                      className="text-red-600 text-2xl w-[20px] h-[20px] cursor-pointer"
                                      onClick={() => {
                                        setPopUp((prevState) => ({
                                          ...prevState,
                                          milestone: true,
                                          mode: "Remove",
                                        }));
                                        setSelectedData({
                                          participantData: participants,
                                        });
                                      }}
                                    />
                                  </div>
                                </div>
                              )}
                            </div>
                          </div>
                        )}
                      </div>
                    </div>
                  );
                })}
              </div>
              <div
                id="note-content"
                className={`tab-content ${
                  activeTab === "note" ? "block" : "hidden"
                } text-left`}
              >
                <p>{gameDetail?.data?.notes}</p>
              </div>
            </div>
          </div>
          {popUp.milestone && (
            <GameMilestonePopup
              popUp={popUp}
              cancelModel={() => {
                setPopUp((prevState) => ({
                  ...prevState,
                  milestone: false,
                }));
              }}
              selectedData={selectedData}
              gameId={id}
            />
          )}
          {popUp.genCoins && (
            <GameGenCoinPopup
              popUp={popUp}
              cancelGenModel={() => {
                setPopUp((prevState) => ({
                  ...prevState,
                  genCoins: false,
                }));
              }}
              gameId={id}
              selectedData={selectedData}
            />
          )}
          {confirmationModel && (
            <Confirmation
              confirmDelete={confirmDelete}
              cancelDelete={cancelDelete}
              message={"delete this Game"}
              buttonText="Delete"
            />
          )}
        </div>
      )}
    </>
  );
};

export default GameDetail;
