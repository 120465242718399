import React from "react";

const ScenarioSvg = () => {
  return (
    <div>
      <svg
        width="12px"
        height="12px"
        viewBox="0 0 18 19"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g>
          <path
            d="M12.9982 12.0427V13.3378C12.9982 13.6813 12.8617 14.0107 12.6189 14.2536C12.376 14.4964 12.0466 14.6329 11.7031 14.6329H5.2277C4.88422 14.6329 4.55481 14.4964 4.31194 14.2536C4.06906 14.0107 3.93262 13.6813 3.93262 13.3378V7.50993C3.93262 7.16645 4.06906 6.83704 4.31194 6.59416C4.55481 6.35129 4.88422 6.21484 5.2277 6.21484H6.52278"
            stroke="#B8BBCC"
            strokeWidth="1.10082"
            strokeLinecap="round"
            strokeLinejoin="round"
          ></path>
          <path
            d="M7.81852 3.625H9.76114L11.0562 4.92008H14.2939C14.6374 4.92008 14.9668 5.05653 15.2097 5.2994C15.4526 5.54228 15.589 5.87169 15.589 6.21516V10.748C15.589 11.0914 15.4526 11.4208 15.2097 11.6637C14.9668 11.9066 14.6374 12.043 14.2939 12.043H7.81852C7.47504 12.043 7.14563 11.9066 6.90276 11.6637C6.65988 11.4208 6.52344 11.0914 6.52344 10.748V4.92008C6.52344 4.57661 6.65988 4.2472 6.90276 4.00432C7.14563 3.76145 7.47504 3.625 7.81852 3.625Z"
            stroke="#B8BBCC"
            strokeWidth="1.10082"
            strokeLinecap="round"
            strokeLinejoin="round"
          ></path>
        </g>
      </svg>
    </div>
  );
};

export default ScenarioSvg;
