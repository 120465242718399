import React from 'react'
import UserTable from './UserTable';
const Users = () => {
  return (
    <div>
        <UserTable/>
    </div>
  )
}

export default Users;