import React from "react";

const LevelSvg = () => {
  return (
    <div>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="12px"
        height="12px"
        viewBox="0 0 20 20"
        fill="none"
      >
        <path
          d="M7.50033 4.1665H5.83366C5.39163 4.1665 4.96771 4.3421 4.65515 4.65466C4.34259 4.96722 4.16699 5.39114 4.16699 5.83317V15.8332C4.16699 16.2752 4.34259 16.6991 4.65515 17.0117C4.96771 17.3242 5.39163 17.4998 5.83366 17.4998H14.167C14.609 17.4998 15.0329 17.3242 15.3455 17.0117C15.6581 16.6991 15.8337 16.2752 15.8337 15.8332V5.83317C15.8337 5.39114 15.6581 4.96722 15.3455 4.65466C15.0329 4.3421 14.609 4.1665 14.167 4.1665H12.5003"
          stroke="#B8BBCC"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        ></path>
        <path
          d="M10.8333 2.5H9.16667C8.24619 2.5 7.5 3.24619 7.5 4.16667C7.5 5.08714 8.24619 5.83333 9.16667 5.83333H10.8333C11.7538 5.83333 12.5 5.08714 12.5 4.16667C12.5 3.24619 11.7538 2.5 10.8333 2.5Z"
          stroke="#B8BBCC"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        ></path>
        <path
          d="M7.5 11.6667L9.16667 13.3333L12.5 10"
          stroke="#B8BBCC"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        ></path>
      </svg>
    </div>
  );
};

export default LevelSvg;
