import React from "react";

const SmallTimer = () => {
  return (
    <>
      <svg
        width="14"
        height="14"
        viewBox="0 0 14 14"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M6.99976 12.2501C5.65809 12.2501 4.48899 11.8053 3.49246 10.9157C2.49594 10.0262 1.92476 8.9154 1.77892 7.58346H2.97476C3.11087 8.59457 3.56052 9.43068 4.32371 10.0918C5.08691 10.7529 5.97892 11.0835 6.99976 11.0835C8.13726 11.0835 9.10219 10.6873 9.89455 9.89491C10.6869 9.10255 11.0831 8.13762 11.0831 7.00012C11.0831 5.86262 10.6869 4.89769 9.89455 4.10533C9.10219 3.31297 8.13726 2.91679 6.99976 2.91679C6.32892 2.91679 5.70184 3.07234 5.11851 3.38346C4.53517 3.69457 4.0442 4.12234 3.64559 4.66679H5.24976V5.83346H1.74976V2.33346H2.91642V3.70429C3.41226 3.08207 4.01746 2.60082 4.73205 2.26054C5.44663 1.92026 6.20253 1.75012 6.99976 1.75012C7.72892 1.75012 8.41191 1.88866 9.04871 2.16575C9.68552 2.44283 10.2397 2.81714 10.7112 3.28866C11.1827 3.76019 11.557 4.31436 11.8341 4.95116C12.1112 5.58797 12.2498 6.27096 12.2498 7.00012C12.2498 7.72929 12.1112 8.41227 11.8341 9.04908C11.557 9.68589 11.1827 10.2401 10.7112 10.7116C10.2397 11.1831 9.68552 11.5574 9.04871 11.8345C8.41191 12.1116 7.72892 12.2501 6.99976 12.2501ZM8.63309 9.45012L6.41642 7.23346V4.08346H7.58309V6.76679L9.44976 8.63346L8.63309 9.45012Z"
          fill="white"
        />
      </svg>
    </>
  );
};

export default SmallTimer;
