import React, { useEffect, useState } from "react";
import CharacterCard from "./CharacterCard";
import { useDispatch, useSelector } from "react-redux";
import { getAllCharacters } from "../../redux/slices/usersSlice";
import { useParams } from "react-router-dom";
import backgroundPattern from "../../images/backgroundPattern.svg";
import { Link } from "react-router-dom";
import { LineWave } from "react-loader-spinner";

const Characters = () => {
  const dispatch = useDispatch();
  const { membershipId } = useParams();
  const { characters, isLoading } = useSelector((state) => state.usersReducer);
  const [active, setActive] = useState(false);

  useEffect(() => {
    dispatch(getAllCharacters(membershipId));
  }, [membershipId, dispatch]);
  return (
    <div>
      {isLoading ? (
        <div className="loader">
          <LineWave color={"#fff"} loading={isLoading} size={150} />
        </div>
      ) : (
        <>
          <div
            className="bg-no-repeat bg-cover py-10 px-14 rounded-xl text-left"
            style={{ backgroundImage: `url(${backgroundPattern})` }}
          >
            <h1 className=" text-white text-[30px]">Characters</h1>
            <Link
              className=" text-red-600 text-[20px]"
              to={`/panel/register/character?mid=${membershipId}`}
            >
              + Register New Character
            </Link>
          </div>
          {characters?.data ? (
            <div className="bg-[#211849] p-[30px] shadow-sm flex flex-wrap rounded-[10px]">
              {characters?.data?.map((character) => {
                return (
                  <CharacterCard
                    data={character}
                    key={character?._id}
                    id={character?._id}
                    setActive={setActive}
                    active={active}
                    uniqueId={membershipId}
                  />
                );
              })}
            </div>
          ) : (
            <div className="text-center bg-[#211849] p-[30px] shadow-sm flex justify-center flex-wrap rounded-[10px]">
              <p className="text-center text-2xl text-gray-500">No Characters Found</p>
            </div>
          )}
        </>
      )}
    </div>
  );
};

export default Characters;
