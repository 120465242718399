import React, { useRef } from "react";
import axios from "axios";
import { Autocomplete } from "@react-google-maps/api";
import moment from "moment-timezone";
import { Field } from "formik";

const LocationInput = ({ name, setFieldValue, setCurrentTime }) => {
  const inputRef = useRef();

  const timezoneHandler = async (place) => {
    const res = await axios.get(
      `https://maps.googleapis.com/maps/api/timezone/json?location=${place.geometry.location.lat()},${place.geometry.location.lng()}&timestamp=${
        new Date(
          moment.tz().utcOffset(place.utc_offset_minutes).toISOString()
        ).getTime() / 1000
      }&key=AIzaSyCRuaF-_J0MNCxM1A9DmlA_VQ9fWPeL0q8`
    );
    return res;
  };

  const handlePlaceChanged = async () => {
    const place = inputRef.current.getPlace();
    setFieldValue("location", place.name);
    const res = await timezoneHandler(place);
    setCurrentTime({
      formattedAddress: place?.formatted_address,
      timezone: res?.data?.timeZoneName,
      timezoneId: res?.data?.timeZoneId,
      lat: place.geometry.location?.lat(),
      lng: place.geometry.location?.lng(),
    });
  };

  return (
    <div>
      <Autocomplete
        onLoad={(ref) => (inputRef.current = ref)}
        onPlaceChanged={handlePlaceChanged}
      >
        <Field
          type="text"
          name={name}
          id="input-group-1"
          placeholder=""
          className="mt-1 block w-full px-3 py-2 bg-[#372F5B] rounded-md shadow-sm focus:outline-none focus:ring focus:ring-red-500 focus:ring-opacity-50"
        />
      </Autocomplete>
    </div>
  );
};

export default LocationInput;
