import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { RxCross1 } from "react-icons/rx";
import { LineWave } from "react-loader-spinner";
import { addGenCoins, removeGenCoins } from "../../redux/slices/usersSlice";
import { toast } from "react-toastify";
import { getAllParticipants } from "../../redux/slices/gameSlice";

const GameGenCoinPopup = ({ cancelGenModel, popUp, selectedData, gameId }) => {
  const dispatch = useDispatch();
  const [genCoins, setGenCoins] = useState("");
  const [error, setError] = useState("");
  const popupTitle =
    popUp?.mode === "Add" ? "Add Gen Coins" : "Remove Gen Coins";
  const { participantsLoading } = useSelector((state) => state?.usersReducer);

  const handleSubmit = (e) => {
    e.preventDefault();
    if (popUp.mode === "Add") {
      dispatch(
        addGenCoins({
          gameId,
          userId: selectedData?.participantData?._id,
          genCoins: parseInt(genCoins),
        })
      ).then((res) => {
        if (res?.payload?.code === 200) {
          toast.success("Gen Coins added Successfully");
          cancelGenModel();
          dispatch(getAllParticipants(gameId));
        }
      });
    } else {
      dispatch(
        removeGenCoins({
          gameId,
          userId: selectedData?.participantData?._id,
          genCoins: parseInt(genCoins),
        })
      ).then((res) => {
        if (res?.payload?.code === 200) {
          toast.success("Gen Coins removed successfully");
          cancelGenModel();
          dispatch(getAllParticipants(gameId));
        }
        if (
          res?.payload?.response?.data?.message ===
          "Removed Points exceeds by points earned"
        ) {
          setError("No Gen Coins to remove!");
        }
      });
    }
  };

  return (
    <div className="fixed inset-0 bg-gray-900 bg-opacity-50 flex items-center justify-center z-50">
      <div className="relative bg-[#150c3f] p-6 rounded-lg shadow-lg text-center w-full max-w-[460px]">
        <button
          className="absolute top-0 sm:right-[-20px] right-0 text-white rounded focus:outline-none text-lg"
          onClick={cancelGenModel}
        >
          <RxCross1 />
        </button>
        <div>
          <h1 className="text-white text-2xl mb-3">{popupTitle}</h1>
          <form onSubmit={handleSubmit}>
            <div className="mb-3">
              <label className="block text-sm text-left font-medium text-white">
                Gen Coins
              </label>
              <input
                type="text"
                inputMode="numeric"
                pattern="[0-9]*"
                onChange={(e) => {
                  setGenCoins(e.target.value);
                  setError("");
                }}
                title="please enter only numeric value"
                className="mt-1 block w-full text-white px-3 py-2 bg-[#372F5B] rounded-md shadow-sm focus:outline-none focus:ring focus:ring-red-500 focus:ring-opacity-50 h-10"
                required
              />
              {error && <p className="text-red-600 text-left mt-3">{error}</p>}
              <div className="text-left text-white text-sm mt-2">
                Total Gen Coins:{" "}
                {selectedData?.participantData?.genCoins
                  ? selectedData?.participantData?.genCoins +
                    selectedData?.participantData?.totalGoonCredits
                  : 0}
              </div>
            </div>

            <button
              type="submit"
              className="bg-red-600 py-2 px-3 text-sm text-white w-full rounded-lg mt-2"
            >
              {popUp?.mode === "Add" ? "Add" : "Remove"}
            </button>
          </form>
        </div>
      </div>
      {participantsLoading && (
        <div className="loader">
          <LineWave color={"#fff"} loading={participantsLoading} size={150} />
        </div>
      )}
    </div>
  );
};

export default GameGenCoinPopup;
