const ScenarioPlayersSvg = () => {
  return (
    <>
      <svg
        width="16"
        height="16"
        viewBox="0 0 16 16"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <ellipse
          cx="7.66653"
          cy="5.20185"
          rx="3.10256"
          ry="2.63043"
          stroke="white"
          strokeLinejoin="round"
        ></ellipse>
        <path
          d="M4 11.4989C4 9.4739 5.64162 7.83228 7.66667 7.83228V7.83228C9.69171 7.83228 11.3333 9.47391 11.3333 11.4989V13.5714H4V11.4989Z"
          stroke="white"
          strokeLinejoin="round"
        ></path>
      </svg>
    </>
  );
};

export default ScenarioPlayersSvg;
