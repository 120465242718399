import React from "react";
import UserSvg from "../../components/svg/UserSvg";
import LevelSvg from "../../components/svg/LevelSvg";
import NonRepeatable from "../../components/svg/NonRepeatable";
import SmallTimer from "../../components/svg/SmallTimer";
import ScenarioSvg from "../../components/svg/ScenarioSvg";
import LocationSvg from "../../components/svg/LocationSvg";
import moment from "moment-timezone";
import TimeSvg from "../../components/svg/TimeSvg";
import OnlineSvg from "../../components/svg/OnlineSvg";
import OflineSvg from "../../components/svg/OflineSvg";
import { useNavigate } from "react-router-dom";

const NewCard = ({ data }) => {
  const navigate = useNavigate();
  const dateTimeString = data?.startDate + data?.startTime;
  const players = data?.noOfPlayer?.split(" ")[0]?.split("-");
  const noOfSpacesLeft =
    players &&
    (players[1] ?? 0) -
      ((data?.participants?.length ?? 0) > 0
        ? (data?.participants?.length ?? 0) - 1
        : 0);

  return (
    <div
      className="xl:w-[33.3%] w-full  md:w-[100%] lg:w-[50%] pr-3 pl-3 mb-5 cursor-pointer"
      onClick={() => navigate(`/panel/gamedetails/${data?._id}`)}
    >
      <div className="card_top relative  rounded-tl-[20px] rounded-tr-[20px] overflow-hidden">
        <div
          className="absolute w-full h-full"
          style={{
            backgroundImage:
              "linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.0086472) 6.67%, rgba(1, 1, 2, 0.03551) 13.33%, rgba(2, 2, 4, 0.0816599) 20%, rgba(4, 3, 7, 0.147411) 26.67%, rgba(6, 5, 10, 0.231775) 33.33%, rgba(8, 7, 15, 0.331884) 40%, rgba(11, 9, 20, 0.442691) 46.67%, rgba(14, 11, 25, 0.557309) 53.33%, rgba(16, 13, 30, 0.668116) 60%, rgba(19, 15, 34, 0.768225) 66.67%, rgba(21, 17, 38, 0.852589) 73.33%, rgba(23, 18, 41, 0.91834) 80%, rgba(24, 19, 43, 0.96449) 86.67%, rgba(24, 20, 44, 0.991353) 93.33%, #19142D 100%)",
          }}
        ></div>
        <img
          src={data?.ScenarioData?.image}
          alt="scenarioImage"
          className="w-full h-[240px] object-cover"
        />
        <div className="absolute top-2 left-0 pl-4 flex items-center gap-2">
          <div className="py-[2px] px-1 bg-[#221C3FB2] text-white rounded-[60px] text-[12px]">
            {players && data.participants.length - 1 === players[1]
              ? "Full"
              : noOfSpacesLeft > 1
              ? `${noOfSpacesLeft} Slots Left`
              : `${noOfSpacesLeft} Slot Left`}
          </div>
          <div className="py-[2px] px-1 bg-[#221C3FB2] text-white rounded-[60px] text-[12px] flex items-center">
            <SmallTimer />
            {`${data?.ScenarioData?.duration}Hr`}
          </div>
          <div className="py-[2px] px-1 bg-[#221C3FB2] text-white rounded-[60px] text-[12px] flex items-center">
            {data?.modes === "online" ? (
              <>
                <OnlineSvg stroke="#fff" height="14" width="14" />
                <span className="ml-1">Online Game</span>
              </>
            ) : (
              <>
                <OflineSvg />
                <span className="ml-1">In-Person Game</span>
              </>
            )}
          </div>
        </div>
        <div className={`absolute bottom-2 left-0 pl-4  pr-4 w-full`}>
          <div className="mb-2 flex items-center justify-between">
            <h2
              className={`text-white text-[14px] lg:text-[16px] font-bold text-left`}
            >
              {data?.gameTitle}
            </h2>
          </div>
          <div className="flex flex-col font-medium text-[#B8BBCC] text-[14px] ">
            <span className="flex items-center gap-1">
              <span className=" inline-block min-w-[14px]">
                <ScenarioSvg height="14px" width="14px" />
              </span>
              <span className=" inline-block truncate">
                {data?.ScenarioData?.name}
              </span>
            </span>
            <div className="flex items-center gap-1">
              <NonRepeatable width="14px" height="14px" />
              <span>
                {data?.ScenarioData?.isRepeatable
                  ? "Repeatable"
                  : "Non repeatable"}
              </span>
              <div className="flex items-center gap-1">
                <LevelSvg height="14px" width="14px" />
                <span>{`Levels ${data?.ScenarioData?.minLevel}-${data?.ScenarioData?.maxLevel}`}</span>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="sm:bg-[#211849] p-4 relative rounded-br-[20px] rounded-bl-[20px] bg-[#fff] sm:shadow-transparent shadow-lg">
        <div className="flex items-center mb-2">
          <LocationSvg width="14px" height="14px" />
          <span className="text-sm text-[#A4A1B2] inline-block pl-2 truncate">
            {data?.onlineLocation
              ? data.onlineLocation
              : data?.specificLocation?.trim()
              ? `${data.specificLocation}, ${data.location[0]?.formattedAddress}`
              : `${data.location[0]?.formattedAddress}`}
          </span>
        </div>
        <div className="flex items-center mb-2">
          <TimeSvg width="14px" height="14px" />
          <span className="text-sm text-[#A4A1B2] inline-block pl-2">
            <span>
              {data?.startDate && data?.startTime && data?.userTimeZone
                ? `${data?.startDate}, 
                ${moment
                  .tz(dateTimeString, "MM-DD-YYYY hh:mm A", data?.userTimeZone)
                  .format("ddd")
                  .toLocaleUpperCase()}, ${data?.startTime} ${moment
                    .tz(data?.userTimeZone)
                    .zoneAbbr()} `
                : "Date/time information not available"}
            </span>
          </span>
        </div>
        <div className="flex items-center">
          <UserSvg height="14px" width="14px" />
          <span className="text-sm text-[#A4A1B2] inline-block pl-2">
            {data?.gmUserName}
          </span>
        </div>
      </div>
    </div>
  );
};

export default NewCard;
