const PointSvg = () => {
  return (
    <>
      <svg
        width="16"
        height="16"
        viewBox="0 0 16 16"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M12.2427 3.68605C11.1094 2.55283 9.60266 1.92871 8 1.92871C6.39734 1.92871 4.89062 2.55283 3.75734 3.68605C2.62412 4.81933 2 6.32605 2 7.92871C2 9.53137 2.62412 11.0381 3.75734 12.1714C4.89062 13.3046 6.39734 13.9287 8 13.9287C9.60266 13.9287 11.1094 13.3046 12.2427 12.1714C13.3759 11.0381 14 9.53137 14 7.92871C14 6.32605 13.3759 4.81933 12.2427 3.68605ZM8 13.2256C5.07929 13.2256 2.70312 10.8494 2.70312 7.92871C2.70312 5.008 5.07929 2.63184 8 2.63184C10.9207 2.63184 13.2969 5.008 13.2969 7.92871C13.2969 10.8494 10.9207 13.2256 8 13.2256Z"
          fill="white"
        ></path>
        <path
          d="M11.85 6.63457C11.8086 6.50731 11.6986 6.41454 11.5662 6.3953L9.31983 6.06889L8.31526 4.03336C8.25603 3.91336 8.1338 3.8374 8 3.8374C7.86619 3.8374 7.74397 3.91339 7.68474 4.03336L6.68016 6.06889L4.43382 6.3953C4.3014 6.41454 4.19138 6.50731 4.15004 6.63457C4.10867 6.76184 4.14317 6.90155 4.23898 6.99495L5.86444 8.57939L5.48072 10.8166C5.45811 10.9485 5.51232 11.0818 5.62058 11.1605C5.72886 11.2392 5.87236 11.2495 5.99082 11.1873L7.99997 10.131L10.0092 11.1873C10.0606 11.2143 10.1168 11.2276 10.1727 11.2276C10.2456 11.2276 10.3182 11.205 10.3794 11.1605C10.4877 11.0818 10.5419 10.9486 10.5192 10.8166L10.1355 8.57939L11.761 6.99495C11.8568 6.90157 11.8913 6.76186 11.85 6.63457ZM9.5124 8.20491C9.42954 8.28568 9.39174 8.40204 9.41129 8.51609L9.70583 10.2334L8.16357 9.42263C8.11236 9.3957 8.05615 9.38225 7.99997 9.38225C7.94379 9.38225 7.88759 9.3957 7.83638 9.42263L6.29412 10.2334L6.58866 8.51611C6.60821 8.40207 6.5704 8.2857 6.48755 8.20493L5.23983 6.98871L6.96413 6.73817C7.07862 6.72153 7.17762 6.6496 7.22883 6.54584L7.99997 4.98333L8.77109 6.54582C8.8223 6.64957 8.9213 6.7215 9.03579 6.73814L10.7601 6.98869L9.5124 8.20491Z"
          fill="white"
        ></path>
      </svg>
    </>
  );
};

export default PointSvg;
