import React, { useState, useEffect, useRef } from "react";
import { useNavigate } from "react-router-dom";
import Cookies from "js-cookie";
import Confirmation from "../confirmation/Confirmation";
function Dropdown() {
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const [popUp, setPopup] = useState(false);
  const dropdownRef = useRef(null);
  const navigate = useNavigate();

  const toggleDropdown = () => {
    setIsDropdownOpen(!isDropdownOpen);
  };

  useEffect(() => {
    function handleClickOutside(event) {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setIsDropdownOpen(false);
      }
    }

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [dropdownRef]);

  const handleLogout = () => {
    setPopup(true);
  };

  const confirmDelete = () => {
    try {
      Cookies.remove("token");
      navigate("/");
    } catch (error) {
      console.error("Logout failed:", error);
    }
  };
  const cancelDelete = () => {
    setPopup(false);
  };
  return (
    <header className="bg-[#150C3F]">
      <nav className="text-white p-4 flex justify-end items-center">
        <div className="relative" ref={dropdownRef}>
          <div className="flex items-center gap-3">
            <button
              className="text-red-600 font-bold py-2 px-4 rounded inline-block"
              onClick={handleLogout}
            >
              Logout
            </button>
            <button onClick={toggleDropdown} className="focus:outline-none ">
              <img
                src="/assets/images/avatar2.png"
                alt="Profile"
                width={40}
                height={40}
                className="rounded-full"
              />
            </button>
          </div>
        </div>
      </nav>
      {popUp && (
        <Confirmation
          confirmDelete={confirmDelete}
          cancelDelete={cancelDelete}
          message="Logout"
          buttonText="Logout"
        />
      )}
    </header>
  );
}

export default Dropdown;
