import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import moment from "moment-timezone";
import { LineWave } from "react-loader-spinner";
import Calendar from "react-calendar";
import { RxCross1 } from "react-icons/rx";
import "react-calendar/dist/Calendar.css";
import Timekeeper from "react-timekeeper";
import { createGame, updateGame } from "../../redux/slices/gameSlice";
import { getCurrentDateTime } from "../../utils/api";

const ScheduleGame = ({
  cancelModel,
  currentTime,
  formData,
  selectedScenario,
  id,
}) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [selectedDate, setSelectedDate] = useState();
  const [time, setTime] = useState();
  const [updateDate, setUpdateDate] = useState();
  const [updateTime, setUpdateTime] = useState();
  const [timeZoneError, setTimeZoneError] = useState(false);
  const [loading, setLoading] = useState(false);
  const [openTimeKeeper, setOpenTimeKeeper] = useState(false);
  const [initialLoad, setInitialLoad] = useState(true); // New state to control initial load

  const gameData = useSelector((state) => state.gameReducer);
  const today = moment().startOf("day").toDate();
  const prevSelDate = selectedDate;
  const prevSelTime = time;
  const timeNow = formData.playMode === "offline" ? moment().format("h:mm A") : moment().tz(formData?.Timezone).format("h:mm A");
  const DateNow = formData.playMode === "offline" ? moment().format("MM-DD-YYYY") : moment().tz(formData?.Timezone).format("MM-DD-YYYY");

  // Effect for initial setup
  useEffect(() => {
    if (initialLoad) {
      if (!id) {
        setCurrentTime();
      } else {
        setSelectedDate(
          gameData?.gameDetail?.data?.startDate?.replace(/-/g, "/")
        );
        setUpdateDate(
          gameData?.gameDetail?.data?.startDate?.replace(/-/g, "/")
        );
        setTime(gameData?.gameDetail?.data?.startTime);
      }
      setInitialLoad(false); // Set to false after initial setup
    }
  }, [id, gameData, initialLoad]);

  const handleCreateGame = async () => {
    let payload;
    if (formData.playMode === "offline") {
      payload = {
        gameTitle: formData?.gameTitle.trim(),
        modes: formData?.playMode,
        formattedAddress: currentTime?.formattedAddress,
        geo: `${currentTime?.lng}, ${currentTime?.lat}`,
        scenarioId: selectedScenario?._id,
        isScheduled: true,
        locationName: formData?.location,
        startTime: time ? time : prevSelTime,
        startDate: selectedDate
          ? moment(selectedDate).format("MM-DD-YYYY")
          : moment(prevSelDate).format("MM-DD-YYYY"),
        userTimeZone: currentTime?.timezoneId,
        regionTimeZone: currentTime?.timezone,
        membershipId: formData?.memberShipId.trim(),
        specificLocation: formData?.specificLocation.trim(),
        notes: formData?.notes.trim(),
      };
      if (id) {
        payload = {
          ...payload,
          status: "NOTSTARTED",
          startTime: updateTime ? updateTime : prevSelTime,
          startDate: updateDate
            ? moment(updateDate).format("MM-DD-YYYY")
            : moment(prevSelDate).format("MM-DD-YYYY"),
        };
      }
    } else {
      payload = {
        gameTitle:
          typeof formData?.gameTitle === "string"
            ? formData.gameTitle.trim()
            : "",
        scenarioId: selectedScenario?._id,
        isScheduled: true,
        userTimeZone: formData?.Timezone.toLowerCase(),
        modes: formData?.playMode,
        startTime: time ? time : prevSelTime,
        startDate: selectedDate
          ? moment(selectedDate).format("MM-DD-YYYY")
          : moment(prevSelDate).format("MM-DD-YYYY"),
        gameLink: formData?.gameLink,
        membershipId:
          typeof formData?.memberShipId === "string"
            ? formData.memberShipId.trim()
            : "",
        onlineLocation: formData?.location,
        notes: typeof formData?.notes === "string" ? formData.notes.trim() : "",
      };
      if (id) {
        payload = {
          ...payload,
          status: "NOTSTARTED",
          startTime: updateTime ? updateTime : prevSelTime,
          startDate: updateDate
            ? moment(updateDate).format("MM-DD-YYYY")
            : moment(prevSelDate).format("MM-DD-YYYY"),
        };
      }
    }
    if (id) {
      dispatch(updateGame({ gameId: id, body: payload })).then((res) => {
        if (res.payload.code === 200) {
          toast.success("Game Updated Successfully");
          navigate("/panel/games");
        } else {
          if (res.payload.response.data.message === "Invalid Membership Id") {
            toast.error("Invalid Membership Id");
          } else {
            toast.error("Something Went Wrong");
          }
        }
      });
    } else {
      dispatch(createGame(payload)).then((res) => {
        if (res.payload.code === 200) {
          toast.success("Game Scheduled Successfully");
          navigate("/panel/games");
        } else {
          if (res.payload.response.data.message === "Invalid Membership Id") {
            toast.error("Invalid Membership Id");
          } else {
            toast.error("Something Went Wrong");
          }
        }
      });
    }
  };

  const handleChange = (date) => {
    if (!id) {
      setSelectedDate(moment(date).format("MM/DD/YYYY"));
      setUpdateDate(moment(date).format("MM/DD/YYYY"));
    } else {
      setUpdateDate(moment(date).format("MM/DD/YYYY"));
    }

    const formattedTime = moment((updateTime ?? time), "HH:mm A").format("h:mm A");
    const dateFormat = "MM-DD-YYYY";
    const timeFormat = "h:mm a";
    const dateToUse = moment(date).format('MM-DD-YYYY');
    const providedDateTime = moment(`${dateToUse} ${formattedTime}`, `${dateFormat} ${timeFormat}`);
    const currentDateTime = moment(`${DateNow} ${timeNow}`, `${dateFormat} ${timeFormat}`);

    if (providedDateTime.isBefore(currentDateTime)) {
      setTimeZoneError(true);
    } else {
      setTimeZoneError(false);
    }
  };

  const handleTimeChange = (newTime) => {
    const formattedTime = moment(newTime.formatted24, "HH:mm").format("h:mm A");
    const dateFormat = "MM-DD-YYYY";
    const timeFormat = "h:mm a";
    const dateToUse = moment(updateDate ?? selectedDate).format('MM-DD-YYYY');
    const providedDateTime = moment(`${dateToUse} ${formattedTime}`, `${dateFormat} ${timeFormat}`);
    const currentDateTime = moment(`${DateNow} ${timeNow}`, `${dateFormat} ${timeFormat}`);

    if (providedDateTime.isBefore(currentDateTime)) {
      setTimeZoneError(true);
    } else {
      setTimeZoneError(false);
    }

    if (!id) {
      setTime(formattedTime);
    } else {
      setUpdateTime(formattedTime);
    }
  };

  const setCurrentTime = async () => {
    try {
      setLoading(true);
      const res = await getCurrentDateTime(
        formData.Timezone ? formData.Timezone : currentTime?.timezoneId
      );
      setLoading(false);
      setSelectedDate(res.date.replace(/-/g, "/"));
      setUpdateDate(res.date.replace(/-/g, "/"));
      setTime(res.time);
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <div className="fixed inset-0 bg-gray-900 bg-opacity-50 flex items-center justify-center z-50">
      <div className="relative bg-[#150c3f] p-6 rounded-lg shadow-lg text-center w-full max-w-[460px]">
        <button
          className="absolute top-0 sm:right-[-20px] right-0 text-white rounded focus:outline-none text-lg"
          onClick={cancelModel}
        >
          <RxCross1 />
        </button>
        <div className="flex justify-between border-b border-white py-3">
          <button
            className="text-red-600 bg-white px-2 py-1 rounded hover:bg-gray-400 focus:outline-none"
            onClick={cancelModel}
          >
            Cancel
          </button>
          <button disabled={timeZoneError} type="button" name={timeZoneError}
            className={timeZoneError ? "text-white bg-gray-600 px-2 py-1 rounded hover:bg-gray-600 focus:outline-none"  : "text-white bg-red-600 px-2 py-1 rounded hover:bg-red-600 focus:outline-none"}
            onClick={() => {
              handleCreateGame();
            }}
          >
            Schedule
          </button>
        </div>
        <div className="mb-4 text-white text-left">
          {id ? (
            <div className=" flex justify-between items-center py-3 mb-4  border-b border-[#B8BBCC]">
              <p className="text-[16px] font-semibold">
                Prev Schedule Date/Time
              </p>
              <button
                className=" text-white bg-[#7676801a] p-2 rounded-md"
                onClick={() => setOpenTimeKeeper(false)}
              >
                {selectedDate && moment(selectedDate).format("MM-DD-YYYY")}
              </button>
              <button onClick={() => setOpenTimeKeeper(true)}>{time}</button>
            </div>
          ) : (
            <div className=" flex justify-between items-center py-3 mb-4  border-b border-[#B8BBCC]">
              <p className="text-[16px] font-semibold">
                Current Schedule Date/Time
              </p>
              <button
                className=" text-white bg-[#7676801a] p-2 rounded-md"
                onClick={() => setOpenTimeKeeper(false)}
              >
                <span>
                  {formData?.playMode === "online"
                    ? moment(selectedDate)
                        .tz(formData?.Timezone)
                        .format("MM-DD-YYYY")
                    : moment(selectedDate).format("MM-DD-YYYY")}
                </span>
              </button>
              <button onClick={() => setOpenTimeKeeper(true)}>
                {formData?.playMode === "online"
                  ? moment(`${selectedDate} ${time}`).tz(formData?.Timezone).format("h:mm A")
                  : moment(`${selectedDate} ${time}`).format("h:mm A")}
              </button>
            </div>
          )}
          {timeZoneError && (
            <p className="text-red-600 text-center p-2">{`The time selected is past the current time ${timeNow}`}</p>
          )}
          {openTimeKeeper ? (
            <Timekeeper
              time={updateTime ?? time}
              onChange={(newTime) => handleTimeChange(newTime)}
            />
          ) : (
            selectedDate && (
              <Calendar
                value={updateDate ?? selectedDate}
                className="w-full !important"
                onChange={handleChange}
                minDate={today}
              />
            )
          )}
        </div>
      </div>
      {loading && (
        <div className="loader">
          <LineWave color={"#fff"} loading={loading} size={150} />
        </div>
      )}
    </div>
  );
};

export default ScheduleGame;
