import { useState } from 'react'
import { toast } from 'react-toastify'
const useCopyToClipboard = () => {
    const [isCopied,setIscopied] = useState(false)
    const copyToClipboard = async (text)=>{
        try{
          await navigator.clipboard.writeText(text)
          setIscopied(true)
          toast.success(`Copy to Clipboard: ${text}`)
        }catch(error){
          throw error;
        }
    }
  return {isCopied,copyToClipboard}
}

export default useCopyToClipboard;