import React from "react";
import { updateCharacter } from "../../redux/slices/characterSlice";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { useParams, useLocation } from "react-router-dom";
import { toast } from "react-toastify";

const AvatarCard = ({ data }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);

  const userId = queryParams.get("key");
    const membershipId = queryParams.get("mid");
  const { characterId } = useParams();
  const handleAvatar = (image) => {
    const formData = new FormData();
      formData.append("userId",userId)
      formData.append("image",image)
    dispatch(
      updateCharacter({
        id: characterId,
        data: formData,
      })
    ).then((res)=>{
        if(res?.payload?.code === 200){
            navigate(`/panel/characters/${membershipId}`)
            toast.success(res?.payload?.message)
        }
        else{
            navigate(`/panel/characters/${membershipId}`)
            toast.error("Something Went Wrong")
        }
    }).catch((error)=>{
        console.log("Error",error)
        navigate(`/panel/characters/${membershipId}`)
        toast.error("Something Went Wrong")
    })
  };
  return (
    <div
      className="w-[16.66%] pr-[20px] pl-[20px] mb-[30px]"
      onClick={()=>{handleAvatar(data?.image)}}
    >
      <img src={data?.image} className=" w-full" />
    </div>
  );
};

export default AvatarCard;
