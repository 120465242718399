const ScenarioLevelsSvg = () => {
  return (
    <>
      <svg
        width="16"
        height="16"
        viewBox="0 0 16 16"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M8.07972 2H6.80988C6.10857 2 5.54004 2.56853 5.54004 3.26984C5.54004 3.97116 6.10857 4.53968 6.80988 4.53968H8.07972C8.78104 4.53968 9.34956 3.97116 9.34956 3.26984C9.34956 2.56853 8.78104 2 8.07972 2Z"
          stroke="white"
          strokeLinecap="round"
          strokeLinejoin="round"
        ></path>
        <path
          d="M5.53968 3.27002H4.26984C3.93306 3.27002 3.61007 3.40381 3.37193 3.64195C3.13379 3.88009 3 4.20308 3 4.53986V12.1589C3 12.4957 3.13379 12.8187 3.37193 13.0568C3.61007 13.295 3.93306 13.4288 4.26984 13.4288H10.619C10.9558 13.4288 11.2788 13.295 11.517 13.0568C11.7551 12.8187 11.8889 12.4957 11.8889 12.1589V4.53986C11.8889 4.20308 11.7551 3.88009 11.517 3.64195C11.2788 3.40381 10.9558 3.27002 10.619 3.27002H9.34921"
          stroke="white"
          strokeLinecap="round"
          strokeLinejoin="round"
        ></path>
        <path
          d="M5.54004 8.9842L6.80988 10.254L9.34956 7.71436"
          stroke="white"
          strokeLinecap="round"
          strokeLinejoin="round"
        ></path>
      </svg>
    </>
  );
};

export default ScenarioLevelsSvg;
