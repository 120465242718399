import axios from "axios";

const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;

export const loginAPI = async (formData) => {
  try {
    const response = await axios.post(`${API_BASE_URL}/admin/login`, formData);
    return response?.data;
  } catch (error) {
    throw error;
  }
};

export const getCurrentDateTime = async (timezoneId) => {
  try {
    const res = await axios.post(`${API_BASE_URL}/misc/current-time`, {
      utc_offset_minutes: timezoneId,
    });
    return res?.data?.data;
  } catch (error) {
    console.log(error);
  }
}