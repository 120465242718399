import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import http from "../../utils/http";

const initialState = {
  isLoading: false,
  dashboard: {},
};

export const getDashboard = createAsyncThunk(
  "application/getDashboard",
  async (_, thunkAPI) => {
    try {
      const response = await http.get("/admin/dashboard");
      return thunkAPI.fulfillWithValue(response.data);
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);

const dashboardSlice = createSlice({
  name: "dashboard",
  initialState,
  extraReducers: (builder) => {
    builder
      .addCase(getDashboard.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getDashboard.fulfilled, (state, action) => {
        state.isLoading = false;
        state.dashboard = action.payload;
      })
      .addCase(getDashboard.rejected, (state) => {
        state.isLoading = false;
      });
  },
});
export default dashboardSlice.reducer;
