import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { RxCross1 } from "react-icons/rx";
import {
  addGenCoins,
  getAllUsers,
  removeGenCoins,
} from "../../redux/slices/usersSlice";
import { toast } from "react-toastify";

const GencoinsPopup = ({
  cancelGenModel,
  action,
  setFilterdUsers,
  currentPage,
}) => {
  const dispatch = useDispatch();
  const [genCoins, setGenCoins] = useState("");
  const [error, setError] = useState("");

  const popupTitle =
    action?.mode === "Assign" ? "Add Gen Coins" : "Remove Gen Coins";

  const handleSubmit = (e) => {
    e.preventDefault();
    if (action.mode === "Assign") {
      dispatch(
        addGenCoins({
          userId: action?.user?.userId,
          genCoins: parseInt(genCoins),
        })
      ).then((res) => {
        if (res?.payload?.code === 200) {
          toast.success("Gen Coins added Successfully");
          cancelGenModel();
          dispatch(getAllUsers({ pageParams: currentPage })).then((res) => {
            setFilterdUsers(res.payload.data);
          });
        }
      });
    } else {
      dispatch(
        removeGenCoins({
          userId: action?.user?.userId,
          genCoins: parseInt(genCoins),
        })
      ).then((res) => {
        if (res?.payload?.code === 200) {
          toast.success("Gen Coins removed successfully");
          cancelGenModel();
          dispatch(getAllUsers({ pageParams: currentPage })).then((res) => {
            setFilterdUsers(res.payload.data);
          });
        }
        if (
          res?.payload?.response?.data?.message ===
          "Removed Points exceeds by points earned"
        ) {
          setError("No Gen Coins to remove!");
        }
      });
    }
  };

  return (
    <div className="fixed inset-0 bg-gray-900 backdrop-blur-sm  bg-opacity-50 flex items-center justify-center z-50">
      <div className="relative bg-[#150c3f] p-6 rounded-lg shadow-lg text-center w-full max-w-[460px]">
        <button
          className="absolute top-0 sm:right-[-20px] right-0 text-white rounded focus:outline-none text-lg"
          onClick={cancelGenModel}
        >
          <RxCross1 />
        </button>
        <div>
          <h1 className="text-white text-2xl mb-3">{popupTitle}</h1>
          <form onSubmit={handleSubmit}>
            <div className="mb-3">
              <label className="block text-sm text-left font-medium text-white">
                Gen Coins
              </label>
              <input
                type="text"
                inputMode="numeric"
                pattern="[0-9]*"
                onChange={(e) => {
                  setGenCoins(e.target.value);
                  setError("");
                }}
                title="please enter only numeric value"
                className="mt-1 block w-full text-white px-3 py-2 bg-[#372F5B] rounded-md shadow-sm focus:outline-none focus:ring focus:ring-red-500 focus:ring-opacity-50 h-10"
                required
              />
              {error && <p className="text-red-600 text-left mt-3">{error}</p>}
              <div className="text-left text-white text-sm mt-2">
                Total Gen Coins:{" "}
                {action?.user?.genCoins
                  ? action?.user?.genCoins + action?.user?.totalGoonCredit
                  : 0}
              </div>
            </div>

            <button
              type="submit"
              className="bg-red-600 py-2 px-3 text-sm text-white w-full rounded-lg mt-2"
            >
              {action?.mode === "Assign" ? "Add" : "Remove"}
            </button>
          </form>
        </div>
      </div>
    </div>
  );
};

export default GencoinsPopup;
