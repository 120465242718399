import React, { useEffect, useState } from "react";
import { Formik, Form, Field, ErrorMessage } from "formik";
import { LineWave } from "react-loader-spinner";
import { FaEye, FaEyeSlash } from "react-icons/fa";
import * as Yup from "yup";
import {
  AddUser,
  updateUser,
  getUserById,
  updateUserDb,
} from "../../redux/slices/usersSlice";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";

const CreateUser = () => {
  const [initialValues, setInitialValues] = useState({
    email: "",
    password: "",
    firstName: "",
    lastName: "",
  });

  const [isUpdateMode, setIsUpdateMode] = useState(false);
  const dispatch = useDispatch();
  const { id } = useParams();
  const navigate = useNavigate();
  const userData = useSelector((state) => state.usersReducer);
  const [ispasswordVisible, setIsPasswordVisible] = useState(false);
  const [isConfirmPasswordVisible, setIsConfirmPasswordVisible] =
    useState(false);

  const validationSchema = Yup.object({
    email: Yup.string()
      .email("Invalid email address")
      .required("Email is Required"),
    password: isUpdateMode
      ? Yup.string()
      : Yup.string()
          .min(8, "Password must be at least 8 characters")
          .matches(
            /^(?!.*\s)(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[\W_]).+$/,
            "Password must contain an uppercase letter, a lowercase letter, a number, and a special character"
          )
          .required("Password is Required"),
    confirmPassword: isUpdateMode
      ? Yup.string()
      : Yup.string()
          .oneOf([Yup.ref("password"), null], "Passwords must match")
          .required("Confirm Password is Required"),
    firstName: Yup.string().required("First Name is Required"),
    lastName: Yup.string().required("Last Name is Required"),
  });

  const handlePasswordToggle = () => {
    setIsPasswordVisible((prev) => !prev);
  };
  const handleConfirmPasswordToggle = () => {
    setIsConfirmPasswordVisible((prev) => !prev);
  };
  useEffect(() => {
    if (id) {
      setIsUpdateMode(true);
      dispatch(getUserById(id))
        .then((res) => {
          const { data } = res?.payload;
          const fullName = data?.name;
          const [firstName = "", ...lastNameParts] = fullName.split(" ");
          const lastName = lastNameParts.join(" ");
          setInitialValues({
            email: data?.email,
            firstName: firstName,
            lastName: lastName,
          });
        })
        .catch((error) => {
          console.error("Error fetching user data:", error);
          toast.error("Error fetching user data.");
        });
    } else {
      setIsUpdateMode(false);
    }
  }, [id, dispatch]);

  const handleSubmit = (values, { setSubmitting, resetForm }) => {
    if (id) {
      dispatch(
        updateUser({
          first_name: values.firstName,
          last_name: values.lastName,
          email: values.email,
        })
      )
        .then((res) => {
          dispatch(
            updateUserDb({
              id: id,
              first_name: res?.payload?.data?.first_name,
              last_name: res?.payload?.data?.last_name,
              email: res?.payload?.data?.email,
              profile_image: {},
              name: `${res?.payload?.data?.first_name} ${res?.payload?.data?.last_name}`,
            })
          )
            .then(() => {
              toast.success("User Updated Successfully");
              navigate("/panel/users");
            })
            .catch((error) => {
              toast.error("Something Went Wrong");
              console.error("Error updating user:", error);
            });
        })
        .catch((error) => {
          toast.error("Error updating user");
          console.log(error);
        });
    } else {
      dispatch(
        AddUser({
          first_name: values.firstName,
          last_name: values.lastName,
          email: values.email,
          password: values.password,
          requested_by: "admin",
        })
      )
        .then((res) => {
          if (res?.payload?.code === 200) {
            dispatch(
              updateUserDb({
                id: res?.payload?.data?.user_id,
                first_name: res?.payload?.data?.first_name,
                last_name: res?.payload?.data?.last_name,
                email: res?.payload?.data?.email,
                profile_image: {},
                name: `${res?.payload?.data?.first_name} ${res?.payload?.data?.last_name}`,
              })
            )
              .then(() => {
                resetForm();
                toast.success("User Created Successfully");
                navigate("/panel/users");
              })
              .catch((error) => {
                toast.error("Something Went Wrong");
                console.error("Error creating user:", error);
              });
          } else {
            toast.error("Email Already Exists ");
          }
        })
        .catch((error) => {
          toast.error("Something Went Wrong");
          console.error("Error creating user:", error);
        })
        .finally(() => {
          setSubmitting(false);
        });
    }
  };

  return (
    <div className="max-w-7xl mx-auto mt-8 p-6 bg-[#211849] shadow-md rounded-lg text-left text-white">
      <h1 className="text-2xl font-bold mb-4">
        {id ? "Edit User" : "Create User"}
      </h1>
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={handleSubmit}
        enableReinitialize={true}
      >
        {({ isSubmitting }) => (
          <Form>
            <div className="mb-4">
              <label
                htmlFor="firstName"
                className="block text-sm font-medium text-white"
              >
                First Name
              </label>
              <Field
                type="text"
                id="firstName"
                name="firstName"
                className="mt-1 block w-full px-3 py-2 bg-[#372F5B] rounded-md shadow-sm focus:outline-none focus:ring focus:ring-red-500 focus:ring-opacity-50"
              />
              <ErrorMessage
                name="firstName"
                component="div"
                className="text-red-600 text-sm mt-1"
              />
            </div>

            <div className="mb-4">
              <label
                htmlFor="lastName"
                className="block text-sm font-medium text-white"
              >
                Last Name
              </label>
              <Field
                type="text"
                id="lastName"
                name="lastName"
                className="mt-1 block w-full px-3 py-2 bg-[#372F5B] rounded-md shadow-sm focus:outline-none focus:ring focus:ring-red-500 focus:ring-opacity-50"
              />
              <ErrorMessage
                name="lastName"
                component="div"
                className="text-red-600 text-sm mt-1"
              />
            </div>

            <div className="mb-4">
              <label
                htmlFor="email"
                className="block text-sm font-medium text-white"
              >
                Email
              </label>
              <Field
                type="email"
                id="email"
                name="email"
                className="mt-1 block w-full px-3 py-2 bg-[#372F5B] rounded-md shadow-sm focus:outline-none focus:ring focus:ring-red-500 focus:ring-opacity-50"
                disabled={id}
              />
              <ErrorMessage
                name="email"
                component="div"
                className="text-red-600 text-sm mt-1"
              />
            </div>
            {!isUpdateMode && (
              <div className="mb-4 relative">
                <label
                  htmlFor="password"
                  className="block text-sm font-medium text-white"
                >
                  Password
                </label>
                <Field
                  id="password"
                  type={ispasswordVisible ? "text" : "password"}
                  name="password"
                  className="mt-1 block w-full px-3 py-2 bg-[#372F5B] rounded-md shadow-sm focus:outline-none focus:ring focus:ring-red-500 focus:ring-opacity-50"
                />
                <span
                  className="absolute top-[35px] right-2 cursor-pointer"
                  onClick={handlePasswordToggle}
                >
                  {ispasswordVisible ? <FaEye /> : <FaEyeSlash />}
                </span>
                <ErrorMessage
                  name="password"
                  component="div"
                  className="text-red-600 text-sm mt-1"
                />
              </div>
            )}
            {!isUpdateMode && (
              <div className="mb-4 relative">
                <label
                  htmlFor="confirmPassword"
                  className="block text-sm font-medium text-white"
                >
                  Confirm Password
                </label>
                <Field
                  id="confirmPassword"
                  type={isConfirmPasswordVisible ? "text" : "password"}
                  name="confirmPassword"
                  className="mt-1 block w-full px-3 py-2 bg-[#372F5B] rounded-md shadow-sm focus:outline-none focus:ring focus:ring-red-500 focus:ring-opacity-50"
                />
                <span
                  className="absolute top-[35px] right-2 cursor-pointer"
                  onClick={handleConfirmPasswordToggle}
                >
                  {isConfirmPasswordVisible ? <FaEye /> : <FaEyeSlash />}
                </span>
                <ErrorMessage
                  name="confirmPassword"
                  component="div"
                  className="text-red-600 text-sm mt-1"
                />
              </div>
            )}
            <div className="mb-4">
              <button
                type="submit"
                disabled={isSubmitting}
                className="bg-red-500 text-white font-bold py-2 px-4 rounded"
              >
                {id ? "Update" : "Submit"}
              </button>
            </div>
          </Form>
        )}
      </Formik>
      {userData?.isLoading && (
        <div className="loader">
          <LineWave color={"#fff"} loading={userData?.isLoading} size={150} />
        </div>
      )}
    </div>
  );
};

export default CreateUser;
