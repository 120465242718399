import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import http from "../../utils/http";

const initialState = {
  isLoading: false,
  settings: [],
};

export const getAllBanners = createAsyncThunk(
  "application/getAllBanners",
  async (_, thunkAPI) => {
    try {
      const response = await http.get(
        "admin/settings/dashboard-banners/fetch-all-home-banners"
      );
      return thunkAPI.fulfillWithValue(response.data);
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);

export const getBannerById = createAsyncThunk(
  "application/getBannerById",
  async (payload, thunkAPI) => {
    try {
      const response = await http.get(
        `admin/settings/dashboard-banners/${payload}`
      );
      return thunkAPI.fulfillWithValue(response.data);
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);

export const addBannerImage = createAsyncThunk(
  "application/addBannerImage",
  async (payload, thunkAPI) => {
    try {
      const response = await http.post(
        "admin/settings/dashboard-banners",
        {
          image: payload?.image,
          title: payload?.title.trim(),
          description: payload?.description.trim(),
        },
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );
      return thunkAPI.fulfillWithValue(response.data);
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);

export const updateBannerImage = createAsyncThunk(
  "application/updateBannerImage",
  async (payload, thunkAPI) => {
    try {
      const response = await http.patch(
        `admin/settings/dashboard-banners/${payload?.id}`, 
        {
          image: payload?.body?.image,
          title: payload?.body?.title.trim(),
          description: payload?.body?.description.trim(),
        },
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );
      return thunkAPI.fulfillWithValue(response.data);
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);

export const deleteBannerImage = createAsyncThunk(
  "application/deleteBannerImage",
  async (payload, thunkAPI) => {
    try {
      const response = await http.delete(
        `admin/settings/dashboard-banners/${payload?.id}`
      );
      return thunkAPI.fulfillWithValue(response.data);
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);

const settingsSlice = createSlice({
  name: "settings",
  initialState,
  extraReducers: (builder) => {
    builder
      .addCase(getAllBanners.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getAllBanners.fulfilled, (state, action) => {
        state.isLoading = false;
        state.settings = action.payload;
      })
      .addCase(getAllBanners.rejected, (state) => {
        state.isLoading = false;
        state.settings = []
      })
      .addCase(getBannerById.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getBannerById.fulfilled, (state, action) => {
        state.isLoading = false;
        state.settings = action.payload;
      })
      .addCase(getBannerById.rejected, (state) => {
        state.isLoading = false;
        state.settings = []
      })
      .addCase(addBannerImage.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(addBannerImage.fulfilled, (state) => {
        state.isLoading = false;
      })
      .addCase(addBannerImage.rejected, (state) => {
        state.isLoading = false;
      })
      .addCase(updateBannerImage.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(updateBannerImage.fulfilled, (state) => {
        state.isLoading = false;
      })
      .addCase(updateBannerImage.rejected, (state) => {
        state.isLoading = false;
      })
      .addCase(deleteBannerImage.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(deleteBannerImage.fulfilled, (state) => {
        state.isLoading = false;
      })
      .addCase(deleteBannerImage.rejected, (state) => {
        state.isLoading = false;
      });
  },
});

export default settingsSlice.reducer;
