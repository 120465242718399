import React from "react";

const NonRepeatable = () => {
  return (
    <>
      <svg
        width="14"
        height="14"
        viewBox="0 0 14 14"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M4.66248 11.5847L3.33865 10.2608H10.2054H10.2055C11.2177 10.2596 12.038 9.43933 12.0392 8.4271V8.42704V7V6.95H11.9892H11.2757H11.2257V7V8.42704C11.2257 8.99049 10.7689 9.44732 10.2054 9.44732H3.33865L4.66248 8.12349L4.69783 8.08814L4.66248 8.05278L4.158 7.5483L4.12264 7.51294L4.08729 7.5483L2.0691 9.56649L2.06909 9.5665C1.9103 9.72538 1.9103 9.98279 2.06909 10.1417L2.0691 10.1417L4.08729 12.1599L4.12264 12.1952L4.158 12.1599L4.66248 11.6554L4.69783 11.62L4.66248 11.5847Z"
          fill="#B8BBCC"
          stroke="#B8BBCC"
          strokeWidth="0.1"
        />
        <path
          d="M10.6506 3.73916L3.7838 3.73916L3.78375 3.73916C2.77151 3.74032 1.95116 4.56067 1.95 5.5729V5.57296V7V7.05H2H2.71352H2.76352V7V5.57296C2.76352 5.00943 3.22027 4.55268 3.7838 4.55268H10.6506L9.32675 5.87651L9.29139 5.91186L9.32675 5.94722L9.83123 6.4517L9.86659 6.48706L9.90194 6.4517L11.9201 4.43351L11.9201 4.4335C12.0789 4.27462 12.0789 4.01722 11.9201 3.85833L11.9201 3.85832L9.90194 1.84013L9.86659 1.80478L9.83123 1.84013L9.32675 2.34461L9.29139 2.37997L9.32675 2.41533L10.6506 3.73916Z"
          fill="#B8BBCC"
          stroke="#B8BBCC"
          strokeWidth="0.1"
        />
        <line
          x1="2.35355"
          y1="1.64645"
          x2="12.3536"
          y2="11.6464"
          stroke="#B8BBCC"
        />
      </svg>
    </>
  );
};

export default NonRepeatable;
