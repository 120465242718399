import React from "react";

const LocationSvg = ({ width, height }) => {
  return (
    <div>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width={width}
        height={height}
        viewBox="0 0 12 13"
        fill="none"
      >
        <g clipPath="url(#clip0_5133_3473)">
          <path
            d="M6 7.49976C6.82843 7.49976 7.5 6.82818 7.5 5.99976C7.5 5.17133 6.82843 4.49976 6 4.49976C5.17157 4.49976 4.5 5.17133 4.5 5.99976C4.5 6.82818 5.17157 7.49976 6 7.49976Z"
            stroke="#B8BBCC"
            strokeWidth="0.857143"
            strokeLinecap="round"
            strokeLinejoin="round"
          ></path>
          <path
            d="M8.8285 8.8286L6.707 10.9501C6.61422 11.043 6.50405 11.1166 6.38278 11.1669C6.26151 11.2172 6.13153 11.243 6.00025 11.243C5.86898 11.243 5.73899 11.2172 5.61772 11.1669C5.49645 11.1166 5.38628 11.043 5.2935 10.9501L3.1715 8.8286C2.61211 8.26918 2.23117 7.55645 2.07685 6.78053C1.92252 6.00461 2.00175 5.20036 2.3045 4.46946C2.60726 3.73857 3.11995 3.11387 3.77774 2.67435C4.43553 2.23483 5.20889 2.00024 6 2.00024C6.79112 2.00024 7.56447 2.23483 8.22226 2.67435C8.88005 3.11387 9.39274 3.73857 9.6955 4.46946C9.99826 5.20036 10.0775 6.00461 9.92316 6.78053C9.76883 7.55645 9.38789 8.26918 8.8285 8.8286Z"
            stroke="#B8BBCC"
            strokeWidth="0.857143"
            strokeLinecap="round"
            strokeLinejoin="round"
          ></path>
        </g>
        <defs>
          <clipPath id="clip0_5133_3473">
            <rect
              width="12"
              height="12"
              fill="white"
              transform="translate(0 0.5)"
            ></rect>
          </clipPath>
        </defs>
      </svg>
    </div>
  );
};

export default LocationSvg;
