import React, { useState } from "react";
import { RxCross1 } from "react-icons/rx";
import { LineWave } from "react-loader-spinner";
import {
  addMilestonePoints,
  removeMilestonePoints,
} from "../../redux/slices/usersSlice";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import { FaCircleInfo } from "react-icons/fa6";
import { getAllParticipants } from "../../redux/slices/gameSlice";

const GameMilestonePopup = ({ selectedData, cancelModel, popUp, gameId }) => {

  const popupTitle = popUp?.mode === "Add" ? "Add Milestone Points" : "Remove Milestone Points";
  const dispatch = useDispatch();
  const [milestonePoints, setMilestonePoints] = useState();
  const [error, setError] = useState("");
  const { isLoading } = useSelector((state) => state?.usersReducer);

  const handleSubmit = (e) => {
    e.preventDefault();
    if (popUp?.mode === "Add") {
      dispatch(
        addMilestonePoints({
          characterId: selectedData?.participantData?.characterDetails?._id,
          milestonePoints: parseInt(milestonePoints),
          gameId,
        })
      ).then((res) => {
        if (res.payload.code === 200) {
          toast.success("Added Milestone points Successfully");
          cancelModel();
          dispatch(getAllParticipants(gameId));
        } else {
          if (
            res?.payload?.response?.data?.message ===
            "Exceeding the maximum limit for points"
          ) {
            setError(res?.payload?.response?.data?.message);
          }
        }
        if (
          res?.payload?.response?.data?.message ===
          "Milestone Points should not exceed by 60"
        ) {
          setError("Milestone points must be less than or equal to 60");
        }
      });
    } else {
      dispatch(
        removeMilestonePoints({
          characterId: selectedData?.participantData?.characterDetails?._id,
          milestonePoints: parseInt(milestonePoints),
          gameId,
        })
      ).then((res) => {
        if (res?.payload?.code === 200) {
          toast.success("Milestone Points" + res?.payload?.message); 
          cancelModel();
          dispatch(getAllParticipants(gameId));
        } else {
          if (
            res?.payload?.response?.data?.message ===
            "Exceeding the maximum limit for points"
          ) {
            setError(res?.payload?.response?.data?.message);
          }
        }
        if (
          res?.payload?.response?.data?.message ===
          "Milestone Points should not exceed by 60"
        ) {
          setError("Milestone points must be less than or equal to 60");
        }
        if (
          res?.payload?.response?.data?.message ===
          "Removed Points exceeds by points earned"
        ) {
          setError(res?.payload?.response?.data?.message);
        }
      });
    }
  };

  return (
    <div className="fixed inset-0 bg-gray-900 bg-opacity-50 flex items-center justify-center z-50">
      <div className="relative bg-[#150c3f] p-6 rounded-lg shadow-lg text-center w-full max-w-[460px]">
        <button
          className="absolute top-0 sm:right-[-20px] right-0 text-white rounded focus:outline-none text-lg"
          onClick={cancelModel}
        >
          <RxCross1 />
        </button>
        <div>
          <h1 className="text-white text-2xl mb-3">{popupTitle}</h1>
          <form onSubmit={handleSubmit}>
            <div className="mb-3">
              <label className="block text-sm text-left font-medium text-white">
                Character
              </label>
              <input
                readOnly
                className="mt-1 block w-full text-white px-3 py-2 bg-[#372F5B] rounded-md shadow-sm focus:outline-none focus:ring focus:ring-red-500 focus:ring-opacity-50 h-10"
                value={selectedData?.participantData?.characterDetails?.name}
              />
              <div className="text-left text-white text-sm mt-2">
                <span className=" flex items-center gap-2">
                  <FaCircleInfo /> Level:{" "}
                  {selectedData?.participantData?.characterDetails?.level} |
                  Milestone Points:{" "}
                  {selectedData?.participantData?.characterDetails?.points}
                </span>
              </div>
            </div>

            <div className="mb-3">
              <label className="block text-sm text-left font-medium text-white">
                Milestone Points
              </label>
              <input
                type="text"
                inputMode="numeric"
                pattern="[0-9]*"
                title="please enter only numeric value"
                onChange={(e) => {
                  const input = e.target.value;
                  setMilestonePoints(input);
                  setError("");
                }}
                className="mt-1 block w-full text-white px-3 py-2 bg-[#372F5B] rounded-md shadow-sm focus:outline-none focus:ring focus:ring-red-500 focus:ring-opacity-50 h-10"
                required
              />
              {error && <p className="text-red-600 text-left mt-3">{error}</p>}
            </div>

            <button
              type="submit"
              className="bg-red-600 py-2 px-3 text-sm text-white w-full rounded-lg mt-2"
            >
              {popUp?.mode === "Add" ? "Add" : "Remove"}
            </button>
          </form>
        </div>
      </div>

      {isLoading && (
        <div className="loader">
          <LineWave color={"#fff"} loading={isLoading} size={150} />
        </div>
      )}
    </div>
  );
};

export default GameMilestonePopup;