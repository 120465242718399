import React from "react";

const UploadSvg = () => {
  return (
    <div>
      <svg
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        className="rounded-[10px] text-white absolute left-7 top-7 z-10 w-6 h-6"
      >
        <circle cx="12" cy="12" r="11.5" fill="white" stroke="#CCCCCC"></circle>
        <g clipPath="url(#clip0_2395_3649)">
          <path
            d="M16.485 6.5H15.015C14.38 6.5 14 6.88 14 7.515V8.985C14 9.62 14.38 10 15.015 10H16.485C17.12 10 17.5 9.62 17.5 8.985V7.515C17.5 6.88 17.12 6.5 16.485 6.5ZM16.595 8.155C16.535 8.215 16.455 8.245 16.375 8.245C16.295 8.245 16.215 8.215 16.155 8.155L16.065 8.065V9.185C16.065 9.36 15.925 9.5 15.75 9.5C15.575 9.5 15.435 9.36 15.435 9.185V8.065L15.345 8.155C15.225 8.275 15.025 8.275 14.905 8.155C14.785 8.035 14.785 7.835 14.905 7.715L15.53 7.09C15.555 7.065 15.59 7.045 15.625 7.03C15.635 7.025 15.645 7.025 15.655 7.02C15.68 7.01 15.705 7.005 15.735 7.005C15.745 7.005 15.755 7.005 15.765 7.005C15.8 7.005 15.83 7.01 15.865 7.025C15.87 7.025 15.87 7.025 15.875 7.025C15.91 7.04 15.94 7.06 15.965 7.085C15.97 7.09 15.97 7.09 15.975 7.09L16.6 7.715C16.72 7.835 16.72 8.035 16.595 8.155Z"
            fill="#150C3F"
          ></path>
          <path
            d="M10.5001 11.1901C11.1573 11.1901 11.6901 10.6573 11.6901 10.0001C11.6901 9.34284 11.1573 8.81006 10.5001 8.81006C9.84284 8.81006 9.31006 9.34284 9.31006 10.0001C9.31006 10.6573 9.84284 11.1901 10.5001 11.1901Z"
            fill="#150C3F"
          ></path>
          <path
            d="M16.485 10H16.25V12.305L16.185 12.25C15.795 11.915 15.165 11.915 14.775 12.25L12.695 14.035C12.305 14.37 11.675 14.37 11.285 14.035L11.115 13.895C10.76 13.585 10.195 13.555 9.795 13.825L7.925 15.08C7.815 14.8 7.75 14.475 7.75 14.095V9.905C7.75 8.495 8.495 7.75 9.905 7.75H14V7.515C14 7.315 14.035 7.145 14.115 7H9.905C8.085 7 7 8.085 7 9.905V14.095C7 14.64 7.095 15.115 7.28 15.515C7.71 16.465 8.63 17 9.905 17H14.095C15.915 17 17 15.915 17 14.095V9.885C16.855 9.965 16.685 10 16.485 10Z"
            fill="#150C3F"
          ></path>
        </g>
        <defs>
          <clipPath id="clip0_2395_3649">
            <rect
              width="12"
              height="12"
              fill="white"
              transform="translate(6 6)"
            ></rect>
          </clipPath>
        </defs>
      </svg>
    </div>
  );
};

export default UploadSvg;
