import React from "react";

const UserSvg = ({ width, height }) => {
  return (
    <>
      <svg
        width={width}
        height={height}
        viewBox="0 0 18 18"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M13.9217 14.2432H3.7933C3.43157 14.2432 3.14219 14.0262 3.06984 13.6644L1.62293 6.42985C1.55058 6.14047 1.69527 5.85108 1.98466 5.63405C2.20169 5.48936 2.56342 5.5617 2.78046 5.70639L5.74664 8.0938L8.2064 4.40417C8.49578 3.97009 9.14689 3.97009 9.43627 4.40417L11.896 8.0938L14.8622 5.70639C15.0792 5.48936 15.441 5.48936 15.658 5.63405C15.9474 5.77874 16.0197 6.06812 16.0197 6.42985L14.5728 13.6644C14.5728 14.0262 14.2834 14.2432 13.9217 14.2432ZM4.37207 12.7963H13.3429L14.2834 8.0938L12.1854 9.75776C12.0407 9.90245 11.8237 9.90245 11.6066 9.90245C11.3896 9.90245 11.2449 9.75776 11.1002 9.61307L8.85751 6.14047L6.54244 9.61307C6.39775 9.75776 6.25306 9.90245 6.03602 9.90245C5.81898 9.90245 5.60195 9.90245 5.45726 9.75776L3.35923 8.0938L4.37207 12.7963Z"
          fill="#B8BBCC"
        ></path>
      </svg>
    </>
  );
};

export default UserSvg;
