import React, { useState } from "react";
import { RxCross1 } from "react-icons/rx";
import {
  addMilestonePoints,
  removeMilestonePoints,
} from "../../redux/slices/usersSlice";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import { FaCircleInfo } from "react-icons/fa6";


const UserPopup = ({ action, cancelModel }) => {
  
  const popupTitle =
    action?.mode === "Assign"
      ? "Add Milestone Points"
      : "Remove Milestone Points";
  const dispatch = useDispatch();
  const [characterId, setCharacterId] = useState("");
  const [milestonePoints, setMilestonePoints] = useState();
  const [error, setError] = useState("");
  const [selectedCharacter, setSelectedCharacter] = useState(null); 
  const { characters } = useSelector((state) => state.usersReducer);

  const handleCharacter = (e) => {
    const { value } = e.target;
    setCharacterId(value);
    setError("");
    const selected = characters.data.find(character => character._id === value);
    setSelectedCharacter(selected);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (action?.mode === "Assign") {
      dispatch(
        addMilestonePoints({
          characterId,
          milestonePoints: parseInt(milestonePoints),
        })
      ).then((res) => {
        if (res.payload.code === 200) {
          toast.success("Added Milestone points Successfully");
          cancelModel();
        } else {
          if (
            res?.payload?.response?.data?.message ===
            "Exceeding the maximum limit for points"
          ) {
            setError(res?.payload?.response?.data?.message);
          }
        }
        if (
          res?.payload?.response?.data?.message ===
          "Milestone Points should not exceed by 60"
        ) {
          setError("Milestone points must be less than or equal to 60");
        }
      });
    } else {
      dispatch(
        removeMilestonePoints({
          characterId,
          milestonePoints: parseInt(milestonePoints),
        })
      ).then((res) => {
        if (res?.payload?.code === 200) {
          toast.success("Milestone Points" + res?.payload?.message);
          cancelModel();
        } else {
          if (
            res?.payload?.response?.data?.message ===
            "Exceeding the maximum limit for points"
          ) {
            setError(res?.payload?.response?.data?.message);
          }
        }
        if (
          res?.payload?.response?.data?.message ===
          "Milestone Points should not exceed by 60"
        ) {
          setError("Milestone points must be less than or equal to 60");
        }
        if (
          res?.payload?.response?.data?.message ===
          "Removed Points exceeds by points earned"
        ) {
          setError(res?.payload?.response?.data?.message);
        }
      });
    }
  };

  return (
    <div className="fixed inset-0 bg-gray-900 backdrop-blur-sm  bg-opacity-50 flex items-center justify-center z-50">
      <div className="relative bg-[#150c3f] p-6 rounded-lg shadow-lg text-center w-full max-w-[460px]">
        <button
          className="absolute top-0 sm:right-[-20px] right-0 text-white rounded focus:outline-none text-lg"
          onClick={cancelModel}
        >
          <RxCross1 />
        </button>
        <div>
          <h1 className="text-white text-2xl mb-3">{popupTitle}</h1>
          <form onSubmit={handleSubmit}>
            <div className="mb-3">
              <label className="block text-sm text-left font-medium text-white">
                Character
              </label>
              <select
                className="mt-1 block w-full text-white px-3 py-2 bg-[#372F5B] rounded-md shadow-sm focus:outline-none focus:ring focus:ring-red-500 focus:ring-opacity-50 h-10"
                value={characterId}
                onChange={handleCharacter}
                required
              >
                <option value={""}></option>
                {characters?.data?.map((character) => {
                  return (
                    <option key={character?._id} value={character?._id}>
                      {character?.name}
                    </option>
                  );
                })}
              </select>
              <div className="text-left text-white text-sm mt-2">
                {selectedCharacter && (
                  <span className=" flex items-center gap-2">
                   <FaCircleInfo/> Level: {selectedCharacter.level} | Milestone Points: {selectedCharacter.points}
                  </span>
                )}
              </div>
            </div>

            <div className="mb-3">
              <label className="block text-sm text-left font-medium text-white">
                Milestone Points
              </label>
              <input
                type="text"
                inputMode="numeric"
                pattern="[0-9]*"
                title="please enter only numeric value"
                onChange={(e) => {
                  const input = e.target.value;
                  setMilestonePoints(input);
                  setError("");
                }}
                className="mt-1 block w-full text-white px-3 py-2 bg-[#372F5B] rounded-md shadow-sm focus:outline-none focus:ring focus:ring-red-500 focus:ring-opacity-50 h-10"
                required
              />
              {error && <p className="text-red-600 text-left mt-3">{error}</p>}
            </div>

            <button
              type="submit"
              className="bg-red-600 py-2 px-3 text-sm text-white w-full rounded-lg mt-2"
            >
              {action?.mode === "Assign" ? "Add" : "Remove"}
            </button>
          </form>
        </div>
      </div>
    </div>
  );
};

export default UserPopup;
