import React, { useState } from "react";
import { Link } from "react-router-dom";
import { useDispatch } from "react-redux";
import {
  deleteBannerImage,
  getAllBanners,
} from "../../redux/slices/settingsSlice";
import { toast } from "react-toastify";
import Confirmation from "../../components/confirmation/Confirmation";

const BannerImagesCard = ({ data }) => {
  const dispatch = useDispatch();
  const [confirmDelete, setConfirmDelete] = useState(false);

  const handleDelete = () => {
    setConfirmDelete(true);
  };
  const cancelDelete = () => {
    setConfirmDelete(false);
  };
  const deleteHandler = () => {
    dispatch(deleteBannerImage({ id: data?._id })).then((res) => {
      if (res.payload.code === 200) {
        toast.success("Banner Deleted Successfully");
        dispatch(getAllBanners());
      } else {
        toast.error("Something Went Wrong");
      }
    });
  };
  return (
    <>
      <div className="w-full sm:w-1/2 md:w-1/2 lg:w-1/3 xl:w-1/4 px-2 mb-4 flex flex-col h-full">
        <div className="relative bg-[#211849] rounded-lg overflow-hidden shadow-md h-full flex flex-col">
          <img
            src={data?.imageUrl}
            alt={data?.image}
            className="w-full h-60 object-cover object-center rounded-t-lg"
          />

          <div className="absolute top-0 right-0 m-2">
            <Link
              className="text-white bg-blue-500 px-2 py-1 rounded hover:bg-blue-600 focus:outline-none text-sm  inline-block"
              to={`/panel/editbanner/${data?._id}`}
            >
              Edit
            </Link>
            <button
              className="text-white bg-red-500 px-2 py-1 rounded hover:bg-red-600 focus:outline-none ml-2 text-sm"
              onClick={() => {
                handleDelete();
              }}
            >
              Delete
            </button>
          </div>

          <div className="p-4 text-white text-left min-h-[150px]">
            <h2 className="text-lg font-bold mb-2">{data?.title}</h2>
            <p className="text-sm truncate-multiline">{data?.description}</p>
          </div>
        </div>
        {confirmDelete && (
          <Confirmation
            confirmDelete={deleteHandler}
            cancelDelete={cancelDelete}
            message="delete this Banner"
           buttonText = "Delete"/>
        )}
      </div>
    </>
  );
};

export default BannerImagesCard;
