import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";

import http from "../../utils/http";

const initialState = {
  isLoading: false,
  participantsLoading:false,
  games: [],
  gameDetail: {},
  gameParticipants: [],
  onlineLocations: [],
  timezones: {},
};

export const getAllGames = createAsyncThunk(
  "application/getAllGames",
  async (payload, thunkAPI) => {
    try {
      const response = await http.post(
        `admin/game/search-filter-game?page=${payload?.pageParams}&limit=${20}`
      );
      return thunkAPI.fulfillWithValue(response.data);
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);

export const getAllGamesBySearch = createAsyncThunk(
  "application/getAllGamesBySearch",
  async (payload, thunkAPI) => {
    try {
      const response = await http.post(
        `admin/game/search-filter-game?page=${payload.pageParams}&limit=${20}`,
        payload.body
      );
      return thunkAPI.fulfillWithValue(response.data);
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);

export const deleteGames = createAsyncThunk(
  "application/deleteGames",
  async (payload, thunkAPI) => {
    try {
      const response = await http.delete(`admin/game/${payload}`);
      return thunkAPI.fulfillWithValue(response.data);
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);

export const createGame = createAsyncThunk(
  "application/createGame",
  async (payload, thunkAPI) => {
    try {
      const response = await http.post(`admin/game/create-game`, payload);
      return thunkAPI.fulfillWithValue(response.data);
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);

export const updateGame = createAsyncThunk(
  "application/updateGame",
  async (payload, thunkAPI) => {
    try {
      const response = await http.patch(
        `admin/game/${payload.gameId}`,
        payload.body
      );
      return thunkAPI.fulfillWithValue(response.data);
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);

export const gamedetails = createAsyncThunk(
  "application/gamedetails",
  async (payload, thunkAPI) => {
    try {
      const response = await http.get(`admin/game/${payload}`);
      return thunkAPI.fulfillWithValue(response.data);
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);

export const getOnlineLocationsData = createAsyncThunk(
  "application/getOnlineLocationsData",
  async (_, thunkAPI) => {
    try {
      const response = await http.get(`/admin/game/game-locations`);
      return thunkAPI.fulfillWithValue(response.data.data);
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);

export const getAllParticipants = createAsyncThunk(
  "application/getAllParticipants",
  async (payload, thunkAPI) => {
    try {
      const response = await http.get(`game/participants-list/${payload}`);
      return thunkAPI.fulfillWithValue(response.data);
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);

export const timezonesList = createAsyncThunk(
  "application/timezonesList",
  async (_, thunkAPI) => {
    try {
      const response = await http.get(`misc/timezone-list`);
      return thunkAPI.fulfillWithValue(response.data.data);
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);

const gamesSlice = createSlice({
  name: "games",
  initialState,
  extraReducers: (builder) => {
    builder
      .addCase(getAllGames.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getAllGames.fulfilled, (state, action) => {
        state.isLoading = false;
        state.games = action.payload;
      })
      .addCase(getAllGames.rejected, (state) => {
        state.isLoading = false;
        state.games = []
      })
      .addCase(getAllGamesBySearch.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getAllGamesBySearch.fulfilled, (state, action) => {
        state.isLoading = false;
        state.games = action.payload;
      })
      .addCase(getAllGamesBySearch.rejected, (state) => {
        state.isLoading = false;
        state.games = []
      })
      .addCase(deleteGames.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(deleteGames.fulfilled, (state, action) => {
        state.isLoading = false;
        state.games = action.payload;
      })
      .addCase(deleteGames.rejected, (state) => {
        state.isLoading = true;
        state.games = []
      })
      .addCase(createGame.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(createGame.fulfilled, (state, action) => {
        state.isLoading = false;
      })
      .addCase(createGame.rejected, (state) => {
        state.isLoading = false;
      })
      .addCase(updateGame.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(updateGame.fulfilled, (state, action) => {
        state.isLoading = false;
      })
      .addCase(updateGame.rejected, (state) => {
        state.isLoading = false;
      })
      .addCase(gamedetails.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(gamedetails.fulfilled, (state, action) => {
        state.isLoading = false;
        state.gameDetail = action.payload;
      })
      .addCase(gamedetails.rejected, (state) => {
        state.isLoading = false;
        state.gameDetail = []
      })
      .addCase(getAllParticipants.pending, (state) => {
        state.participantsLoading = true;
      })
      .addCase(getAllParticipants.fulfilled, (state, action) => {
        state.participantsLoading = false;
        state.gameParticipants = action.payload;
      })
      .addCase(getAllParticipants.rejected, (state) => {
        state.participantsLoading = false;
        state.gameParticipants = []
      })
      .addCase(getOnlineLocationsData.fulfilled, (state, action) => {
        state.onlineLocations = action.payload;
      })
      .addCase(timezonesList.fulfilled, (state, action) => {
        state.timezones = action.payload;
      });
  },
});

export default gamesSlice.reducer;
