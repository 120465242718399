import React, { useState, useEffect, useRef } from "react";
import UploadSvg from "../../components/svg/UploadSvg";
import { MdDelete } from "react-icons/md";
import { Link } from "react-router-dom";
import { ImCross } from "react-icons/im";
import {
  updateCharacter,
  deleteCharacter,
} from "../../redux/slices/characterSlice";
import { useDispatch, useSelector } from "react-redux";
import { getAllCharacters } from "../../redux/slices/usersSlice";
import {
  FaRegCircle,
  FaCircle,
  FaEdit,
  FaRegSmile,
  FaUpload,
} from "react-icons/fa";
import { toast } from "react-toastify";
import { LineWave } from "react-loader-spinner";
import Confirmation from "../../components/confirmation/Confirmation";
const CharacterCard = ({ data, id, active, setActive, uniqueId }) => {
  const totalCharacterPoint = 6;
  const characterPoints = data?.points;
  const [toggle, setToggle] = useState(false);
  const toggleRef = useRef(null);
  const dispatch = useDispatch();
  const [popUp,setPopup] = useState(false);
  const { isLoading } = useSelector((state) => state.characterReducer);
  const handleUpload = () => {
    setActive(id);
    setToggle((prev) => !prev);
  };
  
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (toggleRef.current && !toggleRef.current.contains(event.target)) {
        setToggle(false);
        setActive(null);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [toggleRef, setActive]);

  useEffect(() => {
    if (active !== id) {
      setToggle(false);
    }
  }, [active, id]);

  const handleImageChange = (e) => {
    const selectedImage = e.target.files[0];
    const formData = new FormData();
    formData.append("image", selectedImage);
    formData.append("userId", id);
    dispatch(
      updateCharacter({
        id: id,
        data: formData,
      })
    )
      .then((res) => {
        setToggle(false);
        setActive(null);
        if (res?.payload?.code === 200) {
          dispatch(getAllCharacters(uniqueId));
          toast.success(res?.payload?.message);
        } else {
          toast.error("Something Went Wrong");
        }
      })
      .catch((error) => {
        console.log("Error", error);
        toast.error("Something Went Wrong");
      });
  };
  const handleRemoveImage = () => {
    const formData = new FormData();
    formData.append("image", "");
    formData.append("userId", id);
    dispatch(
      updateCharacter({
        id: id,
        data: formData,
      })
    )
      .then((res) => {
        setToggle(false);
        setActive(null);
        if (res?.payload?.code === 200) {
          dispatch(getAllCharacters(uniqueId));
          toast.success(res?.payload?.message);
        } else {
          toast.error("Something Went Wrong");
        }
      })
      .catch((error) => {
        console.log("Error", error);
        toast.error("Something Went Wrong");
      });
  };
  const handleDelete = (characterId) => {
    setPopup(false)
    dispatch(
      deleteCharacter({
        id: characterId,
        membershipId: uniqueId,
      })
    )
      .then((res) => {
        if (res?.payload?.code === 200) {
          dispatch(getAllCharacters(uniqueId));
          toast.success(res?.payload?.message);
        } else {
          toast.error("Something Went Wrong");
        }
      })
      .catch((error) => {
        console.log("Error", error);
        toast.error("Something Went Wrong");
      });
  };
  const handleCancel = ()=>{
    setPopup(false);
  }
  const handlePopup = ()=>{
    setPopup(true);
  }
  return (
    <div className="p-[24px] lg:w-[50%] w-full flex">
      <div className="relative w-[50px] h-[50px] rounded-full">
        {typeof data?.image === "string" && data.image.trim() !== "" ? (
          <img
            src={data.image}
            alt="Character"
            className="w-full h-full object-cover object-center rounded-full"
          />
        ) : (
          <img
            src="/assets/images/character-image.jpeg"
            alt="Character"
            className="w-full h-full object-cover rounded-full"
          />
        )}

        <button
          className="absolute  top-[4px] right-[30px] rounded-full p-2"
          aria-label="Upload image"
          onClick={handleUpload}
        >
          <UploadSvg />
        </button>
        {toggle && (
          <div
            className=" bg-white p-3 absolute top-[112%] left-0 z-50 w-[200px] rounded-lg"
            ref={toggleRef}
          >
            <div className="flex items-center mb-2 gap-2 text-black">
              <FaRegSmile className="text-yellow-600 text-[12px]" />
              <Link
                className="text-[14px]"
                to={`/panel/avatar/${id}?key=${data?.createdBy}&mid=${uniqueId}`}
              >
                Choose From Avatars
              </Link>
            </div>
            <div className="flex items-center mb-2 gap-2 text-black">
              <FaUpload className=" text-[12px]" />
              <label className="text-[14px]">
                Upload a Picture
                <input
                  type="file"
                  className="hidden"
                  id="file"
                  accept="jpeg,jpg,png"
                  onChange={handleImageChange}
                />
              </label>
            </div>
            <div className="text-black gap-2 flex items-center">
              <ImCross className="text-red-600 text-[12px]" />
              <button className="text-[14px]" onClick={handleRemoveImage}>
                Remove a Photo
              </button>
            </div>
          </div>
        )}
      </div>
      <div className="w-full leading-[17px] pl-[20px]">
        <div className="flex items-center justify-between">
          <span className="block text-white">{data?.name}</span>
          <div className="flex items-center gap-1">
            <Link
              to={`/panel/edit/character/${data?._id}?mid=${uniqueId}`}
              className=" text-white"
            >
              <FaEdit />
            </Link>
            <button
              className=" text-red-600"
              onClick={handlePopup}
            >
              <MdDelete />
            </button>
          </div>
        </div>
        <span className="leading-[21px] block text-left mt-[10px] text-[#B8BBCC] text-sm">
          {`${data?.archeType} | ${data?.class}`}
        </span>
        <div className="text-white text-left mt-2 text-[12px] flex items-center gap-2">
          <span className="block">Level {data?.level}</span>
          <div className=" flex items-center gap-2">
            {Array.from({ length: characterPoints }).map((_, index) => {
              return <FaCircle key={index} className=" text-yellow-300" />;
            })}
            {Array.from({ length: totalCharacterPoint - characterPoints }).map(
              (_, index) => {
                return <FaRegCircle key={index} />;
              }
            )}
          </div>
        </div>
      </div>
      {
        popUp && <Confirmation confirmDelete={()=>{handleDelete(data?._id)}} cancelDelete = {handleCancel} buttonText= "Delete" message ="delete"/>
      }
      {isLoading && (
        <div className="loader">
          <LineWave color={"#fff"} loading={isLoading} size={150} />
        </div>
      )}
    </div>
  );
};

export default CharacterCard;
