import React from "react";

const TimeSvg = ({width,height}) => {
  return (
    <>
      <svg
        width={width}
        height={height}
        viewBox="0 0 16 16"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M8 14C11.3137 14 14 11.3137 14 8C14 4.68629 11.3137 2 8 2C4.68629 2 2 4.68629 2 8C2 11.3137 4.68629 14 8 14Z"
          stroke="#A4A1B2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M8 4.6665V7.99984L10 9.99984"
          stroke="#A4A1B2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
    </>
  );
};

export default TimeSvg;
