import React from "react";
import { Link, useLocation } from "react-router-dom";
import { IoGameControllerOutline } from "react-icons/io5";
import { IoHomeOutline } from "react-icons/io5";
import { MdOutlineFolderCopy } from "react-icons/md";
import { FiUsers } from "react-icons/fi";
import { IoSettingsOutline } from "react-icons/io5";

const Sidebar = () => {
  const location = useLocation();

  return (
    <div className="">
      <div
        className={`fixed top-0 left-0 h-full bg-[#211849] text-gray-300 transition-transform duration-300 p-3 w-[200px]`}
      >
        <div>
          <img src="/assets/images/smallLogo.svg" alt="" className="m-auto" />
        </div>
        <nav className={"flex gap-4 flex-col pt-5"}>
          <Link
            to="/panel/dashboard"
            className={`py-2 px-8 bg-transparent rounded-xl transition-colors duration-300 hover:bg-[rgba(29, 136, 218, 0.773)] hover:bg-[#332B58] flex items-center gap-3 ${
              location.pathname === "/panel/dashboard" ? "text-white" : "text-gray-300"
            }`}
          >
            <IoHomeOutline className={`text-lg ${location.pathname === "/panel/dashboard" ? "text-white" : "text-gray-400"}`} />
            Dashboard
          </Link>
          <Link
            to="/panel/games"
            className={`py-2 px-8 bg-transparent rounded-xl transition-colors duration-300 hover:bg-[rgba(29, 136, 218, 0.773)] hover:bg-[#332B58] flex items-center gap-3 ${
              location.pathname === "/panel/games" ? "text-white" : "text-gray-300"
            }`}
          >
            <IoGameControllerOutline className={`text-lg ${location.pathname === "/panel/games" ? "text-white" : "text-gray-400"}`} />
            Games
          </Link>
          <Link
            to="/panel/scenario"
            className={`py-2 px-8 bg-transparent rounded-xl transition-colors duration-300 hover:bg-[rgba(29, 136, 218, 0.773)] hover:bg-[#332B58] flex items-center gap-3 ${
              location.pathname === "/panel/scenario" ? "text-white" : "text-gray-300"
            }`}
          >
            <MdOutlineFolderCopy className={`text-lg ${location.pathname === "/panel/scenario" ? "text-white" : "text-gray-400"}`} />
            Scenarios
          </Link>
          <Link
            to="/panel/users"
            className={`py-2 px-8 bg-transparent rounded-xl transition-colors duration-300 hover:bg-[rgba(29, 136, 218, 0.773)] hover:bg-[#332B58] flex items-center gap-3 ${
              location.pathname === "/panel/users" ? "text-white" : "text-gray-300"
            }`}
          >
            <FiUsers className={`text-lg ${location.pathname === "/panel/users" ? "text-white" : "text-gray-400"}`} />
            Users
          </Link>
          <Link
            to="/panel/banners"
            className={`py-2 px-8 bg-transparent rounded-xl transition-colors duration-300 hover:bg-[rgba(29, 136, 218, 0.773)] hover:bg-[#332B58] flex items-center gap-3 ${
              location.pathname === "/panel/banners" ? "text-white" : "text-gray-300"
            }`}
          >
            <IoSettingsOutline className={`text-lg ${location.pathname === "/panel/banners" ? "text-white" : "text-gray-400"}`} />
            Banners
          </Link>
        </nav>
      </div>
    </div>
  );
};

export default Sidebar;
