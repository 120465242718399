import React from "react";
import ReactDOM from "react-dom/client";
import { Provider } from "react-redux";
import { LoadScript } from "@react-google-maps/api";
import store from "./redux";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
const root = ReactDOM.createRoot(document.getElementById("root"));
const libraries = ['places']
root.render(
  <React.StrictMode>
    <Provider store={store}>
      <LoadScript
        id="places"
        googleMapsApiKey="AIzaSyCRuaF-_J0MNCxM1A9DmlA_VQ9fWPeL0q8"
        libraries={libraries}
        loadingElement={
          <input
            type="text"
            placeholder=""
            className="mt-1 block w-full px-3 py-2 bg-[#372F5B] rounded-md shadow-sm focus:outline-none focus:ring focus:ring-red-500 focus:ring-opacity-50"
          />
        }
      >
        <App />
      </LoadScript>
    </Provider>
  </React.StrictMode>
);

reportWebVitals();
