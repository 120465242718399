import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";

import http from "../../utils/http";

const initialState = {
  isLoading: false,
  characters: [],
  avatars: [],
  archetype: [],
  charData: {}
};

export const createCharacter = createAsyncThunk(
  "application/createCharacter",
  async (payload, thunkAPI) => {
    try {
      const response = await http.post("/admin/characters", payload);
      return thunkAPI.fulfillWithValue(response.data);
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);

export const getCharacterById = createAsyncThunk(
  "application/getCharacterById",
  async (payload, thunkAPI) => {
    try {
      const response = await http.get(
        `/admin/characters/${payload.id}`
      );
      return thunkAPI.fulfillWithValue(response.data);
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);

export const updateCharacter = createAsyncThunk(
  "application/updateCharacter",
  async (payload, thunkAPI) => {
    try {
      const response = await http.patch(
        `/admin/characters/${payload?.id}`,
        payload.data,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );
      return thunkAPI.fulfillWithValue(response?.data);
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);

export const deleteCharacter = createAsyncThunk(
  "application/deleteCharacter",
  async (payload, thunkAPI) => {
    try {
      const response = await http.delete(
        `/admin/characters/${payload.id}?membershipId=${payload.membershipId}`
      );
      return thunkAPI.fulfillWithValue(response.data);
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);

export const getArcheType = createAsyncThunk(
  "application/getArcheType",
  async (_, thunkAPI) => {
    try {
      const response = await http.get("/archeType");
      return thunkAPI.fulfillWithValue(response.data);
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);

export const getAvatars = createAsyncThunk(
  "application/getAvatars",
  async (_, thunkAPI) => {
    try {
      const response = await http.get("/avatar");
      return thunkAPI.fulfillWithValue(response.data);
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);

export const getArcheTypeByName = createAsyncThunk(
  "application/getArcheTypeByName",
  async (payload, thunkAPI) => {
    try {
      const response = await http.get(
        `/archeType/by-archeType-name?archTypeName=${payload}`
      );
      return thunkAPI.fulfillWithValue(response.data);
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);

const characterSlice = createSlice({
  name: "characters",
  initialState,
  extraReducers: (builder) => {
    builder
      .addCase(createCharacter.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(createCharacter.fulfilled, (state) => {
        state.isLoading = false;
      })
      .addCase(createCharacter.rejected, (state) => {
        state.isLoading = false;
      })
      .addCase(getCharacterById.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getCharacterById.fulfilled, (state,action) => {
        state.isLoading = false;
        state.charData = action.payload.data
      })
      .addCase(getCharacterById.rejected, (state) => {
        state.isLoading = false;
        state.charData = []
      })
      .addCase(updateCharacter.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(updateCharacter.fulfilled, (state) => {
        state.isLoading = false;
      })
      .addCase(updateCharacter.rejected, (state) => {
        state.isLoading = false;
      })
      .addCase(deleteCharacter.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(deleteCharacter.fulfilled, (state) => {
        state.isLoading = false;
      })
      .addCase(deleteCharacter.rejected, (state) => {
        state.isLoading = false;
      })
      .addCase(getArcheType.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getArcheType.fulfilled, (state,action) => {
        state.isLoading = false;
        state.archetype = action.payload?.data
      })
      .addCase(getArcheType.rejected, (state) => {
        state.isLoading = false;
        state.archetype = []
      })
      .addCase(getArcheTypeByName.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getArcheTypeByName.fulfilled, (state,action) => {
        state.isLoading = false;
      })
      .addCase(getArcheTypeByName.rejected, (state) => {
        state.isLoading = false;
      })
      .addCase(getAvatars.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getAvatars.fulfilled, (state, action) => {
        state.isLoading = false;
        state.avatars = action.payload;
      })
      .addCase(getAvatars.rejected, (state) => {
        state.isLoading = false;
      });
  },
});
export default characterSlice.reducer;
