import React from 'react'

const CalendarSvg = () => {
  return (
    <div><svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M12 3.33337H4.00002C3.26364 3.33337 2.66669 3.93033 2.66669 4.66671V12.6667C2.66669 13.4031 3.26364 14 4.00002 14H12C12.7364 14 13.3334 13.4031 13.3334 12.6667V4.66671C13.3334 3.93033 12.7364 3.33337 12 3.33337Z" stroke="#B8BBCC" strokeLinecap="round" strokeLinejoin="round"/>
    <path d="M10.6667 2V4.66667" stroke="#B8BBCC" strokeLinecap="round" strokeLinejoin="round"/>
    <path d="M5.33331 2V4.66667" stroke="#B8BBCC" strokeLinecap="round" strokeLinejoin="round"/>
    </svg>
    </div>
  )
}

export default CalendarSvg;