import React, { useEffect, useState } from "react";
import { FaUserSlash, FaEdit, FaSnowflake , FaUserCheck} from "react-icons/fa";
import { MdDelete } from "react-icons/md";
import { useDispatch, useSelector } from "react-redux";
import { getAllUsers } from "../../redux/slices/usersSlice";
import { AiOutlineSearch } from "react-icons/ai";
import ReactPaginate from "react-paginate";
import MilestonePopup from "./MilestonePopup";
import { toast } from "react-toastify";
import GencoinsPopup from "./GencoinsPopup";
import useDebounce from "../../hooks/useDebounce";
import Confirmation from "../../components/confirmation/Confirmation";
import { useNavigate } from "react-router-dom";

import {
  getAllCharacters,
  deleteUser,
  disableUser,
  freezeUser,
  enableUser,
} from "../../redux/slices/usersSlice";
import { Link } from "react-router-dom";
import { LineWave } from "react-loader-spinner";

const Tooltip = ({ text, children, user }) => (
  <div className="relative group">
    {children}
    <div
      className={`absolute left-1/2 transform -translate-x-1/2 bottom-full mb-2 w-max p-1 bg-gray-700 text-white text-xs rounded opacity-0 group-hover:opacity-100 transition-opacity duration-300 ${
        user ? "hidden" : "block"
      }`}
    >
      {text}
    </div>
  </div>
);

const UserTable = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [currentPage, setCurrentPage] = useState(1);
  const [search, setSearch] = useState("");
  const [filterdUsers, setFilterdUsers] = useState([]);
  const { users, isLoading } = useSelector((state) => state.usersReducer);
  const [userPopup, setUserPopup] = useState(false);
  const [popupAction, setPopupAction] = useState("");
  const [genAction, setGenAction] = useState("");
  const [genPopup, setGenPopup] = useState(false);

  const [state, setState] = useState({
    confirm: false,
    btnText: "",
    messageText: "",
    popupAction: null,
  });
  const debouncedSearch = useDebounce(search, 700);
  useEffect(() => {
    const fetchData = async () => {
      try {
        const params = {
          pageParams: currentPage,
          keyword: debouncedSearch || undefined,
        };
        const res = await dispatch(getAllUsers(params));
        setFilterdUsers(res.payload.data);
      } catch (error) {
        console.error("Error fetching users:", error);
      }
    };

    fetchData();
  }, [currentPage, debouncedSearch, dispatch]);

  const handlePagination = (e) => {
    setCurrentPage(e.selected + 1);
  };

  const handleSearchChange = (e) => {
    setSearch(e.target.value);
    setCurrentPage(1);
  };

  const handlePopup = (action) => {
    dispatch(getAllCharacters(action.user.membershipId)).then((res) => {
      if (res?.payload?.data?.length) {
        setUserPopup(true);
        setPopupAction(action);
      } else {
        if (
          res?.payload?.response?.data?.message ===
          "No Character found for the provided ID"
        ) {
          toast.error("No Character found for the provided ID");
        }
      }
    });
  };

  const cancelModel = () => {
    setUserPopup(false);
    setPopupAction("");
  };

  const cancelGenModel = () => {
    setGenPopup(false);
  };

  const handleGenModel = (genAction) => {
    setGenPopup(true);
    setGenAction(genAction);
  };

  const handleDel = (data) => {
    setState({
      ...state,
      confirm: true,
      btnText: "Delete",
      messageText: "delete this User",
      popupAction: data,
    });
  };
  const handleEnable = (data) => {
    setState({
      ...state,
      confirm: true,
      btnText: "Enable",
      messageText: "enable this User",
      popupAction: data,
    });
  };

  const handleBan = (data) => {
    setState({
      ...state,
      confirm: true,
      btnText: "Ban",
      messageText: "ban this User",
      popupAction: data,
    });
  };

  const handleFreeze = (data) => {
    setState({
      ...state,
      confirm: true,
      btnText: "Freeze",
      messageText: "freeze this User",
      popupAction: data,
    });
  };

  const confirmAction = async () => {
    try {
        const { btnText, popupAction } = state;
        let action;
        switch (btnText) {
            case "Delete":
                action = deleteUser({
                    email: popupAction.email,
                    userId: popupAction.userId,
                });
                toast.success("User deleted successfully");
                break;
            case "Ban":
                action = disableUser({
                    email: popupAction.email,
                    userId: popupAction.userId,
                });
                toast.success("User banned successfully");
                break;
            case "Freeze":
                action = freezeUser({
                    email: popupAction.email,
                    userId: popupAction.userId,
                });
                toast.success("User frozen successfully");
                break;
            case "Enable":
                action = enableUser({
                    email: popupAction.email,
                    userId: popupAction.userId,
                });
                toast.success("User enabled successfully");
                break;
            default:
                return;
        }
        await dispatch(action);

        setState({ ...state, confirm: false });

        const params = {
            pageParams: currentPage,
            keyword: debouncedSearch || undefined,
        };

        const res = await dispatch(getAllUsers(params));

        if (res.payload) {
            setFilterdUsers(res.payload.data);
        } else {
            throw new Error('Failed to fetch user data');
        }
    } catch (error) {
        toast.error(`Something went wrong: ${error.message}`);
    }
};

  return (
    <>
      {isLoading ? (
        <div className="loader">
          <LineWave color={"#fff"} loading={isLoading} size={150} />
        </div>
      ) : (
        <div>
          <div className="pb-7 flex items-center justify-between">
            <div className="ml-2">
              <Link
                className="bg-red-500 text-white font-bold py-2 px-4 rounded inline-block"
                to="/panel/createuser"
              >
                Create User
              </Link>
            </div>

            <div className="relative flex items-center gap-3">
              <span className="absolute left-3 top-[50%] -translate-y-1/2">
                <AiOutlineSearch className="text-gray-400" />
              </span>
              <input
                type="text"
                placeholder="Search by Name/Email/Id"
                onChange={handleSearchChange}
                value={search}
                className="bg-[#372F5B] h-10 px-5 pr-10 pl-[35px] text-white rounded-full text-sm focus:outline-none w-full"
              />
            </div>
          </div>
          <div className="relative overflow-x-auto shadow-md sm:rounded-lg">
            <table className="w-full text-sm text-left rtl:text-right text-white dark:text-gray-400">
              <thead className="text-xs text-gray-700 uppercase bg-gray-300 dark:bg-gray-700 dark:text-gray-400">
                <tr>
                  <th scope="col" className="px-6 py-3">
                    Name
                  </th>
                  <th scope="col" className="px-6 py-3">
                    Email
                  </th>
                  <th scope="col" className="px-6 py-3">
                    Membership Id
                  </th>
                  <th scope="col" className="px-6 py-3">
                    Actions
                  </th>
                  <th scope="col" className="px-6 py-3">
                    Milestone Points
                  </th>
                  <th scope="col" className="px-6 py-3">
                    Gen Coins
                  </th>
                  <th scope="col" className="px-6 py-3">
                    Characters
                  </th>
                </tr>
              </thead>
              <tbody>
                {!isLoading && filterdUsers.length === 0 ? (
                  <tr>
                    <td
                      colSpan="6"
                      className="px-6 py-4 text-center text-2xl text-gray-500"
                    >
                      No Users Found
                    </td>
                  </tr>
                ) : (
                  filterdUsers?.map((user) => (
                    <tr
                      className="bg-[#211849] border-b dark:bg-[#211849] dark:border-gray-700"
                      key={user?._id}
                    >
                      <th
                        scope="row"
                        className="px-6 py-4 font-medium text-white whitespace-normal break-words max-w-[400px] dark:text-white"
                      >
                        {user?.name}
                      </th>
                      <td className="px-6 py-4 max-w-[400px] whitespace-normal break-words">
                        {user?.email}
                      </td>
                      <td className="px-6 py-4">{user?.membershipId}</td>
                      <td className="px-6 py-4">
                        <div className="flex items-center space-x-2">
                          <Tooltip text="Edit" user={user?.isBanned}>
                            <button
                              className={` ${
                                user?.isBanned
                                  ? "bg-gray-600"
                                  : "bg-green-600 hover:bg-green-700"
                              } text-white py-1 px-2 text-[12px] rounded-lg  transition-colors duration-300 cursor-pointer flex items-center`}
                              onClick={() =>
                                navigate(`/panel/edituser/${user?.userId}`)
                              }
                              disabled={user?.isBanned}
                            >
                              <FaEdit />
                            </button>
                          </Tooltip>
                          {user.isBanned ? 
                          <Tooltip text="Enable">
                          <button
                            className="bg-green-900 text-white py-1 px-2 text-[12px] rounded-lg hover:bg-green-900 transition-colors duration-300 cursor-pointer"
                            onClick={() => {
                              handleEnable({
                                email: user?.email,
                                userId: user?.userId,
                              });
                            }}
                          >
                            <FaUserCheck />
                          </button>
                        </Tooltip> 
                        : <Tooltip text="Freeze" user={user?.isBanned}>
                        <button
                          className={` ${
                            user?.isBanned
                              ? "bg-gray-600"
                              : " bg-sky-400 hover:bg-sky-700"
                          } text-white py-1 px-2 text-[12px] rounded-lg  transition-colors duration-300 cursor-pointer flex items-center`}
                          onClick={() => {
                            handleFreeze({
                              email: user?.email,
                              userId: user?.userId,
                            });
                          }}
                          disabled={user?.isBanned}
                        >
                          <FaSnowflake />
                        </button>
                      </Tooltip>
                      }
                          <Tooltip
                            text="Ban"
                            user={user?.isBanned && user?.isDeleted}
                          >
                            <button
                              className={` ${
                                user?.isBanned && user?.isDeleted
                                  ? "bg-gray-600"
                                  : "bg-yellow-600 hover:bg-yellow-700"
                              } text-white py-1 px-2 text-[12px] rounded-lg  transition-colors duration-300 cursor-pointer flex items-center`}
                              onClick={() => {
                                handleBan({
                                  email: user?.email,
                                  userId: user?.userId,
                                });
                              }}
                              disabled={user?.isBanned && user?.isDeleted}
                            >
                              <FaUserSlash />
                            </button>
                          </Tooltip>

                          <Tooltip text="Delete">
                            <button
                              className="bg-red-600 text-white py-1 px-2 text-[12px] rounded-lg hover:bg-red-700 transition-colors duration-300 cursor-pointer"
                              onClick={() => {
                                handleDel({
                                  email: user?.email,
                                  userId: user?.userId,
                                });
                              }}
                            >
                              <MdDelete />
                            </button>
                          </Tooltip>
                        </div>
                      </td>
                      <td className="px-6 py-4">
                        <button
                          className={` ${
                            user?.isBanned ? "bg-gray-600" : "bg-green-600"
                          } text-white py-1 px-2 text-[12px] rounded-lg mr-2`}
                          onClick={() => handlePopup({ mode: "Assign", user })}
                          disabled={user?.isBanned}
                        >
                          Add
                        </button>
                        <button
                          className={` ${
                            user?.isBanned ? "bg-gray-600" : "bg-red-600"
                          } text-white py-1 px-2 text-[12px] rounded-lg mr-2`}
                          onClick={() => handlePopup({ mode: "Remove", user })}
                          disabled={user?.isBanned}
                        >
                          Remove
                        </button>
                      </td>
                      <td className="px-6 py-4">
                        <button
                          className={` ${
                            user?.isBanned ? "bg-gray-600" : "bg-green-600"
                          } text-white py-1 px-2 text-[12px] rounded-lg mr-2`}
                          onClick={() =>
                            handleGenModel({ mode: "Assign", user })
                          }
                          disabled={user?.isBanned}
                        >
                          Add
                        </button>
                        <button
                          className={` ${
                            user?.isBanned ? "bg-gray-600" : "bg-red-600"
                          } text-white py-1 px-2 text-[12px] rounded-lg mr-2`}
                          onClick={() =>
                            handleGenModel({ mode: "Remove", user })
                          }
                          disabled={user?.isBanned}
                        >
                          Remove
                        </button>
                      </td>
                      <td className="px-6 py-4">
                        <button
                          onClick={() => {
                            navigate(`/panel/characters/${user?.membershipId}`);
                          }}
                          disabled={user?.isBanned}
                          className={` ${
                            user?.isBanned ? "bg-gray-600" : "bg-green-600"
                          } text-[12px] py-1 px-2 rounded-lg w-full max-w-[60px] m-auto`}
                        >
                          Manage
                        </button>
                      </td>
                    </tr>
                  ))
                )}
              </tbody>
            </table>
          </div>
          {filterdUsers.length !== 0 && (
            <div className="flex justify-end py-8">
              <ReactPaginate
                previousLabel="Previous"
                nextLabel="Next"
                onPageChange={handlePagination}
                breakLabel="..."
                breakClassName="mx-2 text-white"
                pageCount={users?.meta?.total_pages}
                marginPagesDisplayed={2}
                pageRangeDisplayed={5}
                containerClassName="flex justify-center"
                previousClassName="px-4 py-2 bg-red-600 text-white rounded-l cursor-pointer"
                nextClassName="px-4 py-2 bg-red-600 text-white rounded-r cursor-pointer"
                activeClassName="px-4 py-2 bg-red-500 text-white"
                pageClassName="px-4 py-2 bg-[#372F5B] text-white"
                forcePage={currentPage - 1}
              />
            </div>
          )}

          {state.confirm && (
            <Confirmation
              confirmDelete={confirmAction}
              cancelDelete={() =>
                setState((prevState) => ({ ...prevState, confirm: false }))
              }
              buttonText={state.btnText}
              message={state.messageText}
            />
          )}

          {userPopup && (
            <MilestonePopup cancelModel={cancelModel} action={popupAction} />
          )}
          {genPopup && (
            <GencoinsPopup
              cancelGenModel={cancelGenModel}
              action={genAction}
              setFilterdUsers={setFilterdUsers}
              currentPage={currentPage}
            />
          )}
        </div>
      )}
    </>
  );
};

export default UserTable;
