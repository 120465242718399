import React from "react";

const OnlineSvg = ({width,height,stroke}) => {
  return (
    <div>
      <svg
        width={width}
        height={height}
        viewBox="0 0 14 14"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path d="M1.3811 4.80957H12.6192" stroke={stroke}></path>
        <path d="M1.3811 9H12.6192" stroke={stroke}></path>
        <circle cx="7" cy="7" r="6" stroke={stroke}></circle>
        <ellipse
          cx="6.99974"
          cy="7"
          rx="2.95238"
          ry="6"
          stroke={stroke}
        ></ellipse>
      </svg>
    </div>
  );
};

export default OnlineSvg;
