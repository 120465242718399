import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import http from "../../utils/http";
import axios from "axios";

const initialState = {
  isLoading: false,
  users: [],
  characters: [],
};
const authBaseUrl = `${process.env.REACT_APP_API_AUTH_URL}`;
export const getAllUsers = createAsyncThunk(
  "application/getAllUsers",
  async (payload, thunkAPI) => {
    try {
      const response = await http.get(
        `admin/users?limit=${50}&page=${payload.pageParams}&keyword=${
          payload.keyword ? payload.keyword : ""
        }`
      );
      return thunkAPI.fulfillWithValue(response.data);
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);
export const getAllCharacters = createAsyncThunk(
  "application/getAllCharacters",
  async (payload, thunkAPI) => {
    try {
      const response = await http.get(`admin/characters/fetch-all/${payload}`);
      return thunkAPI.fulfillWithValue(response.data);
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);

export const addMilestonePoints = createAsyncThunk(
  "application/addMilestonePoints",
  async (payload, thunkAPI) => {
    try {
      const response = await http.post(
        "admin/game/add-milestone-points",
        payload
      );
      return thunkAPI.fulfillWithValue(response.data);
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);

export const removeMilestonePoints = createAsyncThunk(
  "application/removeMilestonePoints",
  async (payload, thunkAPI) => {
    try {
      const response = await http.post(
        "admin/game/remove-milestone-points",
        payload
      );
      return thunkAPI.fulfillWithValue(response.data);
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);

export const addGenCoins = createAsyncThunk(
  "application/addGenCoins",
  async (payload, thunkAPI) => {
    try {
      const response = await http.post("admin/game/add-gen-coins", payload);
      return thunkAPI.fulfillWithValue(response.data);
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);

export const removeGenCoins = createAsyncThunk(
  "application/removeGenCoins",
  async (payload, thunkAPI) => {
    try {
      const response = await http.post("admin/game/removed-gen-coins", payload);
      return thunkAPI.fulfillWithValue(response.data);
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);

export const getUserById = createAsyncThunk(
  "application/getUserById",
  async (payload, thunkAPI) => {
    try {
      const response = await http.get(`admin/users/${payload}`);
      return thunkAPI.fulfillWithValue(response.data);
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);

export const AddUser = createAsyncThunk(
  "application/addUser",
  async (payload, thunkAPI) => {
    try {
      const response = await axios.post(`${authBaseUrl}auth/register`, payload);
      return thunkAPI.fulfillWithValue(response.data);
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);
export const updateUser = createAsyncThunk(
  "application/updateUser",
  async (payload, thunkAPI) => {
    try {
      const response = await axios.post(
        `${authBaseUrl}admin/update-profile`,
        payload,
      );
      return thunkAPI.fulfillWithValue(response.data);
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);
export const updateUserDb = createAsyncThunk(
  "application/updateUserDb",
  async (payload, thunkAPI) => {
    try {
      const response = await http.post("admin/update-user", payload);
      return thunkAPI.fulfillWithValue(response.data);
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);
export const deleteUser = createAsyncThunk(
  "application/deleteUser",
  async (payload, thunkAPI) => {
    try {
      let response = await axios.post(`${authBaseUrl}admin/delete-user`, {email: payload?.email});
      if(response?.status === 200 || response?.status === 400){
        await thunkAPI.dispatch(deleteUserDb({ userId: payload?.userId }));
      }
      return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);

export const disableUser = createAsyncThunk(
  "application/disableUser",
  async (payload, thunkAPI) => {
    try {
      let response = await axios.post(`${authBaseUrl}admin/disable-user`, {email: payload?.email});
      if(response?.status === 200 || response?.status === 400){
        await thunkAPI.dispatch(banUserDb({ userId: payload?.userId }));
     }
      return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);

export const freezeUser = createAsyncThunk(
  "application/freezeUser",
  async (payload, thunkAPI) => {
    try {
      let response = await axios.post(`${authBaseUrl}admin/disable-user`, {email: payload?.email});
      if(response?.status === 200 || response?.status === 400){
        await thunkAPI.dispatch(freezeUserDb({ userId: payload?.userId }));
     }
      return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);
export const enableUser = createAsyncThunk(
  "application/enableUser",
  async (payload, thunkAPI) => {
    try {
      let response = await axios.post(`${authBaseUrl}admin/enable-user`, {email: payload?.email});
      if(response?.status === 200 || response?.status === 400){
        await thunkAPI.dispatch(enableUserDb({ userId: payload?.userId }));
     }
      return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);

export const deleteUserDb = createAsyncThunk(
  "application/deleteUserDb",
  async (payload, thunkAPI) => {
    try {
      const response = await http.delete(`admin/delete-account/${payload.userId}`);
      return thunkAPI.fulfillWithValue(response.data);
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);

export const banUserDb = createAsyncThunk(
  "application/banUserDb",
  async (payload, thunkAPI) => {
    try {
      const response = await http.patch(`admin/ban-account/${payload.userId}`);
      return thunkAPI.fulfillWithValue(response.data);
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);

export const freezeUserDb = createAsyncThunk(
  "application/freezeUserDb",
  async (payload, thunkAPI) => {
    try {
      const response = await http.patch(`admin/frozen-account/${payload.userId}`);
      return thunkAPI.fulfillWithValue(response.data);
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);
export const enableUserDb = createAsyncThunk(
  "application/enableUserDb",
  async (payload, thunkAPI) => {
    try {
      const response = await http.patch(`admin/enable-frozen-account/${payload.userId}`);
      return thunkAPI.fulfillWithValue(response.data);
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);


const userSlice = createSlice({
  name: "users",
  initialState,
  extraReducers: (builder) => {
    builder
      .addCase(getAllUsers.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getAllUsers.fulfilled, (state, action) => {
        state.isLoading = false;
        state.users = action.payload;
      })
      .addCase(getAllUsers.rejected, (state) => {
        state.isLoading = false;
        state.users = []
      })
      .addCase(getAllCharacters.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getAllCharacters.fulfilled, (state, action) => {
        state.isLoading = false;
        state.characters = action.payload;
      })
      .addCase(getAllCharacters.rejected, (state) => {
        state.isLoading = false;
        state.characters = []
      })
      .addCase(addMilestonePoints.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(addMilestonePoints.fulfilled, (state) => {
        state.isLoading = false;
      })
      .addCase(addMilestonePoints.rejected, (state) => {
        state.isLoading = false;
      })
      .addCase(removeMilestonePoints.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(removeMilestonePoints.fulfilled, (state) => {
        state.isLoading = false;
      })
      .addCase(removeMilestonePoints.rejected, (state) => {
        state.isLoading = false;
      })
      .addCase(addGenCoins.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(addGenCoins.fulfilled, (state) => {
        state.isLoading = false;
      })
      .addCase(addGenCoins.rejected, (state) => {
        state.isLoading = false;
      })
      .addCase(removeGenCoins.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(removeGenCoins.fulfilled, (state) => {
        state.isLoading = false;
      })
      .addCase(removeGenCoins.rejected, (state) => {
        state.isLoading = false;
      })
      .addCase(AddUser.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(AddUser.fulfilled, (state) => {
        state.isLoading = false;
      })
      .addCase(AddUser.rejected, (state) => {
        state.isLoading = false;
      })
      .addCase(updateUser.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(updateUser.fulfilled, (state) => {
        state.isLoading = false;
      })
      .addCase(updateUser.rejected, (state) => {
        state.isLoading = false;
      })
      .addCase(updateUserDb.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(updateUserDb.fulfilled, (state) => {
        state.isLoading = false;
      })
      .addCase(updateUserDb.rejected, (state) => {
        state.isLoading = false;
      })
      .addCase(deleteUser.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(deleteUser.fulfilled, (state) => {
        state.isLoading = false;
      })
      .addCase(deleteUser.rejected, (state) => {
        state.isLoading = false;
      })
      .addCase(deleteUserDb.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(deleteUserDb.fulfilled, (state) => {
        state.isLoading = false;
      })
      .addCase(deleteUserDb.rejected, (state) => {
        state.isLoading = false;
      })
      .addCase(disableUser.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(disableUser.fulfilled, (state) => {
        state.isLoading = false;
      })
      .addCase(disableUser.rejected, (state) => {
        state.isLoading = false;
      })
      .addCase(banUserDb.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(banUserDb.fulfilled, (state) => {
        state.isLoading = false;
      })
      .addCase(banUserDb.rejected, (state) => {
        state.isLoading = false;
      })
      .addCase(freezeUser.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(freezeUser.fulfilled, (state) => {
        state.isLoading = false;
      })
      .addCase(freezeUser.rejected, (state) => {
        state.isLoading = false;
      })
      .addCase(freezeUserDb.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(freezeUserDb.fulfilled, (state) => {
        state.isLoading = false;
      })
      .addCase(freezeUserDb.rejected, (state) => {
        state.isLoading = false;
      })
      .addCase(enableUser.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(enableUser.fulfilled, (state) => {
        state.isLoading = false;
      })
      .addCase(enableUser.rejected, (state) => {
        state.isLoading = false;
      })
      .addCase(enableUserDb.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(enableUserDb.fulfilled, (state) => {
        state.isLoading = false;
      })
      .addCase(enableUserDb.rejected, (state) => {
        state.isLoading = false;
      });
  },
});

export default userSlice.reducer;
