import React, { useEffect, useState } from "react";
import backgroundPattern from "../../images/backgroundPattern.svg";
import { useNavigate, useParams, useLocation } from "react-router-dom";
import { Formik, Form, Field, ErrorMessage } from "formik";
import {
  getArcheType,
  createCharacter,
  getCharacterById,
  updateCharacter,
} from "../../redux/slices/characterSlice";
import { useSelector, useDispatch } from "react-redux";
import { toast } from "react-toastify";
import { LineWave } from "react-loader-spinner";
import * as Yup from "yup";

const RegisterCharacter = () => {
  let { id } = useParams();
  const location = useLocation();
  const [archTypeClasses, setArchTypeClasses] = useState([]);
  const queryParams = new URLSearchParams(location.search);
  const membershipId = queryParams.get("mid");

  const [initialValues, setInitialValues] = useState({
    name: "",
    archeType: "",
    class: "",
    level: 1,
    membershipId: membershipId,
    image: ""
  });
  const { archetype, isLoading, charData } = useSelector(
    (state) => state.characterReducer
  );
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const validationSchema = Yup.object({
    name: Yup.string().required("Character Name is Required"),
    archeType: Yup.string().required("Arche Type is Required"),
    class: Yup.string().required("Class is Required"),
  });

  useEffect(() => {
    dispatch(getArcheType());
  }, [dispatch]);

  useEffect(() => {
    if (id) {
      dispatch(getCharacterById({ id: id }));
    } else {
      setInitialValues({
        name: "",
        archeType: "",
        class: "",
        level: 1,
        membershipId: membershipId,
        image: ""
      });
    }
  }, [id, dispatch, membershipId]);

  useEffect(() => {
    if (id && charData) {
      let initialValues = {
        name: charData?.name || "",
        archeType: charData?.archeType || "",
        class: charData?.class || "",
        level: charData?.level || 1,
        membershipId: membershipId,
        image: charData.image ?? ""
      };
      setInitialValues(initialValues);

      const selectedArchTypeObj = archetype.find(
        (archType) => archType.archTypeName === initialValues.archeType
      );
      if (selectedArchTypeObj) {
        const classNameArry =
          selectedArchTypeObj.archeTypeClasses[0]?.class?.split(",");
        setArchTypeClasses(classNameArry || []);
      } else {
        setArchTypeClasses([]);
      }
    }
  }, [charData, archetype, id, membershipId]);

  const handleArchTypeChange = (event, setFieldValue) => {
    const selectedOption = event.target.value;
    setFieldValue("archeType", selectedOption);
    setFieldValue("class", "");

    const selectedArchTypeObj = archetype.find(
      (archType) => archType.archTypeName === selectedOption
    );
    if (selectedArchTypeObj) {
      const classNameArry =
        selectedArchTypeObj.archeTypeClasses[0]?.class?.split(",");
      setArchTypeClasses(classNameArry || []);
    } else {
      setArchTypeClasses([]);
    }
  };

  const handleSubmit = (values, { resetForm }) => {
    values = { ...values};
    const formData = new FormData();
    Object.keys(values).forEach((key) => {
      formData.append(key, values[key]);
    });
    if (id) {
      dispatch(
        updateCharacter({
          id: charData?._id,
          data: formData,
        })
      )
        .then((res) => {
          if (res?.payload?.code === 200) {
            navigate(`/panel/characters/${membershipId}`);
            toast.success(res?.payload?.message);
            resetForm();
          } else {
            toast.error("Something Went Wrong");
          }
        })
        .catch((error) => {
          console.log("Error", error);
          toast.error("Something Went Wrong");
        });
    } else {
      dispatch(createCharacter(formData))
        .then((res) => {
          if (res?.payload?.code === 200) {
            navigate(`/panel/characters/${membershipId}`);
            toast.success(res?.payload?.message);
            resetForm();
          } else {
            toast.error("Something Went Wrong");
          }
        })
        .catch((error) => {
          console.log("Error", error);
          toast.error("Something Went Wrong");
        });
    }
  };

  return (
    <div>
      <div
        className="bg-no-repeat bg-cover py-10 px-14 rounded-xl text-left"
        style={{ backgroundImage: `url(${backgroundPattern})` }}
      >
        <h1 className="text-white text-[30px]">
          {id ? "Edit" : "Register"} Character
        </h1>
      </div>
      <div className="bg-[#211849] p-[30px] shadow-sm rounded-[10px]">
        <Formik
          initialValues={initialValues}
          onSubmit={handleSubmit}
          enableReinitialize={true}
          validationSchema={validationSchema}
        >
          {({ setFieldValue, values }) => (
            <Form>
              <Field
                type="text"
                name="membershipId"
                className="hidden mt-1 w-full pr-3 pl-[40px] py-3 bg-[#372F5B] text-white rounded-md shadow-sm focus:outline-none focus:ring focus:ring-red-500 focus:ring-opacity-50"
              />
              <div className="mb-4 text-left">
                <label className="text-left text-white text-[14px]">
                  Character Name
                </label>
                <div className="relative">
                  <img
                    src="/assets/images/chname.svg"
                    alt=""
                    className="absolute inset-y-0 top-[50%] -translate-y-1/2 left-0 flex items-center pl-3 pointer-events-none"
                  />
                  <Field
                    type="text"
                    id="name"
                    name="name"
                    className="mt-1 block w-full pr-3 pl-[40px] py-3 bg-[#372F5B] text-white rounded-md shadow-sm focus:outline-none focus:ring focus:ring-red-500 focus:ring-opacity-50"
                  />
                </div>
                <ErrorMessage
                    name="name"
                    component="div"
                    className="text-red-600 text-sm mt-1"
                  />
              </div>
              <div className="mb-4 text-left">
                <label className="text-left text-white text-[14px]">
                  Arche Type
                </label>
                <div className="relative">
                  <img
                    src="/assets/images/archetype.svg"
                    alt=""
                    className="absolute inset-y-0 top-[50%] -translate-y-1/2 left-0 flex items-center pl-3 pointer-events-none"
                  />
                  <Field
                    as="select"
                    className="mt-1 block w-full pr-3 pl-[40px] py-3 bg-[#372F5B] text-white rounded-md shadow-sm focus:outline-none focus:ring focus:ring-red-500 focus:ring-opacity-50"
                    name="archeType"
                    value={values.archeType}
                    onChange={(e) => handleArchTypeChange(e, setFieldValue)}
                  >
                    <option value="" disabled></option>
                    {archetype?.map((archType) => (
                      <option key={archType._id} value={archType.archTypeName}>
                        {archType.archTypeName}
                      </option>
                    ))}
                  </Field>
                </div>
                  <ErrorMessage
                    name="archeType"
                    component="div"
                    className="text-red-600 text-sm mt-1"
                  />
              </div>
              <div className="mb-4 text-left">
                <label className="text-left text-white text-[14px]">
                  Class
                </label>
                <div className="relative">
                  <img
                    src="/assets/images/character-class.svg"
                    alt=""
                    className="absolute inset-y-0 top-[50%] -translate-y-1/2 left-0 flex items-center pl-3 pointer-events-none"
                  />
                  <Field
                    as="select"
                    className="mt-1 block w-full pr-3 pl-[40px] py-3 bg-[#372F5B] text-white rounded-md shadow-sm focus:outline-none focus:ring focus:ring-red-500 focus:ring-opacity-50"
                    name="class"
                    value={values.class}
                  >
                    <option value="" disabled></option>
                    {archTypeClasses?.map((arclass, index) => (
                      <option key={index} value={arclass}>
                        {arclass}
                      </option>
                    ))}
                  </Field>
                </div>
                  <ErrorMessage
                    name="class"
                    component="div"
                    className="text-red-600 text-sm mt-1"
                  />
              </div>
              <div className="mb-4 text-left">
                <label className="text-left text-white text-[14px]">
                  Level
                </label>
                <div className="relative">
                  <img
                    src="/assets/images/event-name.svg"
                    alt=""
                    className="absolute inset-y-0 top-[50%] -translate-y-1/2 left-0 flex items-center pl-3 pointer-events-none"
                  />
                  <Field
                    type="number"
                    id="level"
                    name="level"
                    className="mt-1 block w-full pr-3 pl-[40px] py-3 bg-[#372F5B] text-white rounded-md shadow-sm focus:outline-none focus:ring focus:ring-red-500 focus:ring-opacity-50"
                    disabled
                  />
                </div>
              </div>
              <button
                type="submit"
                className="bg-red-600 text-[16px] font-bold w-full py-2 text-white rounded-[10px]"
              >
                {id ? "Update" : "Register"} Character
              </button>
            </Form>
          )}
        </Formik>
      </div>
      {isLoading && (
        <div className="loader">
          <LineWave color={"#fff"} loading={isLoading} size={150} />
        </div>
      )}
    </div>
  );
};

export default RegisterCharacter;
