import React, { useState, useRef, useEffect } from "react";
import { useDispatch } from "react-redux";
import { RiCloseLine } from "react-icons/ri";
import ReactCrop from "react-image-crop";
import "react-image-crop/dist/ReactCrop.css";
import {
  addBannerImage,
  getAllBanners,
  updateBannerImage,
} from "../../redux/slices/settingsSlice";
import { toast } from "react-toastify";

const AddbannerPopup = ({
  setOpenBanner,
  selectedBanner,
  setSelectedBanner,
}) => {
  const dispatch = useDispatch();
  const [formData, setFormData] = useState(
    selectedBanner?.title
      ? {
          title: selectedBanner?.title,
          description: selectedBanner?.description,
          image: selectedBanner?.imageUrl,
          imageName: selectedBanner?.image,
        }
      : {
          title: "",
          description: "",
          image: "",
          imageName: "",
        }
  );
  const [imageFile, setImageFile] = useState(null);
  const [crop, setCrop] = useState({
    unit: "%",
    width: 30,
    aspect: 1,
    x: 0,
    y: 0,
  });
  const [croppedImageFile, setCroppedImageFile] = useState(null);
  const imageRef = useRef(null);

  useEffect(() => {
    if (croppedImageFile) {
      setFormData((prevState) => ({
        ...prevState,
        image: croppedImageFile,
      }));
    }
  }, [croppedImageFile]);

  const handleImageChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      if (!file.type.startsWith("image/")) {
        toast.error("The selected file is not an image.");
        return;
      }

      const reader = new FileReader();
      reader.onloadend = () => {
        setImageFile(reader.result);
        setFormData((prevState) => ({
          ...prevState,
          imageName: file.name,
        }));
      };
      reader.readAsDataURL(file);
    }
  };

  const handleCropComplete = (crop) => {
    if (imageRef.current && crop.width && crop.height) {
      const image = imageRef.current;
      const canvas = document.createElement("canvas");
      const scaleX = image.naturalWidth / image.width;
      const scaleY = image.naturalHeight / image.height;
      canvas.width = crop.width;
      canvas.height = crop.height;
      const ctx = canvas.getContext("2d");
      ctx.drawImage(
        image,
        crop.x * scaleX,
        crop.y * scaleY,
        crop.width * scaleX,
        crop.height * scaleY,
        0,
        0,
        crop.width,
        crop.height
      );
      canvas.toBlob((blob) => {
        if (blob) {
          const file = new File(
            [blob],
            formData.imageName || "cropped-image.jpg",
            {
              type: "image/jpeg",
            }
          );
          setCroppedImageFile(file);
        }
      }, "image/jpeg");
    }
  };
  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };
  const handleSubmit = (e) => {
    e.preventDefault();
    handleCropComplete(crop);

    if (!formData?.image) {
      return;
    }

    if (selectedBanner?._id) {
      dispatch(
        updateBannerImage({
          id: selectedBanner?._id,
          body: {
            image: formData?.image,
            title: formData?.title,
            description: formData?.description,
          },
        })
      ).then((res) => {
        if (res.payload.code === 200) {
          toast("Banner Updated Successfully");
          setSelectedBanner({});
          setOpenBanner(false);
          dispatch(getAllBanners());
        } else {
          toast.error("Something Went Wrong");
        }
      });
    } else {
      dispatch(addBannerImage(formData)).then((res) => {
        if (res?.payload.code === 200) {
          toast.success("Banner Added Successfully");
          setOpenBanner(false);
          dispatch(getAllBanners());
        } else {
          toast.error("Something Went Wrong");
        }
      });
    }
  };

  const handleRemove = () => {
    setOpenBanner(false);
  };

  return (
    <div className="fixed inset-0 bg-gray-900 backdrop-blur-sm bg-opacity-50 flex items-center justify-center z-50 h-[500px] overflow-auto">
      <div className="relative bg-[#150c3f] p-6 rounded-lg shadow-lg text-center w-full max-w-[70%]">
        <button
          className="absolute top-0 sm:right-[-20px] right-0 text-white rounded focus:outline-none text-lg"
          onClick={handleRemove}
        >
          <RiCloseLine />
        </button>
        <div>
          <form onSubmit={handleSubmit}>
            <div className="mb-4">
              <label className="block text-sm font-medium text-white text-left">
                Title
              </label>
              <input
                type="text"
                name="title"
                maxLength={60}
                required
                className="mt-1 block w-full text-white px-3 py-2 bg-[#372F5B] rounded-md shadow-sm focus:outline-none focus:ring focus:ring-red-500 focus:ring-opacity-50"
                value={formData?.title}
                onChange={handleChange}
              />
            </div>
            <div className="mb-4">
              <label className="block text-sm font-medium text-white text-left">
                Upload Image
              </label>
              <div className="relative">
                <input
                  type="text"
                  id="imageText"
                  name="imageText"
                  value={formData?.imageName}
                  readOnly
                  className="mt-1 block w-full px-3 py-2 bg-[#372F5B] rounded-md shadow-sm focus:outline-none focus:ring focus:ring-red-500 focus:ring-opacity-50 text-white"
                />
                <input
                  type="file"
                  id="image"
                  name="image"
                  accept="image/*"
                  className="hidden"
                  onChange={handleImageChange}
                />
                <label
                  htmlFor="image"
                  className="bg-[gray] text-white font-bold py-1 px-4 rounded focus:outline-none focus:shadow-outline absolute right-2 top-1.5 text-sm cursor-pointer"
                >
                  Browse File
                </label>
              </div>
            </div>
            {imageFile && (
              <div className="mb-4">
                <ReactCrop
                  crop={crop}
                  onChange={(_, newCrop) => setCrop(newCrop)}
                  onComplete={handleCropComplete}
                  minHeight={100}
                >
                  <img ref={imageRef} alt="Crop me" src={imageFile} />
                </ReactCrop>
              </div>
            )}
            <div className="mb-4">
              <label className="block text-sm font-medium text-white text-left">
                Description
              </label>
              <textarea
                id="description"
                name="description"
                required
                maxLength={180}
                rows={3}
                className="mt-1 block w-full text-white px-3 py-2 bg-[#372F5B] rounded-md shadow-sm focus:outline-none focus:ring focus:ring-red-500 focus:ring-opacity-50"
                value={formData?.description}
                onChange={handleChange}
              ></textarea>
            </div>
            <button
              type="submit"
              className="bg-red-500 text-white font-bold py-2 px-4 rounded inline-block mb-5 w-full"
            >
              Submit
            </button>
          </form>
        </div>
      </div>
    </div>
  );
};

export default AddbannerPopup;
