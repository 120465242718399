import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { loginAPI } from "../../utils/api";
import Cookies from "js-cookie";
import { LineWave } from "react-loader-spinner";
import { FaEye, FaEyeSlash } from "react-icons/fa";
import { toast } from "react-toastify";
const LoginForm = () => {
  
  const navigate = useNavigate();
  const [formData, setFormData] = useState({
    email: "",
    role: "admin",
    password: "",
  });
  const [loading, setLoading] = useState(false);
  const [passwordVisible, setPasswordVisible] = useState(false);

  const handlePasswordToggle = () => {
    setPasswordVisible((prev) => !prev);
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    try {
      const response = await loginAPI(formData);
      if (response.code === 200) {
        Cookies.set("token", response?.data?.token);
        toast.success(response.message);
        navigate("/panel/dashboard");
      }
    } catch (error) {
      if (error.response && error.response.data.message) {
        console.log(error.response.data.message);
        toast.error(error.response.data.message);
      } else {
        toast.error("Failed to login. please try again");
      }
      console.error("Error occurred during login:", error);
    } finally {
      setLoading(false);
    }
  };

  return (
    <form onSubmit={handleSubmit}>
      <div className="mb-4">
        <label className="flex text-[#fff]">Email:</label>
        <input
          type="email"
          name="email"
          value={formData.email}
          placeholder="Email"
          onChange={handleInputChange}
          required
          className="w-full p-2 bg-white my-2 rounded-md border border-black border-t-1"
        />
      </div>
      <div className="mb-4 relative">
        <label className="flex text-[#fff]">Password:</label>
        <input
          type={passwordVisible ? "text" : "password"}
          name="password"
          value={formData.password}
          onChange={handleInputChange}
          className="w-full p-2 bg-white my-2 rounded-md border border-black border-t-1"
          placeholder="Password"
          required
        />
        <span
          className="absolute top-[45px] right-2 cursor-pointer"
          onClick={handlePasswordToggle}
        >
          {passwordVisible ? <FaEye /> : <FaEyeSlash />}
        </span>
      </div>
      <button
        type="submit"
        className="w-full p-3 mt-5 bg-[#EE1C24] text-white border-none rounded-md"
      >
        {loading ? "Logging in..." : "Login"}
      </button>

      {loading && (
        <div className="loader">
          <LineWave color={"#ffffff"} loading={loading} size={80} />
        </div>
      )}
    </form>
  );
};

export default LoginForm;
