import React, { useEffect } from "react";
import DashboardCard from "./DashboardCard";
import { useDispatch, useSelector } from "react-redux";
import { getDashboard } from "../../redux/slices/dashboardSlice";
const Dashboard = () => {
  const dispatch = useDispatch();

  const { dashboard, isLoading } = useSelector(
    (state) => state.dashboardReducer
  );

  useEffect(() => {
    dispatch(getDashboard());
  }, []);

  return (
    <div
      className={`p-4 bg-[#150C3F] transition-all duration-300 h-full 
      }`}
    >
      <DashboardCard dashboard={dashboard} isLoading={isLoading} />
    </div>
  );
};

export default Dashboard;
