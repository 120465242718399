import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import {
  createScenario,
  getScenarioById,
  updateScenario,
} from "../../redux/slices/scenarioSlice";
import { LineWave } from "react-loader-spinner";
import { IoMdInformationCircleOutline } from "react-icons/io";
import { toast } from "react-toastify";

const CreateScenario = () => {
  const dispatch = useDispatch();
  const scenarioData = useSelector((state) => state?.scenarioReducer);
  const navigate = useNavigate();
  const { id } = useParams();
  const [formData, setFormData] = useState({
    name: "",
    image: null,
    minLevel: "",
    maxLevel: "",
    minNoOfPlayer: "",
    points: "",
    description: "",
    maxNoOfPlayer: "",
    duration: "",
    isRepeatable: "yes",
  });

  const handleRepeatable = (e) => {
    const { value } = e.target;
    setFormData({ ...formData, isRepeatable: value });
  };

  const handleLevels = (e) => {
    let { name, value } = e.target;
    let newVal = value;
    if (/^\d*$/.test(newVal)) {
      if (newVal === "" || (newVal >= 1 && newVal <= 10)) {
        setFormData({ ...formData, [name]: newVal });
      }
    } else {
      e.preventDefault();
    }
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    let validatedValue = value;
    if (/^\s/.test(validatedValue)) {
      validatedValue = "";
    }

    if (name === "points" || name === "duration") {
      validatedValue = Math.max(parseInt(value), 0);
    }
    setFormData({ ...formData, [name]: validatedValue });
  };

  const handleFileChange = (event) => {
    setFormData({ ...formData, image: event.target.files[0] });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!formData?.image) {
      return;
    }
    const data = new FormData();
    for (const key in formData) {
      if (key === "isRepeatable") {
        data.append(key, formData[key] === "yes" ? true : false);
      } else {
        data.append(key, formData[key]);
      }  
    }
    try {
      if (id) {
        const formDataToSubmit = new FormData();
        formDataToSubmit.append("name", formData.name);
        formDataToSubmit.append("image", formData.image);
        formDataToSubmit.append("minLevel", formData.minLevel);
        formDataToSubmit.append("maxLevel", formData.maxLevel);
        formDataToSubmit.append("minNoOfPlayer", formData.minNoOfPlayer);
        formDataToSubmit.append("maxNoOfPlayer", formData.maxNoOfPlayer);
        formDataToSubmit.append("points", formData.points);
        formDataToSubmit.append("description", formData.description);
        formDataToSubmit.append("duration", formData.duration);
        formDataToSubmit.append("isRepeatable", formData.isRepeatable);
        formDataToSubmit.append("releaseDate", formData.releaseDate);
        dispatch(updateScenario({ id, data: formDataToSubmit })).then(() => {
          setFormData({
            name: "",
            image: null,
            minLevel: "",
            maxLevel: "",
            minNoOfPlayer: "",
            points: "",
            description: "",
            maxNoOfPlayer: "",
            duration: "",
            isRepeatable: "yes",
          });
          toast.success("Scenario Updated Successfully");
          navigate("/panel/scenario");
        });
      } else {
        dispatch(createScenario(data)).then(() => {
          setFormData({
            name: "",
            image: null,
            minLevel: "",
            maxLevel: "",
            minNoOfPlayer: "",
            points: "",
            description: "",
            maxNoOfPlayer: "",
            duration: "",
            isRepeatable: "yes",
          });
          toast.success("Scenario Created Successfully");
          navigate("/panel/scenario");
        });
      }
    } catch (error) {
      throw error;
    } finally {
    }
  };

  const fetchScenario = async () => {
    dispatch(getScenarioById(id)).then((res) => {
      const { data } = res?.payload;
      setFormData({
        ...data,
        isRepeatable: data?.isRepeatable === true ? "yes" : "no",
      });
    });
  };

  useEffect(() => {
    if (id) {
      fetchScenario();
    }
  }, [id]);

  return (
    <form
      onSubmit={handleSubmit}
      className=" max-w-7xl mx-auto mt-8 p-6 bg-[#211849]  shadow-md rounded-lg text-left text-white"
    >
      <h2 className="text-2xl font-bold mb-8  text-white">
        {id ? "Update Scenario" : "Create New Scenario"}
      </h2>
      <div className="flex flex-wrap -mx-2 mb-4">
        <div className="w-full sm:w-1/2 px-2">
          <label
            htmlFor="name"
            className="block text-sm font-medium text-white"
          >
            Scenario Name
          </label>
          <input
            type="text"
            id="name"
            name="name"
            value={formData?.name}
            onChange={handleChange}
            required
            maxLength={60}
            className="mt-1 block w-full px-3 py-2  bg-[#372F5B] rounded-md  shadow-sm focus:outline-none focus:ring focus:ring-red-500 focus:ring-opacity-50"
          />
          <p className="text-left text-[12px] text-[#d1d5db] pt-1 flex items-center gap-1">
            <IoMdInformationCircleOutline /> <span>Max Characters (60)</span>
          </p>
        </div>
        <div className="w-full sm:w-1/2 px-2">
          <label
            htmlFor="image"
            className="block text-sm font-medium text-white"
          >
            Scenario Image
          </label>
          <div className="relative">
            <input
              type="text"
              id="imageText"
              name="imageText"
              value={
                formData?.image?.name
                  ? formData?.image?.name
                  : formData?.imageName
              }
              readOnly
              className="mt-1 block w-full px-3 py-2 bg-[#372F5B] rounded-md shadow-sm focus:outline-none focus:ring focus:ring-red-500 focus:ring-opacity-50"
            />

            <input
              type="file"
              id="image"
              name="image"
              accept=".png,.jpg,.jpeg"
              onChange={handleFileChange}
              style={{ display: "none" }}
            />
            <label
              htmlFor="image"
              className="bg-[gray] text-white font-bold py-1 px-4 rounded focus:outline-none focus:shadow-outline absolute right-2 top-1.5 text-sm cursor-pointer"
            >
              Browse File
            </label>
          </div>

          <p className="text-left text-[12px] text-[#d1d5db] pt-1 flex items-center gap-1">
            <IoMdInformationCircleOutline />
            <span>PNG or JPEG, Max size 500KB and Dimension (340*180)</span>
          </p>
        </div>
      </div>

      <div className="flex flex-wrap -mx-2 mb-4">
        <div className="w-full sm:w-1/2 px-2">
          <label
            htmlFor="minLevel"
            className="block text-sm font-medium text-white"
          >
            Min Level
          </label>
          <input
            type="text"
            inputMode="numeric"
            id="minLevel"
            name="minLevel"
            value={formData?.minLevel}
            required
            onChange={handleLevels}
            className="mt-1 block w-full px-3 py-2  bg-[#372F5B] rounded-md shadow-sm  focus:outline-none focus:ring focus:ring-red-500 focus:ring-opacity-50"
          />
          <p className="text-left text-[12px] text-[#d1d5db] pt-1 flex items-center gap-1">
            <IoMdInformationCircleOutline /> <span>MinLevel (1)</span>
          </p>
        </div>

        <div className="w-full sm:w-1/2 px-2">
          <label
            htmlFor="maxLevel"
            className="block text-sm font-medium text-white"
          >
            Max Level
          </label>
          <input
            type="text"
            inputMode="numeric"
            id="maxLevel"
            name="maxLevel"
            value={formData?.maxLevel}
            onChange={handleLevels}
            required
            className="mt-1 block w-full px-3 py-2  bg-[#372F5B] rounded-md shadow-sm  focus:outline-none focus:ring focus:ring-red-500 focus:ring-opacity-50"
          />
          <p className="text-left text-[12px] text-[#d1d5db] pt-1 flex items-center gap-1">
            <IoMdInformationCircleOutline /> <span>MaxLevel (10)</span>
          </p>
        </div>
      </div>

      <div className="flex flex-wrap -mx-2 mb-4">
        <div className="w-full sm:w-1/2 px-2">
          <label
            htmlFor="minNoOfPlayer"
            className="block text-sm font-medium text-white"
          >
            Min Number of Players
          </label>
          <input
            type="text"
            inputMode="numeric"
            id="minNoOfPlayer"
            name="minNoOfPlayer"
            value={formData?.minNoOfPlayer}
            onChange={handleLevels}
            required
            className="mt-1 block w-full px-3 py-2  bg-[#372F5B] rounded-md shadow-sm focus:outline-none focus:ring focus:ring-red-500 focus:ring-opacity-50"
          />
          <p className="text-left text-[12px] text-[#d1d5db] pt-1 flex items-center gap-1">
            <IoMdInformationCircleOutline /> <span>Min no. of players (1)</span>
          </p>
        </div>

        <div className="w-full sm:w-1/2 px-2">
          <label
            htmlFor="maxNoOfPlayer"
            className="block text-sm font-medium text-white"
          >
            Max Number of Players
          </label>
          <input
            type="text"
            inputMode="numeric"
            id="maxNoOfPlayer"
            name="maxNoOfPlayer"
            value={formData?.maxNoOfPlayer}
            onChange={handleLevels}
            required
            className="mt-1 block w-full px-3 py-2  bg-[#372F5B] rounded-md  shadow-sm focus:outline-none focus:ring focus:ring-red-500 focus:ring-opacity-50"
          />
          <p className="text-left text-[12px] text-[#d1d5db] pt-1 flex items-center gap-1">
            <IoMdInformationCircleOutline />{" "}
            <span>Max no. of players (10)</span>
          </p>
        </div>
      </div>

      <div className="flex flex-wrap -mx-2 mb-4">
        <div className="w-full sm:w-1/2 px-2">
          <label
            htmlFor="points"
            className="block text-sm font-medium text-white"
          >
            Milestone Points
          </label>
          <input
            type="number"
            id="points"
            name="points"
            value={formData?.points}
            onChange={handleChange}
            required
            className="mt-1 block w-full px-3 py-2  bg-[#372F5B] rounded-md shadow-sm  focus:outline-none focus:ring focus:ring-red-500 focus:ring-opacity-50"
          />
        </div>

        <div className="w-full sm:w-1/2 px-2">
          <label
            htmlFor="duration"
            className="block text-sm font-medium text-white"
          >
            Duration (hours)
          </label>
          <input
            type="number"
            id="duration"
            name="duration"
            value={formData?.duration}
            onChange={handleChange}
            required
            className="mt-1 block w-full px-3 py-2  bg-[#372F5B] rounded-md shadow-sm  focus:outline-none focus:ring focus:ring-red-500 focus:ring-opacity-50"
          />
        </div>
      </div>

      <div className="mb-4">
        <label
          htmlFor="description"
          className="block text-sm font-medium text-white"
        >
          Description
        </label>
        <textarea
          id="description"
          name="description"
          value={formData?.description}
          onChange={handleChange}
          maxLength={180}
          required
          rows="3"
          className="mt-1 block w-full px-3 py-2  bg-[#372F5B] rounded-md  shadow-sm focus:outline-none focus:ring focus:ring-red-500 focus:ring-opacity-50"
        ></textarea>
        <p className="text-left text-[12px] text-[#d1d5db] pt-1 flex items-center gap-1">
          <IoMdInformationCircleOutline /> <span>Max Characters (180)</span>
        </p>
      </div>
      <div className="flex flex-wrap -mx-2 mb-4">
        <div className="w-full sm:w-1/2 px-2">
          <label
            htmlFor="releaseDate"
            className="block text-sm font-medium text-white"
          >
            Release Date
          </label>
          <div>
            <input
              id="releaseDate"
              name="releaseDate"
              type="date"
              value={formData?.releaseDate}
              onChange={(e) => {
                setFormData((prevState) => ({
                  ...prevState,
                  releaseDate: e.target.value,
                }));
              }}
              min={!id && new Date().toISOString().split("T")[0]}
              required
              className="mt-1 px-3 py-2 text-white w-full bg-[#372F5B] rounded-md  shadow-sm focus:outline-none focus:ring focus:ring-red-500 focus:ring-opacity-50"
            />
          </div>
        </div>
      </div>
      <div className="mb-4">
        <div className="flex gap-4">
          <div className="flex items-center gap-1">
            <input
              type="radio"
              id="repeatable"
              name="isRepeatable"
              value={"yes"}
              onChange={handleRepeatable}
              checked={formData?.isRepeatable === "yes"}
              className="appearance-none w-4 h-4 border-2 border-red-500 rounded-full checked:bg-red-500 checked:border-transparent focus:ring-2 focus:ring-red-500"
            />
            <label
              htmlFor="repeatable"
              className="block text-sm font-medium text-white"
            >
              Repeatable
            </label>
          </div>
          <div className="flex items-center gap-1">
            <input
              type="radio"
              id="non-repeatable"
              name="isRepeatable"
              value={"no"}
              onChange={handleRepeatable}
              checked={formData?.isRepeatable === "no"}
              className="appearance-none w-4 h-4 border-2 border-red-500 rounded-full checked:bg-red-500 checked:border-transparent focus:ring-2 focus:ring-red-500"
            />
            <label
              htmlFor="non-repeatable"
              className="block text-sm font-medium text-white"
            >
              Non-Repeatable
            </label>
          </div>
        </div>
      </div>
      <div className="flex justify-end gap-4">
        <button
          type="submit"
          className="bg-[#EE1C24] text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline"
        >
          {id ? "Update Scenario" : "Create Scenario"}
        </button>
        {id && (
          <button
            className="bg-[#EE1C24] text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline"
            type="button"
            onClick={() => {
              navigate("/panel/scenario");
            }}
          >
            Cancel
          </button>
        )}
      </div>
      {scenarioData?.isLoading && (
        <div className="loader">
          <LineWave
            loading={scenarioData?.isLoading}
            color="#ffff"
            size={150}
          />
        </div>
      )}
    </form>
  );
};

export default CreateScenario;
