import React from "react";

import LoginForm from "./LoginForm";
const Login = () => {
  return (
    <div className="h-screen bg-[#150C3F] flex flex-col gap-12  justify-center items-center">
        <div className="pl-3 pr-3">
          <div className="p-8 rounded-lg bg-[#211849]">
          <div className="image_container mb-4">
            <img src="/assets/images/loginimg.svg" alt="login page" className=" w-full max-w-[300px]"/>
          </div>
            <LoginForm />
          </div>
        </div>
    </div>
  );
};

export default Login;
