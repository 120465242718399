import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getAllScenarios } from "../../redux/slices/scenarioSlice";
import ScenarioCard from "./ScenarioCard";
import { Link } from "react-router-dom";
import { LineWave } from "react-loader-spinner";

const Scenario = () => {
  const dispatch = useDispatch();
  const scenarioData = useSelector((state) => state.scenarioReducer);

  useEffect(() => {
    dispatch(getAllScenarios());
  },[]);

  return (
    <div className="pt-4 bg-[#150C3F]">
      <div className="text-right pr-2">
        <Link
          to="/panel/createscenario"
          className="bg-red-500 text-white font-bold py-2 px-4 rounded inline-block mb-5"
        >
          Create Scenario
        </Link>
      </div>
      <div className="flex flex-wrap">
        {scenarioData?.scenarios?.data?.map((item) => (
          <ScenarioCard {...item} key={item?._id} />
        ))}
      </div>
      {scenarioData?.isLoading && (
        <div className="loader">
          <LineWave
            color={"#fff"}
            loading={scenarioData?.isLoading}
            size={150}
          />
        </div>
      )}
    </div>
  );
};

export default Scenario;
