import React, { useEffect } from "react";
import backgroundPattern from "../../images/backgroundPattern.svg";
import { getAvatars } from "../../redux/slices/characterSlice";
import { useDispatch, useSelector } from "react-redux";
import AvatarCard from "./AvatarCard";
const Avatar = () => {
  const dispatch = useDispatch();
  const { avatars } = useSelector((state) => state.characterReducer);

  useEffect(() => {
    dispatch(getAvatars());
  }, []);

  return (
    <div>
      <div
        className="bg-no-repeat bg-cover py-10 px-14 rounded-xl text-left"
        style={{ backgroundImage: `url(${backgroundPattern})` }}
      >
        <h1 className=" text-white text-[30px]">Avatars</h1>
      </div>
       <div className="bg-[#211849] p-[30px] shadow-sm flex flex-wrap rounded-[10px]">
         {avatars?.data?.map((avatar)=>{
            return <AvatarCard data = {avatar} key={avatar?._id}/>
         })}
       </div>
    </div>
  );
};

export default Avatar;
