import React, { useEffect, useState } from "react";
import BannerImagesCard from "./BannerImagesCard";
import { getAllBanners } from "../../redux/slices/settingsSlice";
import { useDispatch, useSelector } from "react-redux";
import { LineWave } from "react-loader-spinner";
import AddbannerPopup from "./AddbannerPopup";
import { Link } from "react-router-dom";

const Banners = () => {
  const dispatch = useDispatch();
  const [openBanner, setOpenBanner] = useState(false);
  const [selectedBanner, setSelectedBanner] = useState({});
  const { settings, isLoading } = useSelector((state) => state.settingsReducer);

  useEffect(() => {
    dispatch(getAllBanners());
  }, []);

  return (
    <>
      <div className="text-right pr-2">
        <Link
          className="bg-red-500 text-white font-bold py-2 px-4 rounded inline-block mb-5"
          to={`/panel/addbanner`}
        >
          Add New Banner
        </Link>
      </div>
      <div className="flex flex-wrap items-center">
        {Array.isArray(settings?.data) ? (
          settings.data.map((setting) => (
            <BannerImagesCard
              data={setting}
              key={setting._id}
              setSelectedBanner={setSelectedBanner}
              setOpenBanner={setOpenBanner}
            />
          ))
        ) : (
          <p>No banners available</p> 
        )}
      </div>
      {openBanner && (
        <AddbannerPopup
          setOpenBanner={setOpenBanner}
          selectedBanner={selectedBanner}
          setSelectedBanner={setSelectedBanner}
        />
      )}
      {isLoading && (
        <div className="loader">
          <LineWave color={"#fff"} loading={isLoading} size={150} />
        </div>
      )}
    </>
  );
};

export default Banners;
