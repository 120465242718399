import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { Link } from "react-router-dom";
import { useLocation } from "react-router-dom";
import {
  deleteScenario,
  getAllScenarios,
} from "../../redux/slices/scenarioSlice";
import Confirmation from "../../components/confirmation/Confirmation";
import { toast } from "react-toastify";
import PointSvg from "../../components/svg/PointsSvg";
import ScenarioRepeatableSvg from "../../components/svg/ScenarioRepeatableSvg";
import ScenarioLevelsSvg from "../../components/svg/ScenarioLevelsSvg";
import ScenarioPlayersSvg from "../../components/svg/ScenarioPlayersSvg";
const ScenarioCard = ({
  name,
  description,
  isRepeatable,
  maxLevel,
  minLevel,
  noOfPlayer,
  points,
  image,
  _id,
}) => {
  const location = useLocation();

  const details = [
    { icon: "level", text: `Levels ${minLevel}-${maxLevel}` },
    { icon: "players", text: `${noOfPlayer}` },
    { icon: "points", text: `${points} Points` },
    {
      icon: "repeatable",
      text: isRepeatable ? "Repeatable" : "Non Repeatable",
    },
  ];
  const dispatch = useDispatch();
  const [showConfirmModal, setShowConfirmModal] = useState(false);

  const handleDel = async () => {
    setShowConfirmModal(true);
  };

  const confirmDelete = () => {
    dispatch(deleteScenario(_id)).then(() => {
      setShowConfirmModal(false);
      dispatch(getAllScenarios());
      toast.success("Scenario deleted successfully!");
    });
  };

  const cancelDelete = () => {
    setShowConfirmModal(false);
  };

  return (
    <div className="w-full sm:w-1/2 md:w-1/2 lg:w-1/3 xl:w-1/4 px-2 mb-4">
      <div className="relative bg-[#211849] rounded-lg overflow-hidden shadow-md h-full flex flex-col">
        <img
          src={image}
          alt={name}
          className="w-full h-40 object-cover object-center rounded-t-lg"
        />
        {location.pathname === "/panel/scenario" ? (
          <div className="absolute top-0 right-0 m-2">
            <Link
              className="text-white bg-blue-500 px-2 py-1 rounded hover:bg-blue-600 focus:outline-none text-sm  inline-block"
              to={`/panel/editscenario/${_id}`}
            >
              Edit
            </Link>
            <button
              className="text-white bg-red-500 px-2 py-1 rounded hover:bg-red-600 focus:outline-none ml-2 text-sm"
              onClick={handleDel}
            >
              Delete
            </button>
          </div>
        ) : null}

        <div className="p-4 text-white text-left">
          <h2 className="text-lg font-bold mb-2">{name}</h2>
          <p className="text-sm">{description}</p>
        </div>
        <div className="flex items-center flex-wrap mt-auto text-white">
          {details.map((detail, index) => (
            <div className="w-full sm:w-1/2 pl-2 pr-2 mb-3" key={index}>
              <div className="flex items-center pr-2 pl-2 bg-[#FFFFFF26] rounded-[11px] px-3 py-1 text-[12px] font-semibold">
                {detail.icon === "points" && <PointSvg />}
                {detail.icon === "repeatable" && <ScenarioRepeatableSvg />}
                {detail.icon === "level" && <ScenarioLevelsSvg />}
                {detail.icon === "players" && <ScenarioPlayersSvg />}
                <span className="inline-block pl-2 text-[12px]">
                  {detail.text}
                </span>
              </div>
            </div>
          ))}
        </div>
      </div>
      {showConfirmModal && (
        <Confirmation
          confirmDelete={confirmDelete}
          cancelDelete={cancelDelete}
          message={"delete this Scenario"}
          buttonText="Delete"
        />
      )}
    </div>
  );
};

export default ScenarioCard;
