import React from "react";

const OflineSvg = (width, height) => {
  return (
    <>
      <svg
        width="14"
        height="14"
        viewBox="0 0 14 14"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M5.77251 3.09628C6.15939 2.97476 6.57156 2.90908 6.99992 2.90908C9.25925 2.90908 11.0908 4.74061 11.0908 6.99992C11.0908 7.42828 11.0251 7.84045 10.9036 8.22736C10.8284 8.46685 10.9615 8.72198 11.201 8.79716C11.4405 8.87239 11.6957 8.73926 11.7708 8.49971C11.9197 8.02572 11.9998 7.52173 11.9998 6.99992C11.9998 4.23854 9.76128 2 6.99992 2C6.47811 2 5.97413 2.08009 5.5001 2.22897C5.2606 2.3042 5.12743 2.55933 5.20265 2.79883C5.27787 3.03833 5.53301 3.1715 5.77251 3.09628ZM2.13313 2.13313C2.31064 1.95562 2.59844 1.95562 2.77595 2.13313L11.8667 11.2239C12.0442 11.4014 12.0442 11.6892 11.8667 11.8667C11.6892 12.0442 11.4014 12.0442 11.2239 11.8667L10.1994 10.8423C9.33243 11.565 8.21695 11.9998 6.99992 11.9998C4.23854 11.9998 2 9.76128 2 6.99992C2 5.78289 2.43482 4.66742 3.15758 3.80039L2.13313 2.77595C1.95562 2.59844 1.95562 2.31064 2.13313 2.13313ZM8.96121 9.60401L9.55338 10.1962C9.24229 10.445 8.89416 10.6494 8.51849 10.7996C8.58644 10.6676 8.64944 10.5281 8.70757 10.3828C8.80307 10.1441 8.88803 9.88319 8.96121 9.60401ZM6.81165 7.45446L8.20031 8.84312C8.11508 9.29761 8.00004 9.70392 7.86354 10.0452C7.71168 10.4247 7.54227 10.7014 7.37737 10.8758C7.21342 11.0492 7.08569 11.0908 6.99992 11.0908C6.91415 11.0908 6.78642 11.0492 6.62247 10.8758C6.45756 10.7014 6.28816 10.4247 6.1363 10.0452C5.87207 9.38456 5.68809 8.48012 5.64565 7.45446H6.81165ZM5.65854 6.30134L5.90256 6.54538H5.64565C5.64905 6.46325 5.65336 6.38188 5.65854 6.30134ZM3.80365 4.44646L4.82824 5.47105C4.78133 5.81504 4.75002 6.17484 4.73587 6.54538H2.93404C3.02127 5.75636 3.33287 5.03498 3.80365 4.44646ZM4.73587 7.45446H2.93404C3.10253 8.97862 4.10838 10.2504 5.48135 10.7996C5.4134 10.6676 5.3504 10.5281 5.29227 10.3828C4.97878 9.59906 4.77869 8.57585 4.73587 7.45446Z"
          fill="white"
        />
      </svg>
    </>
  );
};

export default OflineSvg;
