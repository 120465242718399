import React, { useEffect, useState, useRef } from "react";
import { Formik, Form, Field, ErrorMessage } from "formik";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import * as Yup from "yup";
import { toast } from "react-toastify";
import { LineWave } from "react-loader-spinner";
import ScheduleGame from "./ScheduleGame";
import LocationInput from "./LocationInput";
import { getAllScenarios } from "../../redux/slices/scenarioSlice";
import {
  createGame,
  gamedetails,
  getOnlineLocationsData,
  timezonesList,
  updateGame,
} from "../../redux/slices/gameSlice";
import ScenarioLevelsSvg from "../../components/svg/ScenarioLevelsSvg";
import ScenarioPlayersSvg from "../../components/svg/ScenarioPlayersSvg";
import ScenarioRepeatableSvg from "../../components/svg/ScenarioRepeatableSvg";
import { getCurrentDateTime } from "../../utils/api";

const CreateGame = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const formikRef = useRef(null);
  const { id } = useParams();
  const [showBar, setShowBar] = useState(false);
  const [locationBar, setLocationBar] = useState(false);
  const [timezoneSearch, setTimezoneSearch] = useState([]);
  const [search, setSearch] = useState("");
  const [selectedScenario, setSelectedScenario] = useState({});
  const [currentTime, setCurrentTime] = useState({});
  const [scheduleModel, setShowScheduleModel] = useState(false);
  const [formData, setFormData] = useState({});
  const scenarioData = useSelector((state) => state.scenarioReducer);
  const gameData = useSelector((state) => state.gameReducer);

  useEffect(() => {
    dispatch(getAllScenarios());
    dispatch(getOnlineLocationsData());
    dispatch(timezonesList());
  }, []);

  const validationSchema = Yup.object().shape({
    gameTitle: Yup.string()
      .min(3, "Too Short!")
      .trim()
      .required("Game title is required"),

    playMode: Yup.string().required("Play mode is required"),
    location: Yup.string().trim().required("Location is required"),
    chooseScenario: Yup.string().trim().required("Scenario is required"),
    specificLocation: Yup.string()
      .trim()
      .max(20, "Specific location should be of 20 characters"),
    notes: Yup.string().trim().max(2000, "Character Limit: 2000").optional(),
    memberShipId: Yup.string()
      .trim()
      .required("Game master's membership id is required"),
    Timezone: Yup.string().when("playMode", {
      is: (val) => val === "online",
      then: (schema) => schema.required("Timezone is required"),
      otherwise: (schema) => schema.notRequired(),
    }),
    gameLink: Yup.string().when("playMode", {
      is: (val) => val === "online",
      then: (schema) => schema.required("Game link is required"),
      otherwise: (schema) => schema.notRequired(),
    }),
  });

  const handleSchedule = () => {
    setShowScheduleModel(true);
  };

  const cancelModel = () => {
    setShowScheduleModel(false);
  };

  const handleSearch = (e, setFieldValue) => {
    const timezoneFilter = gameData?.timezones.filter((timezone) =>
      timezone.value.toLowerCase().includes(e.target.value.toLowerCase())
    );
    setFieldValue("Timezone", "");
    setSearch(e.target.value);
    setTimezoneSearch(timezoneFilter);
  };

  useEffect(() => {
    if (id) {
      dispatch(gamedetails(id)).then((res) => {
        const { data } = res?.payload;
        formikRef.current.setValues(
          data?.modes === "offline"
            ? {
                gameTitle: data.gameTitle,
                playMode: data?.modes,
                location: data?.location[0]?.name,
                chooseScenario: data?.scenarioId?.name,
                memberShipId: data?.GMuser?.membershipId,
                specificLocation: data?.specificLocation,
                notes: data?.notes,
              }
            : {
                gameTitle: data.gameTitle,
                playMode: data?.modes,
                location: data?.onlineLocation,
                chooseScenario: data?.scenarioId?.name,
                memberShipId: data?.GMuser?.membershipId,
                Timezone: data?.userTimeZone,
                gameLink: data?.gameLink,
                notes: data?.notes,
              }
        );
        if (data?.modes === "offline") {
          setCurrentTime({
            formattedAddress: data?.location[0]?.formattedAddress,
            timezone: data?.regionTimeZone,
            timezoneId: data?.userTimeZone,
            lat: data?.location[0]?.lat,
            lng: data?.location[0]?.long,
          });
        }
        setSelectedScenario(data?.scenarioId);
      });
    }
  }, []);

  return (
    <div>
      <div className="max-w-7xl mx-auto mt-8 p-6 bg-[#211849] shadow-md rounded-lg text-left text-white">
        <h2 className="text-2xl font-bold mb-8 text-white">
          {id ? "Edit Game" : "Create Game"}
        </h2>
        <Formik
          innerRef={formikRef}
          initialValues={{
            gameTitle: "",
            playMode: "offline",
            gameLink: "",
            location: "",
            chooseScenario: "",
            specificLocation: "",
            Timezone: "",
            memberShipId: "",
            notes: "",
          }}
          validationSchema={validationSchema}
          onSubmit={async (values) => {
            const currentDateTime = await getCurrentDateTime(
              values?.Timezone ? values?.Timezone : currentTime?.timezoneId
            );
            let payload;
            if (values.playMode === "offline") {
              payload = {
                gameTitle: values?.gameTitle.trim(),
                modes: values?.playMode,
                formattedAddress: currentTime?.formattedAddress,
                geo: `${currentTime?.lng}, ${currentTime?.lat}`,
                scenarioId: selectedScenario?._id,
                isScheduled: false,
                locationName: values?.location,
                startTime: currentDateTime?.time,
                startDate: currentDateTime?.date,
                userTimeZone: currentTime?.timezoneId,
                regionTimeZone: currentTime?.timezone,
                membershipId: values?.memberShipId.trim(),
                specificLocation: values?.specificLocation.trim(),
                notes: values?.notes?.trim(),
              };
              if (id) {
                payload = { ...payload, status: "NOTSTARTED" };
              }
            } else {
              payload = {
                gameTitle: values?.gameTitle.trim(),
                scenarioId: selectedScenario?._id,
                isScheduled: false,
                userTimeZone: values?.Timezone.toLowerCase(),
                modes: values?.playMode,
                startTime: currentDateTime?.time,
                startDate: currentDateTime?.date,
                gameLink: values?.gameLink,
                membershipId: values?.memberShipId.trim(),
                onlineLocation: values?.location,
                notes: values?.notes?.trim(),
              };
              if (id) {
                payload = { ...payload, status: "NOTSTARTED" };
              }
            }
            if (id) {
              dispatch(updateGame({ gameId: id, body: payload })).then(
                (res) => {
                  if (res.payload.code === 200) {
                    toast.success("Game Updated Successfully");
                    navigate("/panel/games");
                  } else {
                    if (
                      res.payload.response.data.message ===
                      "Invalid Membership Id"
                    ) {
                      toast.error("Invalid Membership Id");
                    } else {
                      toast.error("Something Went Wrong");
                    }
                  }
                }
              );
            } else {
              dispatch(createGame(payload)).then((res) => {
                if (res.payload.code === 200) {
                  toast.success("Game Created Successfully");
                  navigate("/panel/games");
                } else {
                  if (
                    res.payload.response.data.message ===
                    "Invalid Membership Id"
                  ) {
                    toast.error("Invalid Membership Id");
                  } else {
                    toast.error("Something Went Wrong");
                  }
                }
              });
            }
          }}
        >
          {({
            values,
            handleChange,
            handleSubmit,
            setFieldValue,
            setTouched,
            validateForm,
            setFieldTouched,
          }) => (
            <Form>
              <div className="mb-4">
                <div className="flex gap-4">
                  <div className="flex items-center gap-1">
                    <Field
                      type="radio"
                      id="playInPerson"
                      name="playMode"
                      disabled={id}
                      onChange={() => {
                        setFieldValue("location", "");
                        setFieldValue("playMode", "offline");
                        setFieldValue("Timezone", "");
                        setSearch("");
                        setTimezoneSearch([]);
                      }}
                      value="offline"
                      checked={values.playMode === "offline"}
                      className="appearance-none w-4 h-4 border-2 border-red-500 rounded-full checked:bg-red-500 checked:border-transparent focus:ring-2 focus:ring-red-500"
                    />
                    <label
                      htmlFor="playInPerson"
                      className="block text-sm font-medium text-white"
                    >
                      Play In-Person
                    </label>
                  </div>
                  <div className="flex items-center gap-1">
                    <Field
                      type="radio"
                      id="playOnline"
                      disabled={id}
                      name="playMode"
                      onChange={() => {
                        setFieldValue("location", "");
                        setFieldValue("playMode", "online");
                      }}
                      value="online"
                      checked={values.playMode === "online"}
                      className="appearance-none w-4 h-4 border-2 border-red-500 rounded-full checked:bg-red-500 checked:border-transparent focus:ring-2 focus:ring-red-500"
                    />
                    <label
                      htmlFor="playOnline"
                      className="block text-sm font-medium text-white"
                    >
                      Play Online
                    </label>
                  </div>
                </div>
                <ErrorMessage
                  name="playMode"
                  component="p"
                  className="error text-red-500"
                />
              </div>

              <div className="flex flex-wrap -mx-2 mb-4">
                <div className="w-full sm:w-1/2 px-2">
                  <label
                    htmlFor="gameTitle"
                    className="block text-sm font-medium text-white"
                  >
                    Game Title
                  </label>
                  <Field
                    type="text"
                    id="gameTitle"
                    name="gameTitle"
                    onChange={handleChange}
                    value={values.gameTitle}
                    className="mt-1 block w-full px-3 py-2 bg-[#372F5B] rounded-md shadow-sm focus:outline-none focus:ring focus:ring-red-500 focus:ring-opacity-50"
                  />
                  <ErrorMessage
                    name="gameTitle"
                    component="p"
                    className="error text-red-500"
                  />
                </div>
                {values.playMode === "offline" ? (
                  <div className="w-full sm:w-1/2 px-2">
                    <label
                      htmlFor="location"
                      className="block text-sm font-medium text-white"
                    >
                      Location
                    </label>
                    <LocationInput
                      name={"location"}
                      setFieldValue={setFieldValue}
                      setCurrentTime={setCurrentTime}
                    />
                    <ErrorMessage
                      name="location"
                      component="p"
                      className="error text-red-500"
                    />
                  </div>
                ) : (
                  <div className="w-full sm:w-1/2 px-2 relative">
                    <label
                      htmlFor="location"
                      className="block text-sm font-medium text-white"
                    >
                      Location
                    </label>
                    <Field
                      readOnly
                      type="text"
                      id="location"
                      name="location"
                      onChange={handleChange}
                      onClick={() => {
                        setLocationBar(!locationBar);
                        setShowBar(false);
                      }}
                      value={values.location}
                      className="mt-1 block w-full px-3 py-2 bg-[#372F5B] rounded-md shadow-sm focus:outline-none focus:ring focus:ring-red-500 focus:ring-opacity-50"
                    />
                    {locationBar && (
                      <div className="px-2">
                        <div className="absolute bottom-[102%] bg-[#372F5B] w-full left-0 p-2 rounded-md">
                          <ul>
                            {gameData?.onlineLocations?.map((elem, index) => {
                              return (
                                <li
                                  key={index}
                                  className="cursor-pointer border-b border-gray-600 py-2"
                                  onClick={() => {
                                    setFieldValue("location", elem);
                                    setLocationBar(false);
                                  }}
                                >
                                  {elem}
                                </li>
                              );
                            })}
                          </ul>
                        </div>
                      </div>
                    )}
                    <ErrorMessage
                      name="location"
                      component="p"
                      className="error text-red-500"
                    />
                  </div>
                )}
              </div>
              <div className="flex flex-wrap -mx-2 mb-4">
                <div className="w-full sm:w-1/2 px-2">
                  <label
                    htmlFor="chooseScenario"
                    className="block text-sm font-medium text-white"
                  >
                    Choose Scenario
                  </label>
                  <Field
                    as="select"
                    id="chooseScenario"
                    name="chooseScenario"
                    onChange={(e) => {
                      const selectedScenario =
                        scenarioData?.scenarios?.data?.find(
                          (item) => item.name === e.target.value
                        );
                      setSelectedScenario(selectedScenario);
                      setFieldValue("chooseScenario", e.target.value);
                    }}
                    className="mt-1 block w-full px-3 py-2 bg-[#372F5B] rounded-md shadow-sm focus:outline-none focus:ring focus:ring-red-500 focus:ring-opacity-50 h-10"
                  >
                    <option value={""}></option>
                    {scenarioData?.scenarios?.data?.map((item) => {
                      return (
                        <option
                          key={item?._id}
                          test={item?._id}
                          name={item?.name}
                        >
                          {item?.name}
                        </option>
                      );
                    })}
                  </Field>
                  <ErrorMessage
                    name="chooseScenario"
                    component="p"
                    className="error text-red-500"
                  />
                </div>
                {values?.playMode === "offline" && (
                  <div className="w-full sm:w-1/2 px-2">
                    <label
                      htmlFor="specificLocation"
                      className="block text-sm font-medium text-white"
                    >
                      Specific Location
                    </label>
                    <Field
                      type="text"
                      id="specificLocation"
                      name="specificLocation"
                      onChange={handleChange}
                      value={values.specificLocation}
                      className="mt-1 block w-full px-3 py-2 bg-[#372F5B] rounded-md shadow-sm focus:outline-none focus:ring focus:ring-red-500 focus:ring-opacity-50"
                    />
                    <ErrorMessage
                      name="specificLocation"
                      component="p"
                      className="error text-red-500"
                    />
                  </div>
                )}
                {values.playMode === "online" && (
                  <div className="w-full sm:w-1/2 px-2 relative">
                    <label
                      htmlFor="Timezone"
                      className="block text-sm font-medium text-white"
                    >
                      Timezone
                    </label>
                    <Field
                      type="text"
                      id="Timezone"
                      name="Timezone"
                      onChange={(e) => {
                        handleSearch(e, setFieldValue);
                      }}
                      value={search ? search : values?.Timezone}
                      onClick={() => {
                        setLocationBar(false);
                        setShowBar(!showBar);
                        setFieldTouched("Timezone", true);
                      }}
                      className="mt-1 block w-full px-3 py-2 bg-[#372F5B] rounded-md shadow-sm focus:outline-none focus:ring focus:ring-red-500 focus:ring-opacity-50"
                    />
                    {showBar && (
                      <div className="px-2">
                        <div className="absolute top-[102%] bg-[#372F5B] w-full left-0 p-2 rounded-md h-[300px] overflow-y-auto custom-scrollbar">
                          <ul>
                            {timezoneSearch.length
                              ? timezoneSearch?.map((elem, index) => {
                                  return (
                                    <li
                                      key={index}
                                      className="border-b border-gray-600 py-2 cursor-pointer"
                                      onClick={() => {
                                        setFieldValue("Timezone", elem?.value);
                                        setSearch("");
                                        setShowBar(false);
                                      }}
                                    >
                                      {elem?.value}
                                    </li>
                                  );
                                })
                              : gameData.timezones?.map((elem, index) => {
                                  return (
                                    <li
                                      key={index}
                                      className="border-b border-gray-600 py-2 cursor-pointer"
                                      onClick={() => {
                                        setFieldValue("Timezone", elem?.value);
                                        setSearch("");
                                        setShowBar(false);
                                      }}
                                    >
                                      {elem?.value}
                                    </li>
                                  );
                                })}
                          </ul>
                        </div>
                      </div>
                    )}
                    <ErrorMessage
                      name="Timezone"
                      component="p"
                      className="error text-red-500"
                    />
                  </div>
                )}
              </div>
              <div className="flex flex-wrap -mx-2 mb-4">
                {values?.playMode === "online" && (
                  <div className="w-full sm:w-1/2 px-2">
                    <label
                      htmlFor="gameLink"
                      className="block text-sm font-medium text-white"
                    >
                      Game Link
                    </label>
                    <Field
                      type="text"
                      id="gameLink"
                      name="gameLink"
                      onChange={handleChange}
                      value={values.gameLink}
                      className="mt-1 block w-full px-3 py-2 bg-[#372F5B] rounded-md shadow-sm focus:outline-none focus:ring focus:ring-red-500 focus:ring-opacity-50"
                    />
                    <ErrorMessage
                      name="gameLink"
                      component="p"
                      className="error text-red-500"
                    />
                  </div>
                )}
                <div className="w-full sm:w-1/2 px-2">
                  <label
                    htmlFor="memberShipId"
                    className="block text-sm font-medium text-white"
                  >
                    Game Master (Membership ID)
                  </label>
                  <Field
                    type="text"
                    id="memberShipId"
                    name="memberShipId"
                    onChange={handleChange}
                    value={values.memberShipId}
                    className="mt-1 block w-full px-3 py-2 bg-[#372F5B] rounded-md shadow-sm focus:outline-none focus:ring focus:ring-red-500 focus:ring-opacity-50"
                  />
                  <ErrorMessage
                    name="memberShipId"
                    component="p"
                    className="error text-red-500"
                  />
                </div>
              </div>
              {selectedScenario?._id && (
                <div>
                  <div className="flex items-center flex-wrap mt-auto text-white">
                    <div className="pl-2 pr-2 mb-3">
                      <div className="flex items-center pr-2 pl-2 bg-[#FFFFFF26] rounded-[11px] px-3 py-1 text-[12px] font-semibold">
                        <ScenarioLevelsSvg />
                        <span className="inline-block pl-1 text-[12px]">
                          {`Levels ${selectedScenario?.minLevel}-${selectedScenario?.maxLevel}`}
                        </span>
                      </div>
                    </div>
                    <div className="pl-2 pr-2 mb-3">
                      <div className="flex items-center pr-2 pl-2 bg-[#FFFFFF26] rounded-[11px] px-3 py-1 text-[12px] font-semibold">
                        <ScenarioPlayersSvg />
                        <span className="inline-block pl-1 text-[12px]">
                          {selectedScenario?.noOfPlayer}
                        </span>
                      </div>
                    </div>
                    <div className="pl-2 pr-2 mb-3">
                      <div className="flex items-center pr-2 pl-2 bg-[#FFFFFF26] rounded-[11px] px-3 py-1 text-[12px] font-semibold">
                        <ScenarioPlayersSvg />
                        <span className="inline-block pl-1 text-[12px]">
                          {`${selectedScenario?.points} Points`}
                        </span>
                      </div>
                    </div>
                    <div className="pl-2 pr-2 mb-3">
                      <div className="flex items-center pr-2 pl-2 bg-[#FFFFFF26] rounded-[11px] px-3 py-1 text-[12px] font-semibold">
                        <ScenarioRepeatableSvg />
                        <span className="inline-block pl-1 text-[12px]">
                          {`${
                            selectedScenario?.isRepeatable
                              ? "Repeatable"
                              : "Non-Repeatable"
                          }`}
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
              )}
              <div>
                <label>Notes</label>
                <textarea
                  rows="5"
                  name="notes"
                  id="notes"
                  value={values?.notes}
                  className="mt-1 block w-full px-3 py-2 bg-[#372F5B] rounded-md shadow-sm focus:outline-none focus:ring focus:ring-red-500 focus:ring-opacity-50"
                  onChange={handleChange}
                ></textarea>
                <ErrorMessage
                  name="notes"
                  component="p"
                  className="error text-red-500"
                />
              </div>

              <div className=" flex justify-end items-center gap-2">
                <button
                  type="button"
                  className="text-white bg-red-500 px-4 py-2 rounded-md  mt-4"
                  onClick={() => {
                    setTouched({
                      gameTitle: true,
                      gameLink: true,
                      location: true,
                      chooseScenario: true,
                      specificLocation: true,
                      Timezone: true,
                      memberShipId: true,
                      notes: true,
                    });
                    validateForm().then((error) => {
                      if (!Object.keys(error).length) {
                        handleSubmit();
                        setFormData(values);
                      }
                    });
                  }}
                >
                  Launch Game
                </button>
                <button
                  type="button"
                  className="text-white bg-red-500 px-4 py-2 rounded-md  mt-4"
                  onClick={() => {
                    setTouched({
                      gameTitle: true,
                      gameLink: true,
                      location: true,
                      chooseScenario: true,
                      specificLocation: true,
                      Timezone: true,
                      memberShipId: true,
                      notes: true,
                    });
                    validateForm().then((error) => {
                      if (!Object.keys(error).length) {
                        handleSchedule();
                        setFormData(values);
                      }
                    });
                  }}
                >
                  Schedule Game
                </button>
                {id && (
                  <button
                    type="button"
                    className="text-white bg-red-500 px-4 py-2 rounded-md  mt-4"
                    onClick={() => {
                      navigate(`/panel/gamedetails/${id}`);
                    }}
                  >
                    Cancel
                  </button>
                )}
              </div>
            </Form>
          )}
        </Formik>
      </div>
      {scheduleModel && (
        <ScheduleGame
          cancelModel={cancelModel}
          currentTime={currentTime}
          formData={formData}
          selectedScenario={selectedScenario}
          id={id}
        />
      )}
      {gameData?.isLoading && (
        <div className="loader">
          <LineWave color={"#fff"} loading={gameData?.isLoading} size={150} />
        </div>
      )}
    </div>
  );
};

export default CreateGame;
