import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { getAllScenarios } from "../../redux/slices/scenarioSlice";
import ScenarioCard from "../scenarios/ScenarioCard";
import { IoMdArrowRoundBack } from "react-icons/io";

const SelectScenario = ({ setOpenSceanrio }) => {

  const dispatch = useDispatch();
  const { scenarios } = useSelector((state) => state?.scenarioReducer);
  
  useEffect(() => {
    dispatch(getAllScenarios());
  }, []);

  const handleScenarioData = ()=>{
    // alert("hi")
 }
  return (
    <div>
      <div className=" flex items-center gap-2 mb-5 pl-2">
        <button
          to="/panel/creategame"
          className="text-white font-bold py-2 px-4 rounded inline-block"
          onClick={() => setOpenSceanrio(false)}
        >
          <IoMdArrowRoundBack  className="text-[30px]"/>
        </button>
        <h2 className="text-2xl font-bold text-white text-left">
          Choose Scenario
        </h2>
      </div>
      <div className="flex flex-wrap">
        {scenarios?.data?.map((scenario) => {
          return <ScenarioCard {...scenario} key={scenario.id}  onClick={()=>handleScenarioData()} />;
        })}
      </div>
    </div>
  );
};

export default SelectScenario;
