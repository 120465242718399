const ScenarioRepeatableSvg = () => {
  return (
    <>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="15px"
        height="15px"
        viewBox="0 0 20 20"
        fill="none"
      >
        <path
          d="M14.1667 13.9705V15.7352C14.1667 16.2032 13.9911 16.6521 13.6785 16.983C13.366 17.314 12.942 17.4999 12.5 17.4999H4.16667C3.72464 17.4999 3.30072 17.314 2.98816 16.983C2.67559 16.6521 2.5 16.2032 2.5 15.7352V7.794C2.5 7.32597 2.67559 6.87711 2.98816 6.54617C3.30072 6.21522 3.72464 6.0293 4.16667 6.0293H5.83333"
          stroke="#EE1C24"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        ></path>
        <path
          d="M7.49967 2.5H9.99967L11.6663 4.26471H15.833C16.275 4.26471 16.699 4.45063 17.0115 4.78158C17.3241 5.11252 17.4997 5.56138 17.4997 6.02941V12.2059C17.4997 12.6739 17.3241 13.1228 17.0115 13.4537C16.699 13.7847 16.275 13.9706 15.833 13.9706H7.49967C7.05765 13.9706 6.63372 13.7847 6.32116 13.4537C6.0086 13.1228 5.83301 12.6739 5.83301 12.2059V4.26471C5.83301 3.79668 6.0086 3.34782 6.32116 3.01687C6.63372 2.68592 7.05765 2.5 7.49967 2.5Z"
          stroke="#FFFFFF"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        ></path>
      </svg>
    </>
  );
};

export default ScenarioRepeatableSvg;
