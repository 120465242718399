import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";

import http from "../../utils/http";

const initialState = {
  isLoading: false,
  scenarios: [],
};

export const getAllScenarios = createAsyncThunk(
  "application/getAllScenarios",
  async (_, thunkAPI) => {
    try {
      const response = await http.get("/admin/scenario");
      return thunkAPI.fulfillWithValue(response.data);
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);

export const createScenario = createAsyncThunk(
  "application/createScenario",
  async (payload, thunkAPI) => {
    try {
      const response = await http.post("/admin/scenario", payload);
      return thunkAPI.fulfillWithValue(response.data);
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);

export const getScenarioById = createAsyncThunk(
  "application/getScenarioById",
  async (payload, thunkAPI) => {
    try {
      const response = await http.get(`/admin/scenario/${payload}`);
      return thunkAPI.fulfillWithValue(response.data);
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);

export const updateScenario = createAsyncThunk(
  "application/updateScenario",
  async (payload, thunkAPI) => {
    try {
      const response = await http.patch(
        `/admin/scenario/${payload?.id}`,
        payload.data
      );
      return thunkAPI.fulfillWithValue(response?.data);
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);
  
export const deleteScenario = createAsyncThunk(
  "application/deleteScenario",
  async (payload, thunkAPI) => {
    try {
      const response = await http.delete(`/admin/scenario/${payload}`);
      return thunkAPI.fulfillWithValue(response.data);
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);

const scenarioSlice = createSlice({
  name: "scenarios",
  initialState,
  extraReducers: (builder) => {
    builder
      .addCase(getAllScenarios.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getAllScenarios.fulfilled, (state, action) => {
        state.isLoading = false;
        state.scenarios = action.payload;
      })
      .addCase(getAllScenarios.rejected, (state) => {
        state.isLoading = false;
        state.scenarios = []
      })
      .addCase(createScenario.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(createScenario.fulfilled, (state) => {
        state.isLoading = false;
      })
      .addCase(createScenario.rejected, (state) => {
        state.isLoading = false;
      })
      .addCase(getScenarioById.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getScenarioById.fulfilled, (state) => {
        state.isLoading = false;
      })
      .addCase(getScenarioById.rejected, (state) => {
        state.isLoading = false;
      })
      .addCase(updateScenario.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(updateScenario.fulfilled, (state) => {
        state.isLoading = false;
      })
      .addCase(updateScenario.rejected, (state) => {
        state.isLoading = false;
      })
      .addCase(deleteScenario.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(deleteScenario.fulfilled, (state) => {
        state.isLoading = false;
      })
      .addCase(deleteScenario.rejected, (state) => {
        state.isLoading = false;
      });
  },
});
export default scenarioSlice.reducer;
