import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getAllGames, getAllGamesBySearch } from "../../redux/slices/gameSlice";
import { Link } from "react-router-dom";
import { AiOutlineSearch } from "react-icons/ai";
import ReactPaginate from "react-paginate";
import { LineWave } from "react-loader-spinner";
import { FaCircleInfo } from "react-icons/fa6";
import NewCard from "./NewCard";
const Games = () => {
  const { games, isLoading } = useSelector((state) => state?.gameReducer);
  const [currentPage, setCurrentPage] = useState(1);
  const [search, setSearch] = useState("");
  const [filters, setFilters] = useState({});
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getAllGames({ pageParams: currentPage }));
  }, [currentPage, dispatch]);

  const handleFilters = (body) => {
    dispatch(getAllGamesBySearch({ pageParams: 1, body }));
    setFilters(body);
    setSearch("");
    setCurrentPage(1);
  };

  const handlepagination = (e) => {
    const selectedPage = e.selected + 1;
    setCurrentPage(selectedPage);
    const params = {
      pageParams: selectedPage,
      body: Object.keys(filters).length > 0 ? filters : undefined,
    };
    dispatch(getAllGamesBySearch(params));
  };

  const handleSearch = (value) => {
    setSearch(value);
  };

  useEffect(() => {
    const delayDebounceFn = setTimeout(() => {
      if (search !== "") {
        const body =
          Object.keys(filters).length > 0
            ? { ...filters, value: search }
            : { value: search };
        dispatch(getAllGamesBySearch({ pageParams: 1, body }));
      } else {
        if (Object.keys(filters).length > 0) {
          dispatch(getAllGamesBySearch({ pageParams: 1, body: filters }));
        } else {
          dispatch(getAllGames({ pageParams: 1 }));
        }
      }
    }, 700);

    return () => clearTimeout(delayDebounceFn);
  }, [search, filters, dispatch]);

  return (
    <div>
      <div className="flex items-center justify-between mb-5 pl-3 pr-3 flex-wrap">
        <div className="flex gap-4 w-1/2">
          <div className="flex items-center gap-2">
            <input
              defaultChecked
              type="radio"
              name="status"
              onChange={() => {
                dispatch(getAllGames({ pageParams: 1 }));
                setFilters({});
                setSearch("");
                setCurrentPage(1);
              }}
              className="appearance-none w-4 h-4 border-2 border-red-500 rounded-full checked:bg-red-500 checked:border-transparent focus:ring-2 focus:ring-red-500"
            />
            <label className="block text-sm font-medium text-white">All</label>
          </div>
          <div className="flex items-center gap-2">
            <input
              type="radio"
              name="status"
              onChange={() => {
                handleFilters({ modes: "online" });
              }}
              className="appearance-none w-4 h-4 border-2 border-red-500 rounded-full checked:bg-red-500 checked:border-transparent focus:ring-2 focus:ring-red-500"
            />
            <label className="block text-sm font-medium text-white">
              Online
            </label>
          </div>
          <div className="flex items-center gap-2">
            <input
              type="radio"
              name="status"
              onChange={() => {
                handleFilters({ status: "COMPLETED" });
              }}
              className="appearance-none w-4 h-4 border-2 border-red-500 rounded-full checked:bg-red-500 checked:border-transparent focus:ring-2 focus:ring-red-500"
            />
            <label className="block text-sm font-medium text-white">
              Completed
            </label>
          </div>
          <div className="flex items-center gap-2">
            <input
              type="radio"
              name="status"
              onChange={() => {
                handleFilters({ status: "EXPIRED" });
              }}
              className="appearance-none w-4 h-4 border-2 border-red-500 rounded-full checked:bg-red-500 checked:border-transparent focus:ring-2 focus:ring-red-500"
            />
            <label className="block text-sm font-medium text-white">
              Expired
            </label>
          </div>
          <div className="flex items-center gap-2">
            <input
              type="radio"
              name="status"
              onChange={() => {
                handleFilters({ isDeleted: true });
              }}
              className="appearance-none w-4 h-4 border-2 border-red-500 rounded-full checked:bg-red-500 checked:border-transparent focus:ring-2 focus:ring-red-500"
            />
            <label className="block text-sm font-medium text-white">
              Deleted
            </label>
          </div>
        </div>
        <div className="flex items-center justify-end  gap-3 xl:w-1/2 w-full xl:pt-0 pt-5">
          <div className="relative xl:w-1/2">
            <button
              type="submit"
              className="absolute left-3 top-[50%] -translate-y-1/2"
            >
              <AiOutlineSearch className="text-gray-400" />
            </button>
            <input
              type="text"
              className="bg-[#372F5B] h-10 px-5 pr-10 pl-[35px] text-white rounded-full text-sm focus:outline-none w-full"
              placeholder="Game/Scenario/Location/Code"
              onChange={(e) => handleSearch(e.target.value)}
              value={search}
            />
          </div>
          <div className="text-right pr-2">
            <Link
              to="/panel/creategame"
              className="bg-red-500 text-white font-bold py-2 px-4 rounded inline-block"
            >
              Create Game
            </Link>
          </div>
        </div>
      </div>
      <div className="flex justify-start items-center pl-3 pr-3 mb-2">
        <span className="inline-flex items-center space-x-2">
          <FaCircleInfo className="text-gray-400 text-sm" />
          <p className="text-gray-400 text-sm">
            The games are sorted on the basis of start dates.
          </p>
        </span>
      </div>
      <div
        className={`flex flex-wrap items-center ${
          games?.data?.length === 0 && "justify-center"
        }`}
      >
        {games?.data?.length === 0 ? (
          <div className="text-center text-gray-500 py-8 text-3xl">
            No Games Found
          </div>
        ) : (
          <>
            {games?.data?.map((game) => (
              <NewCard key={game._id} data={game} />
            ))}
          </>
        )}
      </div>
      {games?.data?.length !== 0 && (
        <div className="flex justify-end py-4">
          <ReactPaginate
            previousLabel="Previous"
            nextLabel="Next"
            onPageChange={handlepagination}
            breakLabel="..."
            breakClassName="mx-2 text-white"
            pageCount={games?.meta?.total_no_search_pages}
            marginPagesDisplayed={2}
            pageRangeDisplayed={5}
            containerClassName="flex justify-center"
            previousClassName="px-4 py-2 bg-gray-800 text-white rounded-l cursor-pointer"
            nextClassName="px-4 py-2 bg-gray-800 text-white rounded-r cursor-pointer"
            activeClassName="px-4 py-2 bg-red-500 text-white"
            pageClassName="px-4 py-2 bg-[#372F5B] text-white"
            forcePage={currentPage - 1}
          />
        </div>
      )}

      {isLoading && (
        <div className="loader">
          <LineWave color={"#fff"} loading={isLoading} size={150} />
        </div>
      )}
    </div>
  );
};

export default Games;
