import React from "react";

const DurationSvg = () => {
  return (
    <div>
      <svg
        width="14"
        height="14"
        viewBox="0 0 14 14"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g clipPath="url(#clip0_6098_10150)">
          <path
            d="M6.99984 12.8334C10.2215 12.8334 12.8332 10.2217 12.8332 7.00008C12.8332 3.77842 10.2215 1.16675 6.99984 1.16675C3.77818 1.16675 1.1665 3.77842 1.1665 7.00008C1.1665 10.2217 3.77818 12.8334 6.99984 12.8334Z"
            stroke="#B8BBCC"
            strokeWidth="1.16667"
            strokeLinecap="round"
            strokeLinejoin="round"
          ></path>
          <path
            d="M7 3.5V7L9.33333 8.16667"
            stroke="#B8BBCC"
            strokeWidth="1.16667"
            strokeLinecap="round"
            strokeLinejoin="round"
          ></path>
        </g>
        <defs>
          <clipPath id="clip0_6098_10150">
            <rect width="14" height="14" fill="white"></rect>
          </clipPath>
        </defs>
      </svg>
    </div>
  );
};

export default DurationSvg;
