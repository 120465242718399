import React from "react";
const Confirmation = ({ confirmDelete, cancelDelete, message, buttonText }) => {
  return (
    <div className="fixed inset-0 bg-gray-900 bg-opacity-50 flex items-center justify-center z-50">
      <div className="bg-white p-6 rounded-lg shadow-lg text-center">
        <p className="text-lg font-semibold mb-4">Confirm</p>
        <p className="text-sm mb-4">{`Are you sure you want to ${message}?`}</p>
        <button
          className="text-white bg-red-500 px-4 py-2 rounded hover:bg-red-600 focus:outline-none ml-2"
          onClick={confirmDelete}
        >
          {buttonText}
        </button>
        <button
          className="text-gray-600 bg-gray-300 px-4 py-2 rounded hover:bg-gray-400 focus:outline-none ml-2"
          onClick={cancelDelete}
        >
          Cancel
        </button>
      </div>
    </div>
  );
};

export default Confirmation;
